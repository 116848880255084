import React from "react"

const DualPurposeGrinders = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    viewBox="0 0 142 402"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2690_1673)">
      <path
        d="M70.85 1.5H70.72C67.81 1.57 12.54 3.11 3 18.62V31.67C3 33.11 34.44 115.07 34.44 115.07V131.43H107.03V115.07C107.03 115.07 138.47 33.07 138.47 31.67V18.62C129 3.11 73.76 1.57 70.85 1.5Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.47 127.52H11.1C11.1 127.52 -0.0900095 171.26 1.68999 221.85C3.46999 272.44 6.51999 374.79 7.99999 393.35C21.33 397.79 68 399.96 70.74 400.08H70.87C73.59 399.96 120.28 397.79 133.61 393.35C135.07 374.79 138.12 272.45 139.9 221.86C141.68 171.27 130.47 127.52 130.47 127.52Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M120.81 223.89H20.81V141.76C20.81 141.76 29.54 140.57 70.81 140.57C112.08 140.57 120.81 141.76 120.81 141.76V223.89Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.3 223.89H41.3L47 240.18C47 240.18 46.38 240.42 70.74 240.42C95.1 240.42 94.5 240.18 94.5 240.18L100.3 223.89Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.41 151.42H56.17V165.32H85.41V151.42Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.53 183.5C64.53 185.128 63.8831 186.69 62.7316 187.842C61.5802 188.993 60.0184 189.64 58.39 189.64H47.45C45.8216 189.64 44.2598 188.993 43.1084 187.842C41.9569 186.69 41.31 185.128 41.31 183.5V183.5C41.31 181.872 41.9569 180.31 43.1084 179.158C44.2598 178.007 45.8216 177.36 47.45 177.36H58.39C60.0184 177.36 61.5802 178.007 62.7316 179.158C63.8831 180.31 64.53 181.872 64.53 183.5V183.5Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.05 183.5C100.05 185.128 99.4031 186.69 98.2516 187.842C97.1001 188.993 95.5384 189.64 93.91 189.64H83C81.3716 189.64 79.8098 188.993 78.6583 187.842C77.5069 186.69 76.86 185.128 76.86 183.5V183.5C76.86 181.872 77.5069 180.31 78.6583 179.158C79.8098 178.007 81.3716 177.36 83 177.36H93.91C94.7163 177.36 95.5147 177.519 96.2597 177.827C97.0046 178.136 97.6815 178.588 98.2516 179.158C98.8218 179.729 99.274 180.405 99.5826 181.15C99.8912 181.895 100.05 182.694 100.05 183.5V183.5Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 351.18C6 351.18 25.7 355.69 70.77 355.69C115.84 355.69 135.41 351.18 135.41 351.18"
        fill="white"
      />
      <path
        d="M6 351.18C6 351.18 25.7 355.69 70.77 355.69C115.84 355.69 135.41 351.18 135.41 351.18"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.78 370.24C6.78 370.24 26.25 374.7 70.78 374.7C115.31 374.7 134.66 370.24 134.66 370.24"
        fill="white"
      />
      <path
        d="M6.78 370.24C6.78 370.24 26.25 374.7 70.78 374.7C115.31 374.7 134.66 370.24 134.66 370.24"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 24C3 24 11.1 12.73 70.34 12.73C129.58 12.73 138.23 24.55 138.23 24.55"
        fill="white"
      />
      <path
        d="M3 24C3 24 11.1 12.73 70.34 12.73C129.58 12.73 138.23 24.55 138.23 24.55"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.41 119.13C34.41 119.13 40.94 118.03 70.68 118.03C100.42 118.03 107.12 119.13 107.12 119.13"
        fill="white"
      />
      <path
        d="M34.41 119.13C34.41 119.13 40.94 118.03 70.68 118.03C100.42 118.03 107.12 119.13 107.12 119.13"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.78 214.99C74.3367 214.99 77.22 212.107 77.22 208.55C77.22 204.993 74.3367 202.11 70.78 202.11C67.2233 202.11 64.34 204.993 64.34 208.55C64.34 212.107 67.2233 214.99 70.78 214.99Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.3 214.99C48.8567 214.99 51.74 212.107 51.74 208.55C51.74 204.993 48.8567 202.11 45.3 202.11C41.7433 202.11 38.86 204.993 38.86 208.55C38.86 212.107 41.7433 214.99 45.3 214.99Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.27 214.99C99.8267 214.99 102.71 212.107 102.71 208.55C102.71 204.993 99.8267 202.11 96.27 202.11C92.7133 202.11 89.83 204.993 89.83 208.55C89.83 212.107 92.7133 214.99 96.27 214.99Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2690_1673">
        <rect width="141.57" height="401.58" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default DualPurposeGrinders
