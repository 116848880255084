import React from "react"

const TeaPots = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    viewBox="0 0 375 301"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2694_2223)">
      <path
        d="M62.63 63.7499C62.63 63.7499 90 38.6299 139.38 38.6299C188.76 38.6299 218.38 64.2599 218.38 64.2599L62.63 63.7499Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.5 38.76C148.789 38.76 157.13 30.4191 157.13 20.13C157.13 9.84093 148.789 1.5 138.5 1.5C128.211 1.5 119.87 9.84093 119.87 20.13C119.87 30.4191 128.211 38.76 138.5 38.76Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M287.25 50.3799C246.75 50.3799 225.75 81.8799 225.75 81.8799L216.5 194.38C216.5 194.38 237.75 234.13 289.75 234.13C350 234.13 373.25 186.88 373.25 138.38C373.25 89.8799 327.75 50.3799 287.25 50.3799ZM289.25 213.03C249.19 213.03 232.82 182.41 232.82 182.41L240 95.7399C240 95.7399 256.18 71.4699 287.38 71.4699C318.58 71.4699 353.63 101.9 353.63 139.26C353.63 176.62 335.67 213 289.26 213L289.25 213.03Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.5 91.2499C31.5 91.2499 25.13 75.2499 13.25 74.1299C19.63 62.2599 47.38 60.3799 60.88 60.3799H221.08C223.5 60.3799 225.08 61.1299 225.5 63.3799C225.92 65.6299 225.5 72.2999 225.5 72.2999C225.5 72.2999 275.75 113.63 275.75 177.88C275.75 242.13 237.88 278.38 212 294.13C191.88 299.26 159.38 298.88 143.5 298.88C127.62 298.88 79.88 298.63 68.88 295.88C57.88 293.13 1.5 251.21 1.5 181.54C1.5 128.38 31.5 91.2499 31.5 91.2499Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.33 59.8799L61.67 72.2099C61.67 72.2099 82 80.8699 82 94.5399V247.54C82 247.54 89.5 250.38 139.5 250.38C189.5 250.38 200.67 247.54 200.67 247.54V94.5399C200.67 85.8699 217.67 72.2099 217.67 72.2099L211.67 59.8799H67.33Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.46 96.6299V101.5"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.99 96.6299V101.5"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.51 96.6299V101.5"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.04 96.6299V101.5"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.57 96.6299V101.5"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.1 96.6299V101.5"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.63 96.6299V101.5"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M167.15 96.6299V101.5"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.68 96.6299V101.5"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.21 96.6299V101.5"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.46 110.84V115.72"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.99 110.84V115.72"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.51 110.84V115.72"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.04 110.84V115.72"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.57 110.84V115.72"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.1 110.84V115.72"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.63 110.84V115.72"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M167.15 110.84V115.72"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.68 110.84V115.72"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.21 110.84V115.72"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.46 125.05V129.93"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.99 125.05V129.93"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.51 125.05V129.93"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.04 125.05V129.93"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.57 125.05V129.93"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.1 125.05V129.93"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.63 125.05V129.93"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M167.15 125.05V129.93"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.68 125.05V129.93"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.21 125.05V129.93"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.46 139.27V144.15"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.99 139.27V144.15"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.51 139.27V144.15"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.04 139.27V144.15"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.57 139.27V144.15"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.1 139.27V144.15"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.63 139.27V144.15"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M167.15 139.27V144.15"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.68 139.27V144.15"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.21 139.27V144.15"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.46 153.48V158.36"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.99 153.48V158.36"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.51 153.48V158.36"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.04 153.48V158.36"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.57 153.48V158.36"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.1 153.48V158.36"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.63 153.48V158.36"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M167.15 153.48V158.36"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.68 153.48V158.36"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.21 153.48V158.36"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.46 167.7V172.58"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.99 167.7V172.58"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.51 167.7V172.58"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.04 167.7V172.58"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.57 167.7V172.58"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.1 167.7V172.58"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.63 167.7V172.58"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M167.15 167.7V172.58"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.68 167.7V172.58"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.21 167.7V172.58"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.46 181.91V186.79"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.99 181.91V186.79"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.51 181.91V186.79"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.04 181.91V186.79"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.57 181.91V186.79"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.1 181.91V186.79"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.63 181.91V186.79"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M167.15 181.91V186.79"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.68 181.91V186.79"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.21 181.91V186.79"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2694_2223">
        <rect width="374.75" height="300.38" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default TeaPots
