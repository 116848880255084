import React from "react"

const HandGrinders = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 144 158"
  >
    <path
      fill="none"
      stroke="#000"
      strokeWidth=".765"
      d="m13.744 32.02.651-.485 1.23-.37 1.103-.43.777-.186.29-.112.732-.122 5.06-.282 8.458-.013.003-.484.323-.393.23-.175.658-.007.125-6.873.134-.101.099-.172.196-.091.143-.104.008-.117.041-.114 8.023.212.102.141v.077l.203.031.123.04-.01.304.204.064.048.069-.01 1.721 10.113.132 7.759.343.729.136.968.157.66.192.736.266.575.326.953.447.725.4 1.065.467 1.267.64 2.664 1.864 1.97 1.384 5.02 2.901 11.152 6.852 5.677 3.58 1.174 1.05.424.232 1.495.498 1.751.275.99-.006 13.811.101 1.056-.128.898-.62.684-.376 1.58-.895 1.065-.936 1.03-1.731s.499-1.64.544-1.73c.046-.089.167-1.545.167-1.545l-.708-.39-2.315-1.105-1.374-1.27-1.91-1.851-4.003-4.775-1.526-2.613-1.298-3.36-1.1-3.278-.305-3.06-.099-1.73.137-1.37.452-.952.577-.908.9-.596 1.012-.547 1.553-.67 2.503-.493 3.506-1.164 2.525-.656 1.638-.209 1.172-.315 1.997-.065 4.037.517 3.676.865 3.42 1.288 3.587 1.404 1.04 1.01 1.304 1.587.49 1.812s-.4-.037.009.007c.409.044-.605-.659-.347.003.259.663-.214 2.439-.214 2.439l-1.533 6.116-3.304 4.784-2.768 3.39-1.78 2.157-2.508 2.478-2.481 1.414-1.49-.034-.01 3.749-.91 2.222-1.1 1.6-1.395 1.895-1.668 1.484-2.1 1.03-2.911.754-3.82.325-13.15-.041-2.183-.604-2.282-.553-2.068-1.377-3.56-2.017-19.368-12.132-5.202-3.328-1.936-1.13-1.317-.466-1.624-.187-16.752-.543-.002.646.586.01.387.117.238.598.033.34 7.484.058 5.106.113 3.462.445 1.927.751.158.723-.29 76.677-.12 1.607c-.872.694-.959-.035-.619.704l.13.087c.702.218.21.254.21.254l.195 27.064.077 9.214-.161.73-.978 1.564-.423.895-1.985.773-8.794 1.185-5.639.205-17.656.022-2.63-.181-2.531-.563-3.608-.818-1.734-1.015-1.014-1.077-1.038-1.734-.44-.976.426-35.838.228-.744.291-.66.655 1.345 1.034.297 1.961.654.728-.076.733.418 10.71.4 16.291.176 8.72-.047 2.842-.682 2.802-.654.598-.218c-.176-.417-.122-.156-.345-1.107l-1.551.167-4.575.294-9.424.437-8.572-.01-5.765-.173-10.536-.138-4.411-.45-1.895-.634-.453-.769z"
    />
  </svg>
)

export default HandGrinders
