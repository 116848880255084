import React from "react"

const KnockBoxes = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    viewBox="0 0 250 304"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2694_2078)">
      <path
        d="M36 72.2C26.53 76.39 27.3 55.2 27.3 50.92V34.55C27.3 22.15 20.72 24.98 20.72 24.98C20.72 24.98 6.09996 33.25 4.99996 43.34C4.88996 44.34 4.76996 46.98 4.63996 51.04C3.66616 48.4363 3.20437 45.6689 3.27996 42.89C3.61996 26.36 14.28 14.77 27.28 12.36L54.9 20.64V20.8L36.24 71.51L36 72.2Z"
        fill="white"
      />
      <path
        d="M245.62 87.64C244.742 84.8415 243.318 82.2449 241.43 80C247.14 84.45 249.3 96 246.18 108.28C245.321 111.751 244.052 115.108 242.4 118.28C242.34 115.9 242.28 113.567 242.22 111.28C244.83 103.44 247.39 93.08 245.62 87.64Z"
        fill="white"
      />
      <path
        d="M241.43 80C243.32 82.2508 244.744 84.8545 245.62 87.66C247.39 93.1 244.83 103.46 242.22 111.2C241.91 99.81 241.62 89.2 241.37 79.95L241.43 80Z"
        fill="white"
      />
      <path
        d="M57.0499 11.44C89.4099 -1.17998 159.4 -2.09998 198.65 10.53C237.9 23.16 240 36.69 240.42 47.7C240.54 50.58 240.9 62.78 241.42 79.9C238.971 77.0791 235.78 75.0006 232.21 73.9C228.75 72.81 110 37.18 54.8299 20.64V15.73C54.7074 14.8728 54.8555 13.9988 55.2535 13.2298C55.6514 12.4608 56.2794 11.8351 57.0499 11.44V11.44Z"
        fill="white"
      />
      <path
        d="M216.65 100.78C220.32 86.3 229.9 76.23 238.05 78.29C239.258 78.6006 240.389 79.1591 241.37 79.93C241.62 89.15 241.91 99.79 242.22 111.18C241.188 114.449 239.894 117.629 238.35 120.69C236.85 123.14 230.71 129.06 222.65 129.9C216 126.21 213.32 113.94 216.65 100.78ZM231.23 115C234.32 115.78 237.95 112 239.34 106.48C240.73 100.96 239.34 95.92 236.26 95.13C233.18 94.34 229.55 98.13 228.16 103.65C226.77 109.17 228.15 114.21 231.23 115Z"
        fill="white"
      />
      <path
        d="M231.23 115C228.15 114.22 226.77 109.14 228.16 103.65C229.55 98.16 233.16 94.35 236.26 95.13C239.36 95.91 240.73 101 239.34 106.48C237.95 111.96 234.32 115.77 231.23 115Z"
        fill="white"
      />
      <path
        d="M222.65 129.9C230.71 129.06 236.85 123.14 238.35 120.69C239.894 117.629 241.188 114.449 242.22 111.18C242.28 113.5 242.34 115.833 242.4 118.18C237.78 126.99 230.88 132.24 224.78 130.7C224.039 130.525 223.323 130.256 222.65 129.9V129.9Z"
        fill="white"
      />
      <path
        d="M54.83 20.64C110 37.18 228.75 72.81 232.16 73.87C235.73 74.9706 238.921 77.0491 241.37 79.87C240.389 79.0991 239.258 78.5407 238.05 78.23C229.9 76.17 220.32 86.23 216.65 100.72C213.32 113.88 216.01 126.15 222.65 129.84C220.808 130.04 218.945 129.952 217.13 129.58C216.48 129.45 215.19 129.1 213.35 128.58C209.84 127.58 204.35 125.86 197.29 123.66C195.224 119.898 193.967 115.746 193.6 111.47C192.84 101.67 193.3 87.6 193.3 82.24C193.3 76.88 189.16 76.74 186.41 76.89C183.66 77.04 168.81 79.95 166.41 80.56C164.01 81.17 161.36 81.94 159.41 84.23C157.46 86.52 154.49 99.66 149.28 108.31H149.19C108.7 95.24 60.78 79.56 36.24 71.51L54.83 20.8V20.64Z"
        fill="white"
      />
      <path
        d="M213.34 128.64C206.06 133.3 200.61 129.56 197.29 123.7C204.29 125.9 209.84 127.6 213.35 128.62L213.34 128.64Z"
        fill="white"
      />
      <path
        d="M186.41 76.93C189.16 76.78 193.3 76.93 193.3 82.28C193.3 87.63 192.84 101.71 193.6 111.51C193.967 115.786 195.224 119.938 197.29 123.7C200.61 129.56 206.06 133.3 213.34 128.64C215.18 129.18 216.47 129.53 217.12 129.64C218.935 130.012 220.798 130.1 222.64 129.9C223.311 130.274 224.026 130.559 224.77 130.75C230.87 132.29 237.77 127.04 242.39 118.23C243.64 165.23 245.07 221.87 245.46 243.01C246.15 280.19 179.59 301.77 105.46 301.77C31.33 301.77 1.27998 267.34 1.49998 258.16C1.70998 249.86 3.41998 89.24 4.58998 51C4.71998 46.94 4.83998 44.27 4.94998 43.3C6.09998 33.25 20.71 25 20.71 25C20.71 25 27.29 22.17 27.29 34.57V50.92C27.29 55.17 26.52 76.39 35.99 72.2L36.24 71.51C60.78 79.51 108.7 95.24 149.11 108.3H149.2C154.41 99.65 157.43 86.42 159.33 84.22C161.23 82.02 163.92 81.16 166.33 80.55C168.74 79.94 183.66 77.08 186.41 76.93Z"
        fill="white"
      />
      <path
        d="M241.37 79.93C240.9 62.81 240.54 50.61 240.37 47.73C239.91 36.73 237.85 23.18 198.6 10.56C159.35 -2.06001 89.4099 -1.18001 57.0499 11.44C56.2794 11.8351 55.6514 12.4608 55.2535 13.2298C54.8555 13.9988 54.7074 14.8728 54.8299 15.73V20.8"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36 72.2C26.53 76.39 27.3 55.2 27.3 50.92V34.55C27.3 22.15 20.72 24.98 20.72 24.98C20.72 24.98 6.09998 33.25 4.99998 43.34C4.88998 44.34 4.76998 46.98 4.63998 51.04C3.41998 89.24 1.70998 249.86 1.49998 258.16C1.27998 267.34 31.34 301.77 105.5 301.77C179.66 301.77 246.19 280.19 245.5 243.01C245.11 221.87 243.68 165.22 242.43 118.23"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M222.65 129.9C220.808 130.1 218.945 130.012 217.13 129.64C216.48 129.51 215.19 129.16 213.35 128.64C209.84 127.64 204.35 125.92 197.29 123.72L197.07 123.65"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.58991 51C3.61611 48.3962 3.15432 45.6289 3.22991 42.85C3.56991 26.32 14.2299 14.73 27.2299 12.32L54.8499 20.6C110 37.18 228.75 72.81 232.16 73.87C235.73 74.9706 238.921 77.0491 241.37 79.87"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.11 108.3C108.7 95.24 60.7801 79.56 36.2401 71.51L31.6001 70"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4399 33.59C20.4399 33.59 13.9999 39.6 13.9999 45.26C13.9999 50.92 13.3899 85.34 13.0899 97.58C12.7899 109.82 16.9099 122.98 35.2699 135.38C53.6299 147.78 91.1199 145.17 110.7 136.6C130.28 128.03 142.83 118.09 148.5 109.52C148.75 109.14 148.99 108.75 149.23 108.35C154.44 99.7 157.46 86.47 159.36 84.27C161.26 82.07 163.95 81.21 166.36 80.6C168.77 79.99 183.65 77.08 186.36 76.93C189.07 76.78 193.25 76.93 193.25 82.28C193.25 87.63 192.79 101.71 193.55 111.51C193.917 115.786 195.173 119.938 197.24 123.7C200.56 129.56 206.01 133.3 213.29 128.64"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M241.43 80C247.14 84.45 249.3 96 246.18 108.28C245.321 111.751 244.052 115.108 242.4 118.28C237.78 127.09 230.88 132.34 224.78 130.8C224.036 130.609 223.321 130.324 222.65 129.95C216.01 126.26 213.32 113.95 216.65 100.83C220.32 86.35 229.9 76.28 238.05 78.34C239.258 78.6506 240.389 79.2091 241.37 79.98L241.43 80Z"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M239.34 106.47C237.95 111.96 234.34 115.77 231.23 114.99C228.12 114.21 226.77 109.13 228.16 103.64C229.55 98.15 233.16 94.34 236.26 95.12C239.36 95.9 240.73 101 239.34 106.47Z"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M220.28 70.2C220.28 70.2 219.61 65.76 222.83 63.01C226.05 60.26 233.54 52.91 233.54 47.7C233.54 42.49 231.09 38.22 221.29 30.87C211.49 23.52 190.39 13.74 149.84 10.07C109.29 6.40004 67.3201 17.24 67.3201 17.24"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9199 127C18.4859 135.246 26.093 141.909 34.9999 146.34C49.8399 153.68 82.8899 157.05 109.21 147.25C135.53 137.45 147.42 126.48 156.45 113.17C165.48 99.86 163.58 91.08 166.8 89.09C170.02 87.1 185.16 85.57 185.16 85.57"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2694_2078">
        <rect width="249.11" height="303.27" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default KnockBoxes
