import React from "react"

const Vacuum = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    viewBox="0 0 278 369"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2690_1883)">
      <path
        d="M174.31 40C167.2 31.39 164.12 29.73 164.12 22.3C164.203 17.7224 165.912 13.3236 168.94 9.89001L99.64 8.89001H99.56L30.26 9.89001C33.2884 13.3236 34.9969 17.7224 35.08 22.3C35.08 29.73 32 31.39 24.89 40C17.78 48.61 4.33997 69.21 4.33997 96.83C4.33997 116.62 13.94 169.96 75.45 189.75C76.64 190.82 80.55 194.75 80.55 194.75H118.64C118.64 194.75 122.55 190.84 123.74 189.75C185.25 169.96 194.85 116.62 194.85 96.83C194.85 69.21 181.42 48.59 174.31 40Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M67.79 200.7C67.79 200.7 71.27 192.48 75.93 192.48H119C122.63 192.48 127.14 198.75 127 200.48C124 200.49 67.79 200.7 67.79 200.7Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M173.71 265C159.49 253.78 129.48 244 126.81 242C124.14 240 125.35 235.68 125.27 233.31C125.19 230.94 125.19 220.67 125.19 215.14C125.19 209.61 136.33 206.76 134.75 199.89H68.19C64 199.89 57.6 200.44 55.78 207.24C62.65 207.79 67.32 208.9 69.37 215.14C71.42 221.38 71.9 230.94 71.82 233.31C71.74 235.68 73.64 238.45 66.21 242C58.78 245.55 39.74 253.77 25.52 265C11.3 276.23 1.52002 295.1 1.52002 313.83C1.52002 332.56 15.35 359.66 29.81 362.66C43.59 365.52 94.7 367.24 99.46 367.39H99.82C104.58 367.24 155.69 365.52 169.47 362.66C183.93 359.66 197.76 332.56 197.76 313.83C197.76 295.1 187.93 276.23 173.71 265Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M111.49 239.77H85.4099V342.29H111.49V239.77Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M176.67 10.82C176.67 10.82 175.49 4.74 174.54 3.16C173.59 1.58 172.41 1.5 169.54 1.5H28.2099C26.7099 1.5 25.37 3.5 25.37 3.5L22.2 10.82H57.2C59.76 13.69 62.9399 17.28 63.3499 17.93C64.0599 19.04 66.8299 20.7 70.4599 20.7H129.25C130.526 20.7522 131.799 20.5321 132.983 20.0546C134.168 19.577 135.237 18.8527 136.12 17.93L143.69 10.82H176.67Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M67.0801 213.42C67.0801 213.42 132.43 214.21 141.2 214.21C149.97 214.21 203.86 215 216.11 215.4C228.36 215.8 271.65 215.16 275.36 215.4C279.07 215.64 263.9 221.8 253 223.7C242.1 225.6 228.35 233.18 216.34 233.18C204.33 233.18 189.75 229.94 180.27 226.54C177.03 225.2 172.72 225.35 169.41 225.35C166.1 225.35 162.93 228.75 161.03 231.28C159.13 233.81 154.03 241 154.03 241C154.03 241 135.15 241.47 125.19 241.47H68.0001L67.0801 213.42Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M120.26 101.22H74.98V117.34H120.26V101.22Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2690_1883">
        <rect width="277.43" height="368.92" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Vacuum
