import React from "react"

const ElectricMilkFrothers = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    viewBox="0 0 260 305"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2690_2011)">
      <path
        d="M232 119.59C231.77 124.19 231.77 129.16 232 134.59C215.11 153.96 190.77 168.84 166.29 174.73C144.672 179.871 122.138 179.764 100.57 174.42C83.37 169.93 84.92 162.18 84.92 162.18C84.92 162.18 85.18 143.18 84.11 126.35C83.76 120.92 83.27 115.73 82.59 111.49C79.8 94.14 71.74 87.01 71.59 74.76C71.53 70.09 71.11 60.98 70.59 51.5C70 39.49 69.19 26.82 68.89 22C68.82 20.67 68.77 19.92 68.77 19.92L69.1 19.62C75.63 24.53 112.54 28.29 157.1 28.29C203.91 28.29 242.28 24.14 245.86 18.87H245.91C245.91 18.87 244.52 59.63 244.36 67.38C244.2 75.13 242.36 80.56 237.54 92.38C234.21 100.52 232.52 109.09 232 119.59Z"
        fill="white"
      />
      <path
        d="M246.15 18C246.144 18.2927 246.046 18.5762 245.87 18.81C242.29 24.08 203.92 28.23 157.11 28.23C112.54 28.23 75.63 24.47 69.11 19.56C68.8341 19.3949 68.6002 19.1682 68.4267 18.8975C68.2532 18.6268 68.1448 18.3196 68.11 18C68.11 13.42 94.3 9.53999 130.42 8.23999C124.67 12.89 125.07 18.24 125.07 18.24L189.54 20.24C189.54 20.24 190.2 13.61 183.63 8.23999C219.84 9.53999 246.15 13.42 246.15 18Z"
        fill="white"
      />
      <path
        d="M70.57 51.55H70.47C70.47 51.55 62.1 50.93 58.38 68.45C54.66 85.97 42.38 141.45 40.25 150.75C38.12 160.05 29.09 161.13 24.75 160.51C20.41 159.89 16.07 153.69 17.46 146.72C18.85 139.75 28.31 75.12 33.89 56.52C39.47 37.92 52.34 24.28 68.77 22H68.89C69.19 26.82 70 39.49 70.57 51.55Z"
        fill="white"
      />
      <path
        d="M251.19 156.57C251.1 159.71 248.4 251.95 248.4 260.18C248.4 268.41 247.01 272.27 238.33 275.68C229.65 279.09 192.14 291.49 176 297.07C159.86 302.65 150.43 303.73 137.1 301.41C123.77 299.09 23.2 283.74 17.77 282.19C12.34 280.64 7.23999 281 7.23999 274.75V205.93L7.31999 205.62C8.22999 205.85 9.31999 206.06 10.49 206.24C19.94 207.64 143.49 223.6 158.49 224.07C173.49 224.54 181.27 220.35 188.55 216.47C200.7 210.145 211.619 201.695 220.79 191.52C229.5 182 243.21 166 251.19 156.57Z"
        fill="white"
      />
      <path
        d="M251.19 156.57C243.19 165.97 229.5 182 220.81 191.57C211.639 201.745 200.72 210.195 188.57 216.52C181.29 220.4 173.57 224.58 158.51 224.12C143.45 223.66 19.94 207.69 10.51 206.29C9.29999 206.11 8.24999 205.9 7.33999 205.67C1.16999 204.08 1.51999 201.38 1.51999 199.67V187.33C1.51999 182.22 10.67 180.51 22.91 175.4C35.15 170.29 50 157.11 60.86 147C71.72 136.89 83.8 126.42 83.8 126.42H84.08C85.15 143.21 84.89 162.25 84.89 162.25C84.89 162.25 83.34 170 100.54 174.49C122.108 179.834 144.642 179.941 166.26 174.8C190.74 168.91 215.08 154.03 231.97 134.66C231.78 129.18 231.78 124.21 231.97 119.66C239.67 118.98 257.51 123.91 257.51 129.59V149.12C257.51 149.12 255 152 251.19 156.57Z"
        fill="white"
      />
      <path
        d="M183.59 8.24002C190.16 13.62 189.5 20.24 189.5 20.24L125.03 18.24C125.03 18.24 124.63 12.9 130.38 8.24002C138.82 7.93002 147.8 7.77002 157.11 7.77002C166.42 7.77002 175.22 7.93002 183.58 8.23002L183.59 8.24002Z"
        fill="white"
      />
      <path
        d="M183.58 8.24C175.22 7.94 166.33 7.78 157.11 7.78C147.89 7.78 138.82 7.94 130.38 8.25C134.81 4.67 142.88 1.5 157.58 1.5C171.27 1.5 179.1 4.58 183.58 8.24Z"
        fill="white"
      />
      <path
        d="M183.59 8.23999C219.84 9.53999 246.15 13.42 246.15 18.01C246.144 18.3027 246.046 18.5862 245.87 18.82C242.29 24.09 203.92 28.24 157.11 28.24C112.54 28.24 75.63 24.48 69.11 19.57C68.8341 19.4049 68.6002 19.1782 68.4267 18.9075C68.2532 18.6368 68.1448 18.3296 68.11 18.01C68.11 13.43 94.3 9.54999 130.42 8.24999"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M68.77 19.87C68.77 19.87 68.77 20.62 68.89 21.95C69.19 26.82 69.95 39.49 70.57 51.55C71.08 61.03 71.5 70.14 71.57 74.81C71.72 87.06 79.78 94.19 82.57 111.54C83.25 115.78 83.74 120.97 84.09 126.4C85.16 143.19 84.9 162.23 84.9 162.23C84.9 162.23 83.35 169.98 100.55 174.47C122.118 179.814 144.652 179.921 166.27 174.78C190.75 168.89 215.09 154.01 231.98 134.64C231.79 129.16 231.79 124.19 231.98 119.64C232.49 109.14 234.18 100.57 237.52 92.38C242.33 80.6 244.19 75.17 244.34 67.38C244.49 59.59 245.89 18.87 245.89 18.87"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M83.8 126.42C83.8 126.42 71.71 137 60.86 147C50.01 157 35.13 170.25 22.86 175.36C10.59 180.47 1.49997 182.22 1.49997 187.33V199.58C1.49997 201.33 1.14997 204.03 7.31997 205.58C8.22997 205.81 9.31997 206.02 10.49 206.2C19.94 207.6 143.49 223.56 158.49 224.03C173.49 224.5 181.27 220.31 188.55 216.43C200.7 210.105 211.619 201.655 220.79 191.48C229.48 181.96 243.19 165.93 251.17 156.48C255.01 151.95 257.53 148.96 257.53 148.96V129.52C257.53 123.84 239.69 118.91 231.99 119.59H231.8"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M7.23999 205.93V274.75C7.23999 280.95 12.35 280.64 17.77 282.19C23.19 283.74 123.77 299.08 137.12 301.41C150.47 303.74 159.9 302.65 176.02 297.07C192.14 291.49 229.65 279.07 238.33 275.68C247.01 272.29 248.4 268.55 248.4 260.18C248.4 251.81 251.1 159.71 251.19 156.57V156.49"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M130.38 8.24997C124.63 12.9 125.03 18.25 125.03 18.25L189.5 20.25C189.5 20.25 190.16 13.62 183.59 8.24997C179.11 4.58997 171.28 1.50997 157.59 1.50997C142.88 1.49997 134.81 4.66997 130.38 8.24997Z"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M70.47 51.56C70.47 51.56 62.1 50.94 58.38 68.46C54.66 85.98 42.38 141.46 40.25 150.76C38.12 160.06 29.09 161.14 24.75 160.52C20.41 159.9 16.07 153.7 17.46 146.73C18.85 139.76 28.31 75.12 33.89 56.52C39.47 37.92 52.34 24.28 68.77 22"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M13.9 187.64C13.9 187.64 130 201.9 148.74 202.52C167.48 203.14 175.09 205 192.29 192.29C209.49 179.58 248.87 138.82 248.87 138.82"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2690_2011">
        <rect width="259.05" height="304.06" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default ElectricMilkFrothers
