import React from "react"

const Glassware = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    viewBox="0 0 305 222"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2694_2036)">
      <path
        d="M272.6 57.22C273.26 70.16 266.92 77.56 258.73 85.35C256.03 87.91 248.59 93.77 239.73 100.63C215.05 93.63 184.81 89.44 152.14 89.44C128.961 89.3441 105.832 91.5787 83.1001 96.11C75.6001 78.42 68.8401 59.4 67.4001 45.11C65.4001 25.44 65.6801 19.5 69.9101 16.59C74.1401 13.68 91.8301 1.59003 146.23 1.59003C200.63 1.59003 222.55 12.02 225.85 16.91C229.15 21.8 226.78 38.3 226.51 40.01C234.3 35.13 247.38 33.41 255.3 36.18C263.22 38.95 271.94 44.28 272.6 57.22ZM252.4 69.37C258.47 61.98 258.87 57.49 256.62 52.86C254.37 48.23 247.77 48.11 243.02 49.86C238.27 51.61 232.85 56.19 229.42 64.65C225.99 73.11 222.29 86.43 222.29 86.43C222.02 92.11 225.19 90.13 230.74 87.36C236.29 84.59 246.32 76.76 252.4 69.37ZM220.4 19.85L73.8701 22.23C73.8701 22.23 82.1901 33.92 147.35 33.92C212.51 33.92 220.44 19.85 220.44 19.85H220.4ZM219 59.25L77.8401 61.25C77.8401 61.25 93.3401 72.75 149.84 72.75C206.34 72.75 219 59.25 219 59.25Z"
        fill="white"
      />
      <path
        d="M239.76 100.63C221.82 114.63 198.17 132.74 197.2 135.92C195.75 140.68 193.11 150.18 191.79 154.28C190.47 158.38 183.33 171.18 146.49 171.18C131.18 171.18 107.67 167.08 105.69 156.92C103.71 146.76 101.69 136.85 91.5599 115.06C88.7999 109.13 85.8999 102.72 83.0999 96.06C105.841 91.5267 128.981 89.2921 152.17 89.39C184.84 89.44 215.08 93.58 239.76 100.63Z"
        fill="white"
      />
      <path
        d="M302.84 149.52C302.84 172.26 271.14 192.06 224.4 202.26C224.4 202.12 224.4 201.97 224.4 201.83C224.4 191.52 192.05 183.17 152.15 183.17C112.25 183.17 79.9 191.52 79.9 201.83C79.9 201.97 79.9 202.12 79.9 202.26C33.16 192.06 1.45996 172.26 1.45996 149.52C1.45996 126.27 34.59 106.1 83.06 96.11C85.86 102.72 88.76 109.11 91.52 115.11C101.69 136.9 103.67 146.8 105.65 156.97C107.63 167.14 131.14 171.23 146.45 171.23C183.29 171.23 190.45 158.42 191.75 154.33C193.05 150.24 195.75 140.73 197.16 135.97C198.16 132.79 221.78 114.64 239.72 100.68C278 111.53 302.84 129.37 302.84 149.52Z"
        fill="white"
      />
      <path
        d="M224.42 201.83C224.42 201.97 224.42 202.12 224.42 202.26C200.671 207.258 176.459 209.719 152.19 209.6C127.921 209.719 103.709 207.258 79.96 202.26C79.96 202.12 79.96 201.97 79.96 201.83C79.96 191.52 112.31 183.17 152.21 183.17C192.11 183.17 224.42 191.52 224.42 201.83Z"
        fill="white"
      />
      <path
        d="M73.8699 22.23L220.44 19.85C220.44 19.85 212.52 33.92 147.35 33.92C82.1799 33.92 73.8699 22.23 73.8699 22.23Z"
        fill="white"
      />
      <path
        d="M77.8 61.25L218.96 59.25C218.96 59.25 206.3 72.75 149.8 72.75C93.3 72.75 77.8 61.25 77.8 61.25Z"
        fill="white"
      />
      <path
        d="M224.4 202.26C223.51 212.37 191.51 220.5 152.17 220.5C112.83 220.5 80.8299 212.37 79.9399 202.26C103.689 207.258 127.901 209.719 152.17 209.6C176.439 209.719 200.651 207.258 224.4 202.26V202.26Z"
        fill="white"
      />
      <path
        d="M224.4 202.26C223.51 212.37 191.51 220.5 152.17 220.5C112.83 220.5 80.8299 212.37 79.9399 202.26"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M239.76 100.63C277.95 111.53 302.84 129.37 302.84 149.52C302.84 172.26 271.14 192.06 224.4 202.26C200.651 207.258 176.439 209.719 152.17 209.6C127.901 209.719 103.689 207.258 79.94 202.26C33.2 192.06 1.5 172.26 1.5 149.52C1.5 126.27 34.63 106.1 83.1 96.11"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M239.76 100.63C248.59 93.77 256.03 87.91 258.76 85.35C266.95 77.56 273.29 70.16 272.63 57.22C271.97 44.28 263.25 38.87 255.33 36.09C247.41 33.31 234.33 35.04 226.54 39.92C226.81 38.21 229.18 21.7 225.88 16.82C222.58 11.94 200.63 1.5 146.23 1.5C91.8301 1.5 74.1301 13.65 69.9101 16.55C65.6901 19.45 65.4201 25.4 67.4001 45.07C68.8401 59.4 75.6001 78.42 83.1001 96.07C85.9001 102.68 88.8001 109.07 91.5601 115.07C101.73 136.86 103.71 146.76 105.69 156.93C107.67 167.1 131.18 171.19 146.49 171.19C183.33 171.19 190.49 158.38 191.79 154.29C193.09 150.2 195.79 140.69 197.2 135.93C198.17 132.74 221.82 114.59 239.76 100.63Z"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M252.4 69.37C246.32 76.76 236.29 84.56 230.74 87.37C225.19 90.18 222.02 92.12 222.29 86.44C222.29 86.44 225.99 73.11 229.42 64.66C232.85 56.21 238.27 51.58 243.02 49.87C247.77 48.16 254.38 48.28 256.62 52.87C258.86 57.46 258.47 62 252.4 69.37Z"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M73.8699 22.23C73.8699 22.23 82.1899 33.92 147.35 33.92C212.51 33.92 220.44 19.85 220.44 19.85"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M77.8 61.25C77.8 61.25 93.3 72.75 149.8 72.75C206.3 72.75 219 59.25 219 59.25"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2694_2036">
        <rect width="304.34" height="222" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Glassware
