import React from "react"

const CoffeeRoasters = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    width="362"
    height="207"
    viewBox="0 0 362 207"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2690_1990)">
      <path
        d="M12.47 189.92C4.1 189.92 1.59999 139.25 1.59999 112.09C1.59999 84.93 1.21999 24.5 1.99999 15.5C2.77999 6.5 5.88 1.5 11 1.5L347.46 1.92C354.63 1.92 357.13 7.42 358.79 15.42C360.45 23.42 359.96 86.25 359.96 110.25C359.96 134.25 355.54 189.92 350.63 189.92H12.47Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M275.09 189.88H18.34V33.67C18.34 32.168 18.936 30.7273 19.9971 29.6642C21.0583 28.6012 22.4979 28.0026 24 28H269.42C270.924 28 272.366 28.5974 273.429 29.6607C274.493 30.724 275.09 32.1662 275.09 33.67V189.88Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M39.43 84.58V56.75C39.43 54.25 40.6 51.75 44.43 51.75H249.26C254.59 51.75 257.59 55.58 252.76 59.58C247.93 63.58 221.76 87.91 185.43 87.91H45.26C42.76 87.92 39.43 87.92 39.43 84.58Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M292.22 70.5H334.35C336.35 70.5 338.48 68.75 338.48 66.88V55.13C338.48 53.01 337.61 51.63 335.73 51.63H297.47C295.47 51.63 293.22 51.88 292.1 54.38C290.625 57.894 289.333 61.4822 288.23 65.13C287.47 67.75 287 70.5 292.22 70.5Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M335.47 81.13H290.84C289.183 81.13 287.84 82.4732 287.84 84.13V167.51C287.84 169.167 289.183 170.51 290.84 170.51H335.47C337.127 170.51 338.47 169.167 338.47 167.51V84.13C338.47 82.4732 337.127 81.13 335.47 81.13Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M116.88 34.59H175.88C175.88 34.59 177.02 36.41 175.25 37.34C173.48 38.27 155.17 46.65 146.41 46.65C137.65 46.65 117.74 37.65 117 36.94C116.26 36.23 116.88 34.59 116.88 34.59Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M323.85 100.77C326.987 100.77 329.53 98.227 329.53 95.09C329.53 91.953 326.987 89.41 323.85 89.41C320.713 89.41 318.17 91.953 318.17 95.09C318.17 98.227 320.713 100.77 323.85 100.77Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M312.69 150.09C318.169 150.09 322.61 148.223 322.61 145.92C322.61 143.617 318.169 141.75 312.69 141.75C307.211 141.75 302.77 143.617 302.77 145.92C302.77 148.223 307.211 150.09 312.69 150.09Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M312.69 163.33C318.169 163.33 322.61 161.463 322.61 159.16C322.61 156.857 318.169 154.99 312.69 154.99C307.211 154.99 302.77 156.857 302.77 159.16C302.77 161.463 307.211 163.33 312.69 163.33Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M301.82 100.77C304.957 100.77 307.5 98.227 307.5 95.09C307.5 91.953 304.957 89.41 301.82 89.41C298.683 89.41 296.14 91.953 296.14 95.09C296.14 98.227 298.683 100.77 301.82 100.77Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M323.85 118.52C326.987 118.52 329.53 115.977 329.53 112.84C329.53 109.703 326.987 107.16 323.85 107.16C320.713 107.16 318.17 109.703 318.17 112.84C318.17 115.977 320.713 118.52 323.85 118.52Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M312.69 134.57C315.827 134.57 318.37 132.027 318.37 128.89C318.37 125.753 315.827 123.21 312.69 123.21C309.553 123.21 307.01 125.753 307.01 128.89C307.01 132.027 309.553 134.57 312.69 134.57Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M301.82 118.52C304.957 118.52 307.5 115.977 307.5 112.84C307.5 109.703 304.957 107.16 301.82 107.16C298.683 107.16 296.14 109.703 296.14 112.84C296.14 115.977 298.683 118.52 301.82 118.52Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M26.34 189.82C25.9868 189.807 25.6352 189.874 25.3118 190.016C24.9884 190.159 24.7017 190.373 24.4733 190.643C24.245 190.913 24.081 191.231 23.9938 191.574C23.9066 191.916 23.8984 192.274 23.97 192.62L25.54 202C25.6915 202.772 26.1005 203.469 26.7 203.978C27.2995 204.486 28.0541 204.776 28.84 204.8H45.76C46.5518 204.774 47.3121 204.484 47.9207 203.977C48.5293 203.47 48.9513 202.774 49.12 202L50.9 192.56C50.9808 192.218 50.9812 191.863 50.9013 191.521C50.8213 191.179 50.6632 190.861 50.4394 190.59C50.2155 190.32 49.932 190.105 49.6111 189.963C49.2902 189.82 48.9407 189.754 48.59 189.77L26.34 189.82Z"
        fill="white"
      />
      <path
        d="M26.34 189.82C25.9868 189.807 25.6352 189.874 25.3118 190.016C24.9884 190.159 24.7017 190.373 24.4733 190.643C24.245 190.913 24.081 191.231 23.9938 191.574C23.9066 191.916 23.8984 192.274 23.97 192.62L25.54 202C25.6915 202.772 26.1005 203.469 26.7 203.978C27.2995 204.486 28.0541 204.776 28.84 204.8H45.76C46.5518 204.774 47.3121 204.484 47.9207 203.977C48.5293 203.47 48.9513 202.774 49.12 202L50.9 192.56C50.9808 192.218 50.9812 191.863 50.9013 191.521C50.8213 191.179 50.6632 190.861 50.4394 190.59C50.2155 190.32 49.932 190.105 49.6111 189.963C49.2902 189.82 48.9407 189.754 48.59 189.77L26.34 189.82Z"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M311.09 189.82C310.737 189.807 310.385 189.874 310.062 190.016C309.738 190.159 309.452 190.373 309.223 190.643C308.995 190.913 308.831 191.231 308.744 191.573C308.657 191.916 308.648 192.274 308.72 192.62L310.29 202.04C310.441 202.812 310.85 203.509 311.45 204.017C312.05 204.526 312.804 204.816 313.59 204.84H330.5C331.29 204.814 332.05 204.526 332.658 204.021C333.266 203.516 333.689 202.822 333.86 202.05L335.64 192.61C335.721 192.268 335.721 191.913 335.641 191.571C335.561 191.229 335.403 190.91 335.179 190.64C334.956 190.37 334.672 190.155 334.351 190.013C334.03 189.87 333.681 189.804 333.33 189.82H311.09Z"
        fill="white"
      />
      <path
        d="M311.09 189.82C310.737 189.807 310.385 189.874 310.062 190.016C309.738 190.159 309.452 190.373 309.223 190.643C308.995 190.913 308.831 191.231 308.744 191.573C308.657 191.916 308.648 192.274 308.72 192.62L310.29 202.04C310.441 202.812 310.85 203.509 311.45 204.017C312.05 204.526 312.804 204.816 313.59 204.84H330.5C331.29 204.814 332.05 204.526 332.658 204.021C333.266 203.516 333.689 202.822 333.86 202.05L335.64 192.61C335.721 192.268 335.721 191.913 335.641 191.571C335.561 191.229 335.403 190.91 335.179 190.64C334.956 190.37 334.672 190.155 334.351 190.013C334.03 189.87 333.681 189.804 333.33 189.82H311.09Z"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M18.43 178.75H274.93"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2690_1990">
        <rect width="361.5" height="206.33" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default CoffeeRoasters
