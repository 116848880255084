import React from "react"

const TampingStands = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    viewBox="0 0 347 302"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2694_2121)">
      <path
        d="M183.51 270.71V291.83C183.51 291.83 180.76 300.46 165.14 300.46C149.52 300.46 145.14 292.71 145.14 292.71V269.71H145.19C147.19 271.78 152.53 275.41 164.76 274.5C167.65 274.29 174.34 272.87 183.49 270.67L183.51 270.71Z"
        fill="white"
      />
      <path
        d="M151.72 78.66C183.062 78.66 208.47 67.6193 208.47 54C208.47 40.3806 183.062 29.34 151.72 29.34C120.378 29.34 94.97 40.3806 94.97 54C94.97 67.6193 120.378 78.66 151.72 78.66Z"
        fill="white"
      />
      <path
        d="M25.8 40.5L191.3 1.5L315.89 94.58C318.39 96.46 319.3 99.33 319.3 102.33V192.5C319.3 200.5 316.3 205.72 313.9 207.12L313.47 207.33C310.97 208.33 170.47 249.83 165.97 250.83C162.177 251.573 158.265 251.443 154.53 250.45C153.146 250.114 151.866 249.444 150.8 248.5C148.55 246.25 70.95 180.5 39.17 152.64C36.82 150.58 34.73 148.73 32.93 147.12C28.43 143.12 25.8 140.62 25.8 140.33V40.5V40.5ZM208.47 54C208.47 40.38 183.06 29.34 151.72 29.34C120.38 29.34 95 40.38 95 54C95 67.62 120.41 78.67 151.75 78.67C183.09 78.67 208.47 67.62 208.47 54Z"
        fill="white"
      />
      <path
        d="M343.89 222.46V249.33C343.89 249.33 340.14 256.83 325.39 256.83C310.64 256.83 306.14 249.08 306.14 249.08V237.83L306.02 237.41C322.02 232.88 333.31 229.66 335.02 229.21C339.37 228 342.38 226 343.89 222.46Z"
        fill="white"
      />
      <path
        d="M345 216.21C345.056 218.348 344.675 220.475 343.88 222.46C342.37 225.96 339.36 228.04 335 229.21C333.3 229.66 322 232.88 306 237.41C271.15 247.26 213.82 263.3 183.47 270.63C174.32 272.83 167.63 274.25 164.74 274.46C152.51 275.37 147.17 271.74 145.17 269.67C144.758 269.275 144.405 268.824 144.12 268.33V261.58C144.12 253.83 154.37 250.83 154.37 250.83L154.51 250.45C158.245 251.443 162.157 251.573 165.95 250.83C170.45 249.83 310.95 208.33 313.45 207.33L313.88 207.12C334.8 201.5 345 208.86 345 216.21Z"
        fill="white"
      />
      <path
        d="M32.93 147.12L32.51 147.58C26.89 150.21 17.14 150.46 9.01002 148.83C5.93002 148.21 4.11002 147.16 3.01002 145.83C1.25002 143.63 1.49002 140.65 1.49002 137.47C1.49002 132.34 4.24002 130.84 7.37002 129.59C10.5 128.34 25.12 125.09 25.12 125.09H25.78V140.34C25.8 140.62 28.43 143.1 32.93 147.12Z"
        fill="white"
      />
      <path
        d="M39.17 152.64L38.89 152.96V168.46C38.89 168.46 29.76 175.33 19.01 175.33C8.26001 175.33 2.76001 169 2.76001 169V145.83H3.00001C4.08001 147.17 5.90001 148.22 9.00001 148.83C17.13 150.46 26.88 150.21 32.5 147.58L32.92 147.12C34.73 148.73 36.82 150.58 39.17 152.64Z"
        fill="white"
      />
      <path
        d="M313.9 207.12C316.33 205.72 319.3 200.52 319.3 192.5V102.33C319.3 99.33 318.39 96.46 315.89 94.58L191.3 1.5L25.8 40.5V140.33C25.8 140.62 28.43 143.1 32.93 147.12C34.73 148.73 36.82 150.58 39.17 152.64C71 180.54 148.55 246.25 150.8 248.5C151.866 249.444 153.146 250.114 154.53 250.45C158.265 251.443 162.177 251.573 165.97 250.83C170.47 249.83 310.97 208.33 313.47 207.33L313.9 207.12Z"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.14 39.17C32.14 39.17 149.64 135.83 151.8 137.67C153.96 139.51 154.97 141.17 154.97 143.83V244.5"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.72 78.66C183.062 78.66 208.47 67.6193 208.47 54C208.47 40.3806 183.062 29.34 151.72 29.34C120.378 29.34 94.97 40.3806 94.97 54C94.97 67.6193 120.378 78.66 151.72 78.66Z"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M155.14 149.17L205 136.5V122.33C205 120.17 185.83 119 185.33 114C184.83 109 198.17 102.83 221 102.83C243.83 102.83 257.83 112.5 257.83 118.33C257.83 124.16 268.67 120.17 276.83 117.67C284.99 115.17 311 107.83 311 107.83V201.17"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M212.51 128.71L302.8 198.83"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.14 268.37C144.425 268.864 144.778 269.316 145.19 269.71C147.19 271.78 152.53 275.41 164.76 274.5C167.65 274.29 174.34 272.87 183.49 270.67C213.84 263.34 271.17 247.3 306.02 237.45C322.02 232.92 333.31 229.7 335.02 229.25C339.38 228.08 342.39 226 343.9 222.5C344.695 220.515 345.076 218.388 345.02 216.25C345.02 208.9 334.81 201.54 313.91 207.16C313.49 207.26 313.07 207.38 312.65 207.5"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.39 250.83C154.39 250.83 144.14 253.83 144.14 261.58V268.33"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.14 269.71V292.71C145.14 292.71 149.51 300.46 165.14 300.46C180.77 300.46 183.51 291.83 183.51 291.83V270.71"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M306.14 237.83V249.08C306.14 249.08 310.64 256.83 325.39 256.83C340.14 256.83 343.89 249.33 343.89 249.33V222.46"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.14 125.08C25.14 125.08 10.51 128.33 7.39004 129.58C4.27004 130.83 1.51004 132.33 1.51004 137.46C1.51004 140.64 1.27004 143.62 3.03004 145.82C4.11004 147.16 5.93004 148.21 9.03004 148.82C17.16 150.45 26.91 150.2 32.53 147.57"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.89 153V168.5C38.89 168.5 29.76 175.37 19.01 175.37C8.26001 175.37 2.76001 169 2.76001 169V145.83"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2694_2121">
        <rect width="346.51" height="301.96" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default TampingStands
