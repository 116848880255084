import React from "react"

const CleaningDescaling = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    width="381"
    height="297"
    viewBox="0 0 381 297"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2690_1914)">
      <path
        d="M99.73 217.72L95.34 223C92.34 211.28 81.95 206.09 76.93 205.76H76.84C79.22 202.76 81.84 199.46 84.66 195.96C90.08 196.69 99.96 201.96 103.42 213.31L99.73 217.72Z"
        fill="white"
      />
      <path
        d="M121.82 191.36C121.06 192.26 120.29 193.17 119.53 194.09C115.53 182.79 105.53 177.5 99.95 176.82C102.25 173.92 104.59 170.99 106.95 168.07C112.53 168.75 122.77 174.12 126.65 185.64L121.82 191.36Z"
        fill="white"
      />
      <path
        d="M119.53 194.09L115.13 199.32L111.7 203.4C108.3 192.01 98.52 186.55 92.9 185.63L99.9 176.82C105.49 177.5 115.58 182.79 119.53 194.09Z"
        fill="white"
      />
      <path
        d="M375.73 8.24998C377.85 9.87998 381.35 14 378.23 19.63C375.326 24.5971 371.131 28.6845 366.09 31.4578C361.048 34.231 355.35 35.5865 349.6 35.38C345.045 35.1891 340.595 33.9467 336.6 31.75C336.6 31.75 226.73 107 220 111.5C213.27 116 205.87 120.63 200 121.63C194.13 122.63 180.35 128 176.1 131C171.85 134 158.48 148.25 147.73 160.75C145.3 163.57 140.55 169.16 134.63 176.17C130.18 164.74 119.63 159.51 114.13 159.03C130.25 138.85 144.57 120.9 146.98 117.88C151.718 111.595 155.742 104.804 158.98 97.63C160.23 94.75 161.35 92 169.6 89.5C181.98 85.13 322.73 20.5 323.6 19.75C324.47 19 324.23 16.63 323.1 14.63C320.73 9.62998 322.85 5.49998 327.35 3.74998C331.85 1.99998 340.1 0.879977 352.6 1.87998C365.1 2.87998 373.6 6.62998 375.73 8.24998Z"
        fill="white"
      />
      <path
        d="M111.7 203.4L107.76 208.11L103.42 213.3C100 202 90.08 196.68 84.66 195.95L92.9 185.63C98.52 186.55 108.3 192 111.7 203.4Z"
        fill="white"
      />
      <path
        d="M134.63 176.17L129.26 182.53L126.63 185.64C122.75 174.12 112.51 168.75 106.93 168.07L108 166.7L114.11 159.03C119.65 159.51 130.18 164.74 134.63 176.17Z"
        fill="white"
      />
      <path
        d="M92.27 226.7L87.37 232.62C84.37 221.01 74.37 215.76 69.2 215.28C69.99 214.28 70.86 213.22 71.78 212.06L76.84 205.75H76.93C81.93 206.08 92.29 211.27 95.34 222.99L92.27 226.7Z"
        fill="white"
      />
      <path
        d="M10.85 276.88C7.26 266.4 6.73 261.5 7.6 257C8.20746 254.771 9.18799 252.661 10.5 250.76C9.88 254.44 9.35 257.95 9.23 259.38C8.98 262.5 11.48 269.75 16.35 273.5C21.22 277.25 32.73 280.63 44.85 277.25C56.97 273.87 66.73 264.5 68.85 258.38C70.97 252.26 70.85 245.38 69.23 243.13C68.23 241.71 61.03 233.69 55.04 227.8C58.04 225.99 62.92 223.02 63.6 222.25C63.76 222.07 65.82 219.51 69.2 215.25C74.32 215.73 84.42 220.98 87.37 232.59L84.21 236.46C81 240.4 79 243 78.6 243.63C76.48 247.25 78.02 250.38 79.27 256.75C80.52 263.12 74.41 282.47 60.6 289.42C42.14 298.71 17.6 296.59 10.85 276.88Z"
        fill="white"
      />
      <path
        d="M33.58 246.82C34.6846 246.643 35.7768 246.396 36.85 246.08C38.2234 245.686 39.5614 245.177 40.85 244.56C43.3324 243.406 45.5775 241.798 47.47 239.82C49.84 237.26 51.17 234.32 51.05 231.47H51.22L50.94 230.47C50.9197 230.318 50.8896 230.168 50.85 230.02L52.64 229.27L55 227.8C61 233.69 68.16 241.71 69.19 243.13C70.81 245.38 70.94 252.25 68.81 258.38C66.68 264.51 56.94 273.88 44.81 277.25C32.68 280.62 21.19 277.25 16.31 273.5C11.43 269.75 9.00001 262.5 9.23001 259.38C9.35001 257.95 9.88001 254.44 10.5 250.76C11.7276 248.849 13.0631 247.009 14.5 245.25L18.66 243.5C20.7575 245.328 23.3435 246.503 26.1 246.88C28.58 247.267 31.1065 247.247 33.58 246.82V246.82Z"
        fill="white"
      />
      <path
        d="M55 227.8L52.56 229.25L50.77 230C50.7361 229.828 50.6927 229.657 50.64 229.49V229.49L48.49 222C50.8101 223.758 52.9869 225.697 55 227.8V227.8Z"
        fill="white"
      />
      <path
        d="M50.9 230.45L51.18 231.45H51.01C50.9971 231.114 50.9604 230.78 50.9 230.45V230.45Z"
        fill="white"
      />
      <path
        d="M51 231.47C51.12 234.32 49.79 237.26 47.42 239.82L47.22 239.63L45.43 233.09L51 231.47Z"
        fill="white"
      />
      <path
        d="M51 231.47L45.43 233.09L45.23 232.34L50.78 230L50.9 230.44C50.9585 230.78 50.9919 231.125 51 231.47V231.47Z"
        fill="white"
      />
      <path
        d="M50.9 230.45L50.78 230.01C50.8293 230.154 50.8694 230.301 50.9 230.45V230.45Z"
        fill="white"
      />
      <path
        d="M50.68 229.49C50.7327 229.657 50.7761 229.828 50.81 230V230L50.66 229.49H50.68Z"
        fill="white"
      />
      <path
        d="M50.78 230.01L45.24 232.34L44.92 231.19L50.63 229.5L50.78 230.01Z"
        fill="white"
      />
      <path
        d="M48.49 222L50.63 229.48L44.92 231.17L42.44 222.08C43.9897 221.593 45.6057 221.35 47.23 221.36C47.683 221.501 48.109 221.717 48.49 222V222Z"
        fill="white"
      />
      <path
        d="M47.23 221.38C45.6057 221.37 43.9897 221.613 42.44 222.1L36.1 198.88L35.93 198.72C38.55 195.82 39.81 192.55 39.18 189.53L48.49 222C48.1076 221.724 47.6817 221.515 47.23 221.38V221.38Z"
        fill="white"
      />
      <path
        d="M47.23 239.63L47.43 239.82C45.5375 241.798 43.2924 243.406 40.81 244.56L40.6 244.13L38.09 235.34L39 235L45.45 233.13L47.23 239.63Z"
        fill="white"
      />
      <path
        d="M45.24 232.34L45.44 233.09L38.99 234.96L45.24 232.34Z"
        fill="white"
      />
      <path
        d="M44.92 231.19L45.24 232.34L38.99 234.96L38.06 235.23L37.53 233.37L44.92 231.19Z"
        fill="white"
      />
      <path
        d="M42.44 222.1L44.92 231.19L37.53 233.37L34.91 224.22C37.68 223.37 40.31 222.62 42.44 222.1Z"
        fill="white"
      />
      <path
        d="M36.1 198.88L42.44 222.1C40.31 222.62 37.68 223.37 34.91 224.22L29.1 203.88C31.6763 202.605 34.0004 200.873 35.96 198.77L36.1 198.88Z"
        fill="white"
      />
      <path
        d="M40.6 244.13L40.81 244.56C39.5214 245.177 38.1834 245.686 36.81 246.08C35.7368 246.396 34.6446 246.643 33.54 246.82L31.08 238.27L38.08 235.34L40.6 244.13Z"
        fill="white"
      />
      <path
        d="M1.72 199.58L17.25 195.29L39.1 189.29L39.18 189.57C39.81 192.57 38.55 195.86 35.93 198.76C33.9704 200.863 31.6463 202.594 29.07 203.87C27.3871 204.732 25.6258 205.432 23.81 205.96C23.19 206.14 22.57 206.31 21.96 206.44C19.0947 207.131 16.137 207.357 13.2 207.11C7.67 206.59 3.32 204.19 1.95 200.37C1.9 200.23 1.85 200.09 1.81 199.95L1.72 199.58Z"
        fill="white"
      />
      <path
        d="M17.25 195.29L1.72 199.58C0.160002 193.29 6.92 185.89 17 182.91C27.28 179.91 37.13 182.57 39 188.91C39.06 189.11 39.11 189.31 39.15 189.51L39.07 189.23L17.25 195.29Z"
        fill="white"
      />
      <path
        d="M38.99 234.96L38.09 235.34L38.06 235.23L38.99 234.96Z"
        fill="white"
      />
      <path
        d="M38.06 235.23L38.09 235.34L31.12 238.27L30.85 237.33L38.06 235.23Z"
        fill="white"
      />
      <path
        d="M37.53 233.37L38.06 235.23L30.85 237.33L30.32 235.5L37.53 233.37Z"
        fill="white"
      />
      <path
        d="M37.53 233.37L30.32 235.5L27.73 226.5C30.09 225.72 32.55 224.94 34.91 224.23L37.53 233.37Z"
        fill="white"
      />
      <path
        d="M29.1 203.88L34.91 224.22C32.55 224.93 30.09 225.71 27.73 226.49L22 206.5V206.4C22.61 206.27 23.23 206.1 23.85 205.92C25.6658 205.392 27.4271 204.692 29.11 203.83L29.1 203.88Z"
        fill="white"
      />
      <path
        d="M31.12 238.27L33.58 246.82C31.1 247.249 28.5665 247.269 26.08 246.88V246.75L24.26 241.14L31.12 238.27Z"
        fill="white"
      />
      <path
        d="M30.85 237.33L31.12 238.27L24.28 241.14L23.71 239.4L30.85 237.33Z"
        fill="white"
      />
      <path
        d="M30.32 235.5L30.85 237.33L23.71 239.4L23.13 237.62L30.32 235.5Z"
        fill="white"
      />
      <path
        d="M27.73 226.49L30.32 235.49L23.13 237.61L20.32 228.94C22.43 228.23 25 227.37 27.73 226.49Z"
        fill="white"
      />
      <path
        d="M22 206.5L27.75 226.5C25.03 227.38 22.45 228.24 20.34 228.96L13.2 207.07C16.1503 207.321 19.1218 207.095 22 206.4V206.5Z"
        fill="white"
      />
      <path
        d="M24.28 241.14L26.1 246.75V246.88C23.3435 246.503 20.7575 245.328 18.66 243.5L24.28 241.14Z"
        fill="white"
      />
      <path
        d="M23.71 239.4L24.28 241.14L18.64 243.5C17.972 242.877 17.3992 242.159 16.94 241.37L23.71 239.4Z"
        fill="white"
      />
      <path
        d="M23.13 237.62L23.71 239.4L16.94 241.4C16.639 240.858 16.3976 240.284 16.22 239.69L23.13 237.62Z"
        fill="white"
      />
      <path
        d="M16.22 239.66C16.3976 240.254 16.639 240.828 16.94 241.37L16.77 241.42L14.11 234C14.65 232.15 15.11 230.72 15.11 230.72C15.11 230.72 17.21 229.98 20.33 228.92L23.14 237.59L16.22 239.66Z"
        fill="white"
      />
      <path
        d="M18.64 243.5L14.48 245.25C13.0431 247.009 11.7076 248.849 10.48 250.76C11.21 246.39 12.05 241.76 12.48 240C12.91 238.24 13.54 236 14.11 234L16.77 241.39L16.94 241.34C17.3968 242.14 17.9697 242.868 18.64 243.5V243.5Z"
        fill="white"
      />
      <path
        d="M14.11 234L2 200.33C3.37 204.15 7.72 206.55 13.25 207.07L20.37 228.95C17.25 230.01 15.15 230.75 15.15 230.75C15.15 230.75 14.65 232.18 14.11 234Z"
        fill="white"
      />
      <path
        d="M1.81001 199.91C1.81001 200.05 1.90001 200.19 1.95001 200.33L1.68001 199.59L1.81001 199.91Z"
        fill="white"
      />
      <path
        d="M10.5 250.76C9.18799 252.661 8.20746 254.771 7.6 257C6.73 261.5 7.26 266.4 10.85 276.88C17.6 296.59 42.14 298.71 60.6 289.42C74.41 282.47 80.52 263.13 79.27 256.75C78.02 250.37 76.48 247.25 78.6 243.63C78.97 242.99 81.02 240.4 84.21 236.49L87.37 232.62L92.27 226.7L95.34 223L99.73 217.73L103.42 213.31L107.76 208.12L111.7 203.41L115.13 199.33L119.53 194.1C120.29 193.18 121.06 192.27 121.82 191.37L126.63 185.65L129.26 182.54L134.63 176.18C140.55 169.18 145.3 163.58 147.73 160.76C158.48 148.25 171.85 134 176.1 131C180.35 128 194.1 122.63 199.98 121.63C205.86 120.63 213.23 116 219.98 111.5C226.73 107 336.6 31.75 336.6 31.75C340.595 33.9467 345.045 35.1891 349.6 35.38C355.35 35.5865 361.048 34.231 366.09 31.4578C371.131 28.6845 375.326 24.5971 378.23 19.63C381.35 14 377.85 9.87998 375.73 8.24998C373.61 6.61998 365.1 2.87998 352.6 1.87998C340.1 0.879977 331.85 1.99998 327.35 3.74998C322.85 5.49998 320.73 9.62998 323.1 14.63C324.23 16.63 324.48 19 323.6 19.75C322.72 20.5 182 85.13 169.6 89.5C161.35 92 160.23 94.75 158.98 97.63C155.742 104.804 151.718 111.595 146.98 117.88C144.57 120.88 130.25 138.88 114.13 159.03L108 166.7L106.91 168.07C104.57 170.99 102.23 173.92 99.91 176.82L92.91 185.63L84.67 195.95C81.87 199.45 79.23 202.75 76.85 205.75L71.79 212.06C70.87 213.22 70 214.3 69.21 215.28C65.83 219.51 63.77 222.07 63.61 222.28C62.93 223.05 58.08 226.02 55.05 227.83"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M14.11 234C13.54 235.92 12.86 238.31 12.48 240C12.1 241.69 11.23 246.39 10.48 250.76C9.86 254.44 9.33 257.95 9.21 259.38C8.96 262.5 11.46 269.75 16.33 273.5C21.2 277.25 32.71 280.63 44.83 277.25C56.95 273.87 66.71 264.5 68.83 258.38C70.95 252.26 70.83 245.38 69.21 243.13C68.21 241.71 61.01 233.69 55.02 227.8C53.0005 225.697 50.817 223.757 48.49 222"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M16.94 241.37L16.77 241.42L14.11 234.03L1.95001 200.33L1.68001 199.59L1.72001 199.58"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M50.9 230.45L51.18 231.42L51.01 231.47"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M39.18 189.53L48.49 222.02L50.63 229.5"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M16.22 239.66C16.3976 240.254 16.639 240.828 16.94 241.37C17.3992 242.159 17.972 242.877 18.64 243.5C20.7375 245.328 23.3235 246.503 26.08 246.88C28.5665 247.269 31.1 247.249 33.58 246.82C34.6846 246.643 35.7768 246.396 36.85 246.08C38.2234 245.686 39.5614 245.177 40.85 244.56C43.3324 243.406 45.5775 241.798 47.47 239.82C49.84 237.26 51.17 234.32 51.05 231.47C51.0371 231.134 51.0004 230.8 50.94 230.47C50.9197 230.318 50.8896 230.168 50.85 230.02C50.8161 229.848 50.7727 229.677 50.72 229.51"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M39.18 189.53C39.14 189.33 39.09 189.13 39.03 188.93C37.16 182.57 27.31 179.88 17.03 182.93C6.92001 185.93 0.160006 193.31 1.72001 199.6L1.81001 199.93C1.81001 200.07 1.90001 200.21 1.95001 200.35C3.32001 204.17 7.67001 206.57 13.2 207.09C16.1514 207.335 19.1229 207.102 22 206.4C22.61 206.27 23.23 206.1 23.85 205.92C25.6658 205.392 27.4271 204.692 29.11 203.83C31.6863 202.555 34.0104 200.823 35.97 198.72C38.55 195.82 39.81 192.55 39.18 189.53Z"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M29.1 203.88L34.91 224.22L37.53 233.37L38.06 235.23L38.09 235.34L40.6 244.13"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M36.1 198.88L42.44 222.1L44.92 231.19L45.24 232.34L45.44 233.09L47.23 239.63"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M21.98 206.5L27.73 226.49L30.32 235.5L30.85 237.33L31.12 238.27L33.58 246.82L33.6 246.88"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M13.1 206.75L13.2 207.07L20.32 228.95L23.13 237.62L23.71 239.4L24.28 241.14L26.1 246.75"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M8.98 198.3L9.85 200.92"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M19.48 199.46L20.35 202.09"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M26.6 196.53L27.48 199.15"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M33.35 191.9L34.23 194.53"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M16.48 192.96L17.25 195.29L17.35 195.59"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M24.48 190.03L25.35 192.65"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M29.85 186.03L30.73 188.65"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M10.16 190.65L11.04 193.28"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M18.54 186.71L19.41 189.34"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M326.85 10C326.85 10 333.73 17.25 350.1 18C366.47 18.75 373.98 15.5 374.6 14.5"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M164.27 96.59C170.416 100.437 177.323 102.905 184.515 103.825C191.707 104.745 199.013 104.095 205.93 101.92C229.93 94.59 324.48 29.38 328.1 27.63"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M63.85 221.5C63.85 221.5 63.77 214.92 68.93 215.25H69.2C74.32 215.73 84.42 220.98 87.37 232.59C87.45 232.91 87.53 233.23 87.6 233.59C88.29 236.9 85.27 236.67 84.21 236.49C84.0886 236.474 83.9684 236.451 83.85 236.42"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M71.77 212.25C71.77 212.25 71.77 212.18 71.77 212.06C71.77 210.97 72.27 205.52 76.83 205.75H76.92C81.92 206.08 92.28 211.27 95.33 222.99C95.42 223.35 95.51 223.72 95.59 224.09C96.35 227.74 92.87 226.88 92.26 226.7H92.17"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M78.6 202.92C78.6 202.92 78.41 195.52 83.96 195.92C84.18 195.92 84.41 195.92 84.66 195.99C90.08 196.72 99.96 201.99 103.42 213.34C103.65 214.07 103.84 214.83 104.01 215.61C104.82 219.5 100.55 218.07 99.73 217.76H99.6"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M86.35 192.92C86.35 192.92 86.18 185.14 91.83 185.51C92.17 185.51 92.52 185.57 92.9 185.63C98.52 186.55 108.3 192.01 111.7 203.4C111.92 204.1 112.1 204.82 112.26 205.56C113.17 209.94 107.93 208.25 107.93 208.25"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M93.68 183.67C93.68 183.67 93.46 176.38 99.32 176.76L99.95 176.82C105.49 177.5 115.58 182.82 119.53 194.09C119.939 195.22 120.273 196.376 120.53 197.55C121.53 202.09 115.38 199.5 115.38 199.5"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M100.85 175.09C100.85 175.09 100.46 167.63 106.32 168.01L106.93 168.07C112.51 168.75 122.75 174.12 126.63 185.64C126.984 186.675 127.274 187.73 127.5 188.8C128.4 193.11 122.43 191.53 121.82 191.36"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M107.77 166.5C107.77 166.5 107.77 158.61 113.88 159.01H114.13C119.65 159.49 130.13 164.72 134.63 176.15C135.21 177.63 135.668 179.155 136 180.71C137 185.45 129.43 182.65 129.43 182.65"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2690_1914">
        <rect width="380.97" height="296.23" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default CleaningDescaling
