import React from "react"

const TravelMugs = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    viewBox="0 0 130 368"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2694_2164)">
      <path
        d="M122 198.38L118 353.54C118 353.54 113.67 366.2 66.67 366.2C19.67 366.2 13.33 352.87 13.33 352.87L7.72 198.54H7.77C25.5993 204.569 44.2891 207.662 63.11 207.7C94.11 207.7 121.77 198.37 121.77 198.37L122 198.38Z"
        fill="white"
      />
      <path
        d="M2.27 46.87C2.27 46.87 9.94 54.87 67.77 54.87C110.83 54.87 122.67 49.42 125.9 46.64L124.4 104.7H124.27C124.27 104.7 112.27 112.87 65.61 112.87C18.95 112.87 4.44 104.7 4.44 104.7H4.35L2.27 46.87Z"
        fill="white"
      />
      <path
        d="M124.4 104.7L122 198.38H121.8C121.8 198.38 94.14 207.71 63.14 207.71C44.3191 207.672 25.6293 204.579 7.8 198.55H7.72L4.35 104.7H4.44C4.44 104.7 18.94 112.87 65.61 112.87C112.28 112.87 124.27 104.7 124.27 104.7H124.4Z"
        fill="white"
      />
      <path
        d="M71.65 45.93L110.83 45.4C99.55 49 85.24 48.22 71.65 45.93Z"
        fill="white"
      />
      <path
        d="M13.7 25.1701C13.94 25.8501 19.27 28.4701 24.77 31.0201C33.7187 35.5551 43.0988 39.1831 52.77 41.8501C58.59 43.3501 65.02 44.8501 71.61 45.9101L2.23 46.8501C2.23 46.8501 1.9 28.8501 1.9 25.1801C1.94 22.3201 -2 14.2601 13.62 9.82007C13.11 11.7401 13.69 23.9201 13.69 25.1201C13.6894 25.1373 13.6928 25.1544 13.7 25.1701V25.1701Z"
        fill="white"
      />
      <path
        d="M48.56 35.2501C44.7111 35.8668 40.8324 36.2807 36.94 36.4901C36.94 36.4901 30.79 33.8101 24.82 31.0001C20.56 28.9001 16.82 26.8501 13.75 25.1501C13.75 23.9501 13.17 11.7701 13.68 9.85007C19.328 8.38089 25.1114 7.49294 30.94 7.20007C32.36 7.11007 33.76 7.02007 35.14 6.95007C40 14.0001 47.18 26.6301 48.56 35.2501Z"
        fill="white"
      />
      <path
        d="M110.83 45.4001L71.65 45.9301C65.06 44.8301 58.65 43.3701 52.81 41.8701C43.1407 39.1897 33.764 35.5482 24.82 31.0001C30.82 33.7701 36.94 36.4501 36.94 36.4501C40.8324 36.2407 44.7111 35.8268 48.56 35.2101C47.18 26.6301 40 14.0001 35.14 6.95006C68.33 5.24006 92.86 9.73006 107.14 14.3701C121.97 19.2001 128.14 29.0401 127.64 37.0401L127.14 45.0401C127.14 45.0401 127.04 45.6801 125.93 46.6401V45.2001L110.83 45.4001Z"
        fill="white"
      />
      <path
        d="M13.7 25.17C16.7 26.87 20.51 28.92 24.77 31.02C19.27 28.47 13.94 25.85 13.7 25.17Z"
        fill="white"
      />
      <path
        d="M30.94 7.20003C25.0923 7.479 19.2886 8.35692 13.62 9.82003C13.6306 9.74957 13.6544 9.68174 13.69 9.62003C14.29 8.62003 8.98 11.23 8.69 11.36C8.75 11.05 10.26 4.83003 13.43 3.12003C16.6 1.41003 29.3 1.37003 30.8 1.62003C31.27 1.70003 32.96 3.77003 35.13 6.95003C33.76 7.00003 32.36 7.11003 30.94 7.20003Z"
        fill="white"
      />
      <path
        d="M2.27 46.8701L71.65 45.9301C85.24 48.2201 99.55 48.9901 110.83 45.4001L125.94 45.2001V46.6401C122.71 49.4201 110.87 54.8701 67.81 54.8701C9.94 54.8701 2.27 46.8701 2.27 46.8701Z"
        fill="white"
      />
      <path
        d="M2.27 46.87L4.35 104.7L7.72 198.54L13.27 352.87C13.27 352.87 19.61 366.2 66.61 366.2C113.61 366.2 117.94 353.54 117.94 353.54L121.94 198.38L124.37 104.7L125.87 46.64"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.14 6.95006C68.33 5.24006 92.86 9.73006 107.14 14.3701C121.97 19.2001 128.14 29.0401 127.64 37.0401L127.14 45.0401C127.14 45.0401 127.04 45.6801 125.93 46.6401C122.7 49.4201 110.86 54.8701 67.8 54.8701C9.97 54.8701 2.3 46.8701 2.3 46.8701C2.3 46.8701 1.97 28.8701 1.97 25.2001C1.94 22.3201 -2 14.2601 13.62 9.82006"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.82 31C33.7687 35.535 43.1488 39.1631 52.82 41.83C58.64 43.33 65.07 44.83 71.66 45.89C85.25 48.18 99.56 48.95 110.84 45.36C115.445 44.0121 119.676 41.6162 123.2 38.36"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.06 20.62C6.06 20.62 9 22.48 13.7 25.17"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.7 11.36C8.76 11.05 10.27 4.83003 13.44 3.12003C16.61 1.41003 29.31 1.37003 30.81 1.62003C31.28 1.70003 32.97 3.77003 35.14 6.95003C40 14 47.18 26.63 48.56 35.25C44.7111 35.8667 40.8324 36.2806 36.94 36.49C36.94 36.49 30.79 33.81 24.82 31C19.32 28.45 13.99 25.83 13.75 25.15C13.75 23.95 13.17 11.77 13.68 9.85003C13.6906 9.77958 13.7144 9.71174 13.75 9.65003C14.35 8.65003 9.04 11.26 8.75 11.39"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.44 104.7C4.44 104.7 18.94 112.87 65.61 112.87C112.28 112.87 124.27 104.7 124.27 104.7"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.77 198.54C25.5993 204.569 44.2891 207.662 63.11 207.7C94.11 207.7 121.77 198.37 121.77 198.37"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2694_2164">
        <rect width="129.14" height="367.7" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default TravelMugs
