import React from "react"

const TeaKettles = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    viewBox="0 0 375 331"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2694_2301)">
      <path
        d="M180.48 43.78C180.48 43.78 172.31 35.13 172.31 25.07C172.31 15.01 182.68 1.5 185.67 1.5C188.66 1.5 198.87 12.03 198.87 25.39C198.937 29.1742 198.05 32.9144 196.292 36.2662C194.534 39.6179 191.961 42.4735 188.81 44.57L180.48 43.78Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.44 198C34.88 185 32 166.88 33.75 154.61C34.04 152.61 40.85 105.06 32.62 82.9701C29.5779 75.1705 25.2589 67.9316 19.84 61.55H1.5C3.84 63.55 15.09 73.7401 20.25 87.5501C22.71 94.1501 23.79 105.65 23.37 120.8C23.0458 131.464 22.1511 142.102 20.69 152.67C18.69 166.67 21.22 189.14 35.21 206.31C46.08 219.65 62.15 227.31 81.7 228.44L82.47 215.26C66.49 214.36 54 208.54 45.44 198Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.47 287.11C100.47 287.11 83.97 287.27 78.47 300.31C72.97 313.35 71.71 317 71.71 317C71.71 317 68.88 329.1 181.27 329.1C293.66 329.1 299.48 315.1 299.48 315.1C299.48 315.1 295.39 302.21 292.41 297.5C289.43 292.79 285.18 288.23 267.57 287.6C249.96 286.97 100.47 287.11 100.47 287.11Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183.47 293.71C117.8 293.71 94.34 290.09 89.47 288.05C84.6 286.01 78 282.24 77.52 273.12C77.04 264 76.66 254.73 80.9 194.12C85.14 133.51 115.88 86.7 126.41 73C136.94 59.3 155.33 41.72 185.83 41.72C216.33 41.72 241.16 71.43 241.16 71.43C241.16 71.43 282.5 133.76 288.87 219.58C290.99 247.17 290.28 274.52 289.34 278.53C288.4 282.54 282.27 288.2 277.34 289.14C272.41 290.08 249.13 293.71 183.47 293.71Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M307.49 249.7C307.49 249.7 320.38 227.06 357 224.55C359.67 224.24 357.94 231.31 357.94 231.31C357.94 231.31 336.25 235.4 328.07 242.79C319.89 250.18 314.71 257.72 314.24 259.14C313.77 260.56 307.48 265.27 303.55 265.27C299.62 265.27 307.49 249.7 307.49 249.7Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.89 258.35C76.89 258.35 102.67 265.27 184.25 265.27C265.83 265.27 289.41 259 289.41 259"
        fill="white"
      />
      <path
        d="M76.89 258.35C76.89 258.35 102.67 265.27 184.25 265.27C265.83 265.27 289.41 259 289.41 259"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M246.5 82.29C246.5 82.29 257.19 72.39 279.82 64.37C302.45 56.35 323.36 60.91 339.24 69.71C355.12 78.51 371.78 103.51 373.04 125.51C374.3 147.51 367.85 180.68 344.12 205.2C320.39 229.72 308.12 238.84 308.12 238.84C308.12 238.84 307.02 265.56 305.92 269.49C304.82 273.42 296.92 280.65 287.53 280.81C285.33 244.18 281.32 200.01 271.18 162.29C261.04 124.57 246.42 87.08 246.42 87.08C246.08 86.325 245.91 85.5041 245.924 84.676C245.938 83.8479 246.135 83.0332 246.5 82.29V82.29Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M307.73 209.23C307.73 209.23 307.25 141.79 279 95.58C284.19 73.42 313.66 68.47 335.35 89.69C357.04 110.91 360.81 151.69 345.25 176.22C329.69 200.75 318.25 211.12 314.36 212.88C310.47 214.64 306.9 212.06 307.73 209.23Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M128.18 70.9399H239.9"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M184.76 286.8C194.723 286.8 202.8 283.527 202.8 279.49C202.8 275.453 194.723 272.18 184.76 272.18C174.797 272.18 166.72 275.453 166.72 279.49C166.72 283.527 174.797 286.8 184.76 286.8Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2694_2301">
        <rect width="374.69" height="330.58" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default TeaKettles
