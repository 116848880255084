import React from "react"

const SuperAutomatic = ({ classes = "w-[2.9375rem] h-[3.5rem]" }) => (
  <svg className={classes} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g stroke="currentColor" strokeMiterlimit="10">
      <path d="M45.878 1.296c-.89-.446-18.15-.65-21.45-.651h-.018c-3.301 0-20.56.205-21.45.651-1.247 13.976.856 38.604 1.714 45.303 1.009 7.884 19.366 2.984 19.744 2.883.379.102 18.736 5.002 19.745-2.883.858-6.7 2.96-31.327 1.715-45.303Z" />
      <path d="M34.947 48.077a.411.411 0 0 1-.407.396H14.415a.411.411 0 0 1-.407-.396l-.37-15.553a.387.387 0 0 1 .388-.396h20.902a.388.388 0 0 1 .388.396l-.37 15.553ZM25.499 16.008h-2.16v16.118h2.16V16.008ZM6.36 25.52s-.022-12.551.046-13.557c.069-1.006.091-5.784.091-5.784s.55-.366 1.693-.366 1.691.503 1.691.503S9.538 23.76 9.469 25.5c-.48.089-3.11.02-3.11.02Z" />
      <path d="M5.675 12.854s4.001 0 4.137.206c.136.206.091 1.487.091 1.487v4.088a2.037 2.037 0 0 0-.869-.297c-.318-.04-4.063-.022-4.063-.022l.704-5.462ZM19.42 20.547s3.86-.12 4.99-.12c1.13 0 4.835.12 4.835.12v7.254h-9.738l-.087-7.253Z" />
      <path d="M29.645 22.478s-.28-.183-1.36-.183-1.36.17-1.36.17l.183 9.775h.189v1.509l.806.543.89-.549v-1.502h.264l.388-9.763ZM21.824 22.478s-.28-.183-1.36-.183-1.361.17-1.361.17l.184 9.775h.189v1.509l.805.543.892-.549v-1.502h.263l.388-9.763ZM4.14 12.167l.091-3.43s1.121-.319 1.646.14c0 .8.023 4.162.023 4.162s-1.829-.506-1.76-.872ZM24.422.21c-5.853 0-7.59.184-7.728.229-.137.044-.114.412-.114.412s-.344 14.45-.28 14.791c.062.342.183.343.411.343h15.324c.435 0 .457-.09.457-.319 0-.228-.14-14.563-.14-14.907 0-.343-.091-.365-.228-.388-.138-.022-1.85-.16-7.703-.16Z" />
      <path d="M30.223 2.063H18.837v8.71h11.386v-8.71ZM24.57 15.265a1.92 1.92 0 1 0 0-3.842 1.92 1.92 0 0 0 0 3.842ZM3.936 18.523a3.2 3.2 0 1 0 0-6.4 3.2 3.2 0 0 0 0 6.4Z" />
      <path d="M3.936 16.397a1.075 1.075 0 1 0 0-2.149 1.075 1.075 0 0 0 0 2.15ZM45.583 51.652H3.259s2.859 2.629 4.484 3.75c1.436.269 16.113.384 16.673.388h.018c.56 0 15.236-.12 16.674-.388 1.617-1.121 4.475-3.75 4.475-3.75Z" />
      <path d="M44.145 46.713s-9.37.365-19.718.344h-.018c-10.35.02-19.72-.344-19.72-.344-.685.737-1.961 3.533-1.961 3.533s.017.72.102 1.577c4.358.526 21.202.498 21.578.498.375 0 17.22.028 21.577-.498.085-.857.102-1.577.102-1.577s-1.257-2.796-1.942-3.533Z" />
      <path d="M24.438 49.48c.365 0 15.523-.095 18.504-.32a6.778 6.778 0 0 0-.617-1.261s-16.045.206-17.893.206c-1.848 0-17.89-.206-17.89-.206-.25.398-.457.82-.619 1.26 2.982.226 18.14.319 18.505.32a.14.14 0 0 0 0-.022l.01.022ZM26.662 25.028h3.315l-.24 4.927H26.8l-.137-4.927ZM18.842 25.028h3.316l-.24 4.927h-2.939l-.137-4.927ZM2.736 50.246s9.387.673 21.639.673c12.251 0 21.725-.673 21.725-.673" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.526)" d="M0 0h45.948v56H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SuperAutomatic
