import React from "react"

const Lever = ({ classes = "w-8 h-[3.5rem]" }) => (
  <svg className={classes} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M27.24 17.846a.071.071 0 0 1-.009.032c-.065.134-.533.242-1.114.242-.619 0-1.121-.123-1.121-.267 0-.144.502-.276 1.12-.276.62 0 1.123.117 1.123.27Z"
        fill="none"
      />
      <path
        d="M27.231 17.878v2.71h-2.235v-2.742c0 .151.502.267 1.12.267.576.007 1.05-.099 1.115-.235Z"
        fill="none"
      />
      <path
        d="M28.15 20.587v1.953h-6.148v-1.953h6.148ZM29.828 22.107v.656H28.17v-2.4h1.657v.848h-.096v.893c.032.003.064.002.096.003ZM30.575 32.834l.164.56-.037.749s-.096.258-.556.252c-.46-.007-.548-.267-.548-.267s-.023-.43-.008-.681a3.42 3.42 0 0 1 .173-.659l.812.046Z"
        fill="none"
      />
      <path
        d="m30.575 32.834-.812-.047h-.034c.234-4.037.534-8.911.638-9.222.063-.163.14-.321.226-.473.11-.21.358-.673.39-.884a4.72 4.72 0 0 0-1.155-.1v-.894c.725-.016 1.613.06 1.92.497.333.48-.039 1.18-.367 1.8-.067.126-.15.282-.168.335-.072.373-.4 5.718-.593 8.991l-.045-.004ZM8.672 22.75H7.335v12.282h1.337V22.75ZM19.451.744c.688.456.906 1.397.534 2.722s-1.48 1.944-1.579 2.092c-.098.148-.133.366-.317.545-.184.178-.604.326-.753.474-.15.148-.07.675-.07.675s.72.901.72 1.476v1.936l-.01.054a8.027 8.027 0 0 0-2.618-.01V8.777c0-.505.545-1.268.9-1.496a2.429 2.429 0 0 0-.286-1.05 21.739 21.739 0 0 0-1.377-2.07c-.525-.654-.933-1.237-1.049-2.133-.116-.896-.02-1.333.307-1.576.03.367.644 1.357 1.03 1.773a5.568 5.568 0 0 1 1.05 1.793c.247.693.544 1.05.8 1.12.254.07.563-.02.592-.208.03-.188-.356-.565.051-1.12.407-.555 1.871-2.373 2.075-3.066ZM29.43 44.824V51.8c0 2.209-6.337 3.999-14.152 3.999-7.815 0-14.154-1.79-14.154-4v-6.975c.033 2.202 6.357 3.984 14.154 3.984s14.12-1.782 14.153-3.984ZM12.146 22.578v7.242c0 .266-.457.685-.82.974v-8.603c.117.145.407.279.82.387ZM13.123 20.147c-.933.104-1.6.287-1.797.501v-3.881a4.47 4.47 0 0 0 1.361.533c.122.862.317 2.12.436 2.847ZM11.323 20.334v.314a.192.192 0 0 0-.06.134v1.106H9.346v-1.554h1.976Z"
        fill="none"
      />
      <path
        d="M9.346 21.887v.864H6.663v-3.477h2.683v2.613ZM9.346 37.594v.913H6.663v-3.475h2.683V37.594ZM11.323 21.887v-.974c.315.34 1.835.6 3.663.6 2.056 0 3.723-.329 3.723-.733v1.263c0 .205-.338.4-.898.533-.927.207-1.875.303-2.825.288-1.14 0-2.16-.113-2.843-.292-.413-.108-.703-.241-.82-.386v-.3ZM29.43 44.807v.017c-.033 2.202-6.357 3.984-14.152 3.984-7.795 0-14.12-1.782-14.154-3.984v-.017c0-1.823 4.308-3.36 10.202-3.843v.462c-.242.175-.378.365-.386.561v.852c0 .885 2.554 1.6 5.704 1.6s5.706-.719 5.706-1.6v-.849c-.008-.196-.142-.383-.378-.556h-.009v-.15h.01c4.44.673 7.458 1.998 7.458 3.523Z"
        fill="none"
      />
      <path
        d="M21.972 41.431c.236.174.37.36.377.556v.852c0 .885-2.554 1.6-5.705 1.6-3.151 0-5.704-.719-5.704-1.6v-.85c.008-.195.144-.386.385-.56v.282c0 .827 2.382 1.437 5.319 1.437s5.319-.61 5.319-1.437v-.277l.009-.003ZM18.712 20.78c0 .4-1.667.732-3.723.732-1.829 0-3.348-.259-3.663-.6v-.266c.197-.215.87-.4 1.797-.502.039.234.068.412.085.504a7.627 7.627 0 0 0 3.226.01c0-.064 0-.283.007-.556 1.334.114 2.271.374 2.271.678ZM11.328 36.042H9.347v1.552h1.98v-1.552ZM8.005 14.482A2.317 2.317 0 1 1 8 19.117a2.317 2.317 0 0 1 .004-4.635Zm1.185 2.314a1.19 1.19 0 1 0-2.38-.002 1.19 1.19 0 0 0 2.38.002ZM11.32 30.793c.362-.29.819-.707.819-.975v-7.24c.684.178 1.704.291 2.843.291.95.016 1.898-.081 2.825-.288v7.239c0 .445 1.276 1.285 1.276 1.285v.016c0 .445-1.837.806-4.1.806-2.264 0-4.101-.361-4.101-.806.004 0 .203-.136.437-.328Z"
        fill="none"
      />
      <path
        d="M11.681 33.493a3.318 3.318 0 0 1-.353-.133c-.28-.124-.438-.267-.438-.41v-1.83c0 .446 1.835.807 4.1.807 2.265 0 4.1-.361 4.1-.806v1.835c0 .243-.431.464-1.131.628-.977.201-1.972.296-2.969.283-1.358 0-2.562-.142-3.309-.374ZM21.964 16.768c-.828.557-2.898.953-5.319.953h-.437c-.04-.146-.078-.288-.11-.4-.12-.454-.524-2.386-.524-2.386h-.887l-.926-3.778a6.859 6.859 0 0 1 1.6-.456 8.027 8.027 0 0 1 2.617.01c2.236.377 3.875 1.678 3.875 3.227v1.681c.314.185.488.39.488.607.01.19-.128.374-.377.542Z"
        fill="none"
      />
      <path
        d="M19.156 39.42c.675.39 1.6.058 2.053-.739.453-.797.285-1.756-.388-2.146h-.007c-.133-.096-2.364-1.613-2.556-1.757a2.37 2.37 0 0 0-.296-.164V33.58c.7-.164 1.131-.385 1.131-.628v-1.847s-1.275-.84-1.275-1.285V22.58c.56-.144.898-.333.898-.533v-1.262c0-.304-.933-.564-2.266-.676l.024-1.484s-.133-.473-.257-.904h.437c2.42 0 4.49-.4 5.319-.953V41.71c0 .826-2.381 1.437-5.319 1.437s-5.33-.612-5.33-1.438v-8.341c.114.051.232.096.353.133v1.4c0 .356.445.832.791.99.347.159.992.278 1.6.278h1.509c.235.288.541.666.64.822.168.257 2.506 2.16 2.852 2.406l.011-.017a.9.9 0 0 0 .076.04ZM11.322 16.767c-.249-.168-.385-.351-.385-.542 0-.217.177-.424.496-.61v-1.677c0-.732.368-1.408.99-1.958l.733 2.95h-.234s-.337 1.109-.337 1.485c0 .1.042.44.105.889a4.471 4.471 0 0 1-1.368-.537Z"
        fill="none"
      />
      <path
        d="M16.7 34.041c-.133-.049-.94.307-1.149.624-.208.317-.266 1.139-.266 1.139l.291.357h-1.51c-.604 0-1.248-.119-1.6-.278-.351-.158-.791-.634-.791-.99v-1.4c.746.227 1.95.374 3.308.374.997.013 1.993-.082 2.969-.283v1.027a21.03 21.03 0 0 0-1.252-.57ZM20.82 36.535c.673.39.848 1.35.388 2.147-.46.795-1.378 1.127-2.053.738a.845.845 0 0 1-.074-.048l1.74-2.837ZM20.811 36.531h.007l-1.74 2.841c-.612-.415-.757-1.333-.314-2.1.457-.793 1.373-1.125 2.047-.74Z"
        fill="none"
      />
      <path
        d="M18.765 37.273c-.442.766-.297 1.685.315 2.1l-.01.017c-.347-.247-2.686-2.15-2.854-2.407-.098-.152-.4-.533-.64-.822h.011s2.06-.297 2.368-.812v-.738c.102.047.201.102.296.164.192.144 2.418 1.66 2.556 1.757-.669-.384-1.584-.052-2.042.74Z"
        fill="none"
      />
      <path
        d="M17.96 34.61v.739c-.307.51-2.372.812-2.372.812h-.016l-.292-.358s.06-.82.266-1.138c.207-.317 1.02-.673 1.15-.624.103.038.831.35 1.264.57ZM16.464 18.621l-.024 1.484a18.111 18.111 0 0 0-3.318.042 155.85 155.85 0 0 1-.435-2.846c1.154.268 2.335.407 3.52.416.12.43.257.904.257.904Z"
        fill="none"
      />
      <path
        d="M16.442 20.105c0 .266-.007.491-.007.555a7.627 7.627 0 0 1-3.226-.009 26.344 26.344 0 0 1-.085-.504c.62-.069 1.243-.102 1.866-.1.515 0 1.005.021 1.452.058ZM16.099 17.313c.03.116.069.258.109.4a16.112 16.112 0 0 1-3.52-.416c-.063-.449-.106-.789-.106-.889 0-.372.34-1.478.34-1.478h.237l.201.8s.435.38.874.228c.438-.154.55-.65.55-.65l-.095-.383h.887s.403 1.934.523 2.388Z"
        fill="none"
      />
      <path
        d="m14.686 14.93.095.383s-.11.495-.55.65c-.44.155-.874-.233-.874-.233l-.201-.8h1.53Z"
        fill="none"
      />
      <path
        d="m13.76 11.158.926 3.772h-1.53l-.735-2.95a4.91 4.91 0 0 1 1.339-.822Z"
        fill="none"
      />
      <path
        d="m13.189 8.853.572 2.304a4.912 4.912 0 0 0-1.333.823l-.714-2.836c.26.08.536.087.8.024.246-.051.477-.16.675-.315ZM12.254.986a.669.669 0 0 0-.082-.22.087.087 0 0 1 .035.028c.112.19 1.057 4.518 1.138 5.02.082.5.208 2.532.208 2.532a1.055 1.055 0 0 1-.366.507l-.043-.175-1.466.321.036.145a1.232 1.232 0 0 1-.756-.602 50 50 0 0 1-.995-2.533c-.104-.328-1.075-4.265-1.066-4.456.097.57.933.905 1.852.748.918-.158 1.6-.744 1.505-1.315Z"
        fill="none"
      />
      <path
        d="M12.255.986c.097.571-.575 1.162-1.5 1.32-.925.159-1.757-.173-1.854-.748.015-.194 1.118-.75 1.414-.8.296-.049 1.6-.143 1.856 0 .042.07.07.148.084.228ZM11.678 9l1.466-.322.044.175a1.623 1.623 0 0 1-.667.316c-.264.062-.54.054-.8-.024l-.043-.146Z"
        fill="none"
      />
      <path
        d="M12.173.765c-.252-.145-1.579-.041-1.856 0-.277.042-1.4.6-1.414.8C8.806.988 9.477.4 10.403.239c.798-.136 1.523.094 1.77.526Z"
        fill="none"
      />
      <path
        d="M21.972 41.28c4.44.677 7.459 2 7.459 3.527v.017c-.034 2.202-6.358 3.984-14.153 3.984-7.795 0-14.12-1.782-14.154-3.984v-.017c0-1.823 4.308-3.36 10.202-3.843"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M29.43 44.824V51.8c0 2.209-6.337 3.999-14.152 3.999-7.815 0-14.154-1.79-14.154-4v-6.975M21.965 41.709c0 .826-2.381 1.437-5.319 1.437s-5.323-.61-5.323-1.437M13.76 11.157a6.859 6.859 0 0 1 1.6-.456 8.027 8.027 0 0 1 2.618.01c2.236.377 3.875 1.678 3.875 3.227M11.429 13.938c0-.732.368-1.408.99-1.958"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M22.35 42.84c0 .884-2.555 1.599-5.706 1.599-3.151 0-5.704-.719-5.704-1.6M10.94 41.99c.008-.196.144-.387.385-.561M21.972 41.431c.236.174.369.36.377.556M11.326 33.368v8.341M11.326 16.717v14.087M21.963 16.717V41.71M11.335 20.334H9.262M11.335 21.887H9.262M11.335 36.042H9.262M11.335 37.594H9.262M7.336 22.75h-.673v-3.476h2.683v3.477h-.673"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M7.335 35.032V22.75h1.337v12.28M9.346 36.042v-1.01H6.663v3.475h2.683v-2.465ZM9.189 16.796a1.189 1.189 0 1 1-2.378 0 1.189 1.189 0 0 1 2.378 0Z"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M8.003 19.116a2.317 2.317 0 1 0 0-4.634 2.317 2.317 0 0 0 0 4.634ZM29.828 22.107v.656H28.17v-2.399h1.657V22.107ZM27.23 20.587h.92v1.953h-6.148v-1.953h5.228ZM24.996 17.846c0-.151.502-.275 1.12-.275.62 0 1.123.123 1.123.275a.071.071 0 0 1-.008.032c-.065.134-.533.242-1.114.242-.619 0-1.121-.124-1.121-.274ZM24.996 17.846v2.741M27.23 17.846v2.742"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M29.828 21.212c.725-.016 1.613.062 1.92.498.333.48-.039 1.18-.367 1.8-.067.126-.15.282-.168.335-.072.373-.4 5.717-.593 8.991h-.045l-.812-.046h-.034c.234-4.037.534-8.912.638-9.222.063-.163.14-.322.226-.473.11-.21.358-.674.39-.884a4.716 4.716 0 0 0-1.155-.1h-.096v-.893l.096-.006Z"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M29.85 32.542s-.039.104-.087.245c-.08.213-.137.434-.173.659-.015.252.008.68.008.68s.088.26.548.267c.46.007.556-.252.556-.252l.037-.749-.164-.56-.08-.266M15.358 10.688V8.777c0-.505.545-1.268.9-1.496a2.429 2.429 0 0 0-.286-1.05 21.739 21.739 0 0 0-1.377-2.07c-.525-.654-.933-1.237-1.049-2.133-.116-.896-.02-1.333.307-1.576.03.367.644 1.357 1.03 1.773a5.568 5.568 0 0 1 1.05 1.793c.247.693.544 1.05.8 1.12.254.07.563-.02.592-.208.03-.188-.356-.565.051-1.12.407-.555 1.88-2.367 2.089-3.066.677.456.894 1.397.522 2.722-.371 1.325-1.48 1.944-1.578 2.092-.099.148-.133.366-.317.545-.184.178-.604.326-.753.474-.15.148-.07.675-.07.675s.719.901.719 1.476v1.936M22.35 42.84v-.853M10.94 42.84v-.853M12.686 17.301a4.47 4.47 0 0 1-1.36-.533c-.25-.168-.386-.35-.386-.541 0-.217.177-.424.496-.61V13.94M21.86 13.938v1.68c.314.186.489.391.489.607 0 .191-.134.375-.387.543-.828.557-2.898.953-5.319.953h-.437M17.959 34.61v-1.018M11.683 33.487v1.406c0 .356.445.832.791.99.347.16.992.278 1.6.278h1.509"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M20.81 36.531c-.132-.096-2.364-1.613-2.556-1.757a2.358 2.358 0 0 0-.296-.164 23.858 23.858 0 0 0-1.258-.569c-.133-.05-.941.307-1.15.624-.207.317-.266 1.139-.266 1.139l.292.357c.235.288.541.666.64.822.168.258 2.506 2.16 2.853 2.406M16.44 20.105c1.332.111 2.266.371 2.266.675 0 .4-1.668.732-3.723.732-1.83 0-3.349-.258-3.664-.6a.173.173 0 0 1 0-.266c.198-.215.87-.4 1.797-.502M18.712 22.043c0 .205-.339.4-.899.533-.927.206-1.875.303-2.824.288-1.14 0-2.16-.114-2.844-.292-.413-.108-.702-.241-.82-.387a.225.225 0 0 1-.06-.148M19.09 32.956c0 .243-.431.464-1.131.628-.977.202-1.972.296-2.969.283-1.358 0-2.562-.147-3.309-.373a3.318 3.318 0 0 1-.353-.134c-.28-.124-.438-.266-.438-.41M10.89 31.105v1.886"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M20.818 36.535c.673.39.848 1.35.388 2.147-.46.795-1.379 1.127-2.053.738a.875.875 0 0 1-.075-.048c-.612-.414-.757-1.333-.314-2.1.457-.8 1.373-1.124 2.047-.74l.007.004ZM12.147 22.528v7.292c0 .266-.457.685-.82.974-.239.19-.437.327-.437.327 0 .445 1.835.806 4.1.806 2.265 0 4.1-.361 4.1-.806"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M17.815 22.528v7.292c0 .445 1.276 1.285 1.276 1.285v1.886M14.687 14.93h.886s.4 1.931.526 2.386a103.279 103.279 0 0 0 .367 1.304s-.016.875-.025 1.483c0 .267-.007.492-.007.556a7.627 7.627 0 0 1-3.226-.01 155.716 155.716 0 0 1-.521-3.35c-.062-.448-.105-.788-.105-.888 0-.375.34-1.481.34-1.481h.237M11.264 20.78V22.058M18.714 20.78V22.058"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="m11.714 9.144.708 2.836.735 2.95.201.8s.434.38.873.227.55-.65.55-.65l-.094-.383-.926-3.767-.572-2.304"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M12.171.765a.087.087 0 0 1 .035.03c.112.189 1.057 4.517 1.138 5.018.082.501.208 2.533.208 2.533a1.054 1.054 0 0 1-.364.507 1.624 1.624 0 0 1-.666.315c-.264.063-.54.055-.8-.024a1.232 1.232 0 0 1-.756-.602 50 50 0 0 1-.994-2.533c-.104-.328-1.076-4.265-1.067-4.456"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M12.173.765c.04.068.07.143.084.221.097.571-.575 1.162-1.5 1.32-.925.159-1.757-.173-1.854-.748-.097-.574.574-1.158 1.5-1.32.798-.135 1.523.095 1.77.527Z"
        stroke="currentColor"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.924)" d="M0 0h31.153v56H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default Lever
