import React from "react"

const BeanStorage = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    width="367"
    height="261"
    viewBox="0 0 367 261"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2690_1893)">
      <path
        d="M323.31 206.25L344.93 254.8C344.93 254.8 342.93 255.36 339.76 256.07C316.53 252.88 283.52 213.07 260.76 158.53C238.95 106.29 233.39 56.94 245.28 36.34L246.86 34.57L268.86 84.02C267 98.78 271.61 123 282.25 148.42C294.32 177.33 310.61 199.6 323.31 206.25Z"
        fill="white"
      />
      <path
        d="M268.87 84L323.31 206.24C310.61 199.59 294.31 177.32 282.25 148.41C271.61 123 267 98.78 268.87 84Z"
        fill="white"
      />
      <path
        d="M320.19 132.58C326.19 146.94 325.91 160.72 319.59 163.36C313.27 166 303.28 156.5 297.28 142.14C291.28 127.78 291.56 114 297.89 111.36C304.22 108.72 314.19 118.22 320.19 132.58Z"
        fill="white"
      />
      <path
        d="M339.76 256.07C332.12 257.8 317.99 260.45 311.4 258.07C282.75 249.57 251.73 205.97 236.93 171.72C228.78 152.86 225.19 133.83 223.64 118.78C225.8 94.26 227.99 69.78 229.25 55.71C233.71 49.5 241.84 40.18 245.25 36.31C233.36 56.91 238.92 106.31 260.73 158.5C283.52 213.11 316.53 252.88 339.76 256.07Z"
        fill="white"
      />
      <path
        d="M323.31 206.25L268.87 84C269.81 76.73 272.35 71.74 276.54 70C289.26 64.69 312.42 91.1999 328.28 129.2C344.14 167.2 346.7 202.32 333.98 207.62C330.94 208.89 327.3 208.34 323.31 206.25ZM297.31 142.14C303.31 156.5 313.31 165.99 319.62 163.36C325.93 160.73 326.21 146.94 320.22 132.58C314.23 118.22 304.22 108.72 297.92 111.36C291.62 114 291.29 127.78 297.28 142.14H297.31Z"
        fill="white"
      />
      <path
        d="M245.27 36.33C247.322 32.457 250.609 29.3807 254.61 27.59C277.43 18.06 317.18 61.24 343.39 124.04C369.6 186.84 372.34 245.45 349.51 255C346.421 256.248 343.05 256.628 339.76 256.1C342.89 255.39 344.93 254.83 344.93 254.83L323.31 206.28C327.31 208.37 330.94 208.92 333.98 207.65C346.7 202.35 344.14 167.24 328.28 129.23C312.42 91.22 289.28 64.72 276.54 70.03C272.35 71.78 269.81 76.77 268.87 84.03L246.87 34.58L245.27 36.33Z"
        fill="white"
      />
      <path
        d="M2.26999 45L230.32 43.82C230.32 43.82 230.32 44.31 230.19 45.22C223.35 64.47 175 79.42 116.27 79.42C57.39 79.42 8.86999 64.42 2.26999 44.98V45Z"
        fill="white"
      />
      <path
        d="M230.19 45.17C230.03 47.04 229.7 50.73 229.25 55.74C228.307 57.0119 227.455 58.3486 226.7 59.74C223.55 66.03 222.7 81.62 222.53 92.17C222.363 101.061 222.734 109.954 223.64 118.8C220.58 153.39 217.56 188.03 217.26 193.39C216.63 204.57 195.53 244.24 115.09 244.24C34.65 244.24 12.09 207.72 11.34 191.5C10.57 175.44 2.41999 47.24 2.26999 45C8.86999 64.39 57.39 79.44 116.27 79.44C175 79.42 223.35 64.47 230.19 45.17Z"
        fill="white"
      />
      <path
        d="M226.7 59.75C227.455 58.3586 228.307 57.0219 229.25 55.75C227.99 69.75 225.8 94.3 223.64 118.82C222.734 109.974 222.363 101.081 222.53 92.19C222.69 81.63 223.55 66 226.7 59.75Z"
        fill="white"
      />
      <path
        d="M2.27 45C1.76326 43.5467 1.50301 42.019 1.5 40.48C1.5 18.96 52.88 1.47998 116.27 1.47998C179.66 1.47998 231 18.94 231 40.46C230.999 42.0671 230.714 43.6615 230.16 45.17C230.25 44.26 230.29 43.77 230.29 43.77L2.27 45Z"
        fill="white"
      />
      <path
        d="M229.25 55.74C229.7 50.74 230.03 47.04 230.19 45.17"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M2.26999 45C2.41999 47.26 10.57 175.46 11.34 191.53C12.13 207.75 34.64 244.27 115.09 244.27C195.54 244.27 216.63 204.6 217.26 193.42C217.56 188.06 220.58 153.42 223.64 118.83"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M2.27 45C1.76326 43.5467 1.50301 42.019 1.5 40.48C1.5 18.96 52.88 1.47998 116.27 1.47998C179.66 1.47998 231 18.94 231 40.46C230.999 42.0671 230.714 43.6615 230.16 45.17C223.35 64.47 175 79.42 116.27 79.42C57.39 79.42 8.87 64.37 2.27 45Z"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M245.26 36.34C241.84 40.21 233.71 49.53 229.26 55.74C228.317 57.0119 227.465 58.3485 226.71 59.74C223.56 66.03 222.71 81.62 222.54 92.17C222.373 101.061 222.744 109.954 223.65 118.8C225.2 133.85 228.79 152.88 236.94 171.74C251.74 205.99 282.76 249.59 311.41 258.09C318 260.44 332.13 257.79 339.77 256.09"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M245.27 36.33C247.322 32.457 250.609 29.3807 254.61 27.59C277.43 18.06 317.18 61.24 343.39 124.04C369.6 186.84 372.34 245.45 349.51 255C346.421 256.248 343.05 256.628 339.76 256.1C316.53 252.91 283.52 213.1 260.76 158.56C238.95 106.32 233.39 56.97 245.28 36.37L245.27 36.33Z"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M268.87 84C269.81 76.73 272.35 71.74 276.54 70C289.26 64.69 312.42 91.1999 328.28 129.2C344.14 167.2 346.7 202.32 333.98 207.62C330.98 208.89 327.3 208.34 323.31 206.25C310.61 199.6 294.31 177.33 282.25 148.42C271.61 123 267 98.78 268.87 84Z"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M320.19 132.58C326.19 146.94 325.91 160.72 319.59 163.36C313.27 166 303.28 156.5 297.28 142.14C291.28 127.78 291.56 114 297.89 111.36C304.22 108.72 314.19 118.22 320.19 132.58Z"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2690_1893">
        <rect width="366.49" height="260.53" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default BeanStorage
