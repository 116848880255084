import React from "react"

const EspressoGrinders = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    viewBox="0 0 126 401"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2690_1645)">
      <path
        d="M63.19 1.50999C75.4 1.61999 118.88 5.50999 120.6 7.87999C120.6 12 120.73 15.25 120.73 15.25H118.5C118.5 14.99 118.5 14.86 118.5 14.86H7.33C7.33 14.86 7.33 14.99 7.33 15.25H5.12C5.12 15.25 5.25 12 5.25 7.87999C7 5.47999 50.46 1.61999 62.67 1.50999H63.19V1.50999Z"
        fill="white"
        stroke="#858180"
        strokeMiterlimit="10"
      />
      <path
        d="M63.19 15.25H118.5C118.18 20.08 115.07 68.25 114.44 74.77C113.77 81.69 110.6 84.77 108.1 87.19C105.6 89.61 78.69 116.36 78.69 116.36L63 116.44H62.85L47.17 116.36C47.17 116.36 20.25 89.61 17.75 87.19C15.25 84.77 12.08 81.69 11.42 74.77C10.78 68.21 7.67 20.08 7.35 15.25H63.19V15.25Z"
        fill="white"
        stroke="#858180"
        strokeMiterlimit="10"
      />
      <path
        d="M83 118.27V139.69C83 140.77 80.58 141.86 79.33 141.86H46.5C45.25 141.86 42.83 140.77 42.83 139.69V118.27C42.83 117.02 44.58 116.44 46.67 116.44H79.19C81.27 116.44 83 117 83 118.27Z"
        fill="white"
        stroke="#858180"
        strokeMiterlimit="10"
      />
      <path
        d="M23.33 393.92V398.11C21.7188 398.784 19.9959 399.15 18.25 399.19C16.3715 399.119 14.5157 398.755 12.75 398.11V392C13.5128 392.395 14.3052 392.729 15.12 393C17.8283 393.522 20.5734 393.829 23.33 393.92V393.92Z"
        fill="white"
        stroke="#858180"
        strokeMiterlimit="10"
      />
      <path
        d="M113.1 392V398.14C111.334 398.785 109.478 399.149 107.6 399.22C105.854 399.178 104.132 398.811 102.52 398.14V393.95C105.279 393.833 108.024 393.499 110.73 392.95C111.543 392.695 112.336 392.377 113.1 392V392Z"
        fill="white"
        stroke="#858180"
        strokeMiterlimit="10"
      />
      <path
        d="M113.1 390.19V392C112.337 392.395 111.545 392.729 110.73 393C108.024 393.548 105.279 393.883 102.52 394V392.19L113.1 390.19Z"
        fill="white"
        stroke="#858180"
        strokeMiterlimit="10"
      />
      <path
        d="M97.41 163C97.33 166.53 95.96 219.55 94.92 221.57C93.88 223.59 69.08 267.07 69.08 267.07C66.7905 267.66 64.443 267.995 62.08 268.07C59.5886 267.946 57.1242 267.495 54.75 266.73C54.75 266.73 29.08 222.73 29.08 220.15C29.08 217.57 28.83 162.82 28.83 162.82C28.83 166.59 44.19 169.65 63.12 169.65C81.76 169.69 96.94 166.72 97.41 163Z"
        fill="white"
        stroke="#858180"
        strokeMiterlimit="10"
      />
      <path
        d="M89.21 283.9C90.4286 283.908 91.6175 284.276 92.6269 284.959C93.6363 285.642 94.4209 286.608 94.8817 287.736C95.3426 288.864 95.4591 290.104 95.2165 291.298C94.974 292.492 94.3832 293.588 93.5187 294.447C92.6543 295.306 91.5548 295.889 90.3591 296.124C89.1633 296.359 87.9248 296.235 86.7997 295.766C85.6746 295.298 84.7133 294.507 84.0371 293.494C83.3609 292.48 83 291.289 83 290.07C82.9987 289.256 83.1586 288.45 83.4707 287.698C83.7828 286.946 84.2409 286.264 84.8183 285.69C85.3958 285.116 86.0812 284.663 86.835 284.355C87.5888 284.048 88.396 283.893 89.21 283.9Z"
        fill="white"
        stroke="#858180"
        strokeMiterlimit="10"
      />
      <path
        d="M80 310.57H44.92C44.92 310.57 45.21 306.75 45.62 304.13C46.03 301.51 53.62 300.65 62.42 300.65C75.54 300.65 78.83 303.8 79.42 304.74C80.01 305.68 80 310.57 80 310.57Z"
        fill="white"
        stroke="#858180"
        strokeMiterlimit="10"
      />
      <path
        d="M63.12 395.43H62.73C59.45 395.36 36.3 394.84 23.33 393.92V392.11L12.75 390.19V392C7.68 389.46 1.5 384 1.5 380.19C1.5 375.81 2.25 324.57 5.25 296.44C8.25 268.31 21.5 162.44 22.62 155.31C23.74 148.18 43.87 141.94 47.37 141.94H78.48C81.98 141.94 102.1 148.19 103.23 155.31C104.36 162.43 117.6 268.31 120.6 296.44C123.6 324.57 124.35 375.81 124.35 380.19C124.35 383.95 118.17 389.46 113.1 391.97V390.19L102.52 392.11V393.92C89.55 394.84 66.4 395.36 63.12 395.43ZM97.41 163C97.415 162.94 97.415 162.88 97.41 162.82C97.41 159.04 82.05 155.98 63.11 155.98C44.17 155.98 28.82 159.04 28.82 162.82C28.82 162.82 29.07 217.57 29.07 220.15C29.07 222.73 54.74 266.73 54.74 266.73C57.1142 267.495 59.5786 267.946 62.07 268.07C64.433 267.995 66.7805 267.66 69.07 267.07C69.07 267.07 93.82 223.65 94.91 221.57C96 219.49 97.33 166.57 97.41 163ZM95.41 290C95.412 288.779 95.0515 287.585 94.3743 286.568C93.6971 285.552 92.7336 284.76 91.6058 284.292C90.478 283.823 89.2365 283.7 88.0387 283.938C86.841 284.176 85.7407 284.764 84.8772 285.627C84.0137 286.491 83.4258 287.591 83.1881 288.789C82.9503 289.987 83.0734 291.228 83.5416 292.356C84.0098 293.484 84.8022 294.447 85.8184 295.124C86.8346 295.802 88.0288 296.162 89.25 296.16C90.8647 296.15 92.4108 295.506 93.5555 294.367C94.7001 293.228 95.3517 291.685 95.37 290.07L95.41 290ZM44.96 310.5H80C80 310.5 79.92 305.6 79.37 304.67C78.82 303.74 75.54 300.58 62.37 300.58C53.53 300.58 45.99 301.45 45.57 304.06C45.15 306.67 44.92 310.57 44.92 310.57L44.96 310.5Z"
        fill="white"
        stroke="#858180"
        strokeMiterlimit="10"
      />
      <path
        d="M28.83 162.86H97.42C97.425 162.92 97.425 162.98 97.42 163.04C96.95 166.72 81.77 169.69 63.13 169.69C44.19 169.69 28.83 166.63 28.83 162.86Z"
        fill="white"
        stroke="#858180"
        strokeMiterlimit="10"
      />
      <path
        d="M28.83 162.86C28.83 159.08 44.19 156.02 63.12 156.02C82.05 156.02 97.42 159.08 97.42 162.86H28.83Z"
        fill="white"
        stroke="#858180"
        strokeMiterlimit="10"
      />
      <path
        d="M23.33 392.11V393.92C20.5713 393.803 17.8261 393.469 15.12 392.92C14.3052 392.649 13.5128 392.315 12.75 391.92V390.14L23.33 392.11Z"
        fill="white"
        stroke="#858180"
        strokeMiterlimit="10"
      />
      <path
        d="M12.75 392V398.14C14.5157 398.785 16.3715 399.149 18.25 399.22C19.9959 399.18 21.7188 398.814 23.33 398.14V393.95"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.1 392V398.14C111.334 398.785 109.478 399.149 107.6 399.22C105.854 399.178 104.132 398.811 102.52 398.14V393.95"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.1 392C118.17 389.49 124.35 384 124.35 380.22C124.35 375.84 123.6 324.6 120.6 296.47C117.6 268.34 104.35 162.47 103.23 155.34C102.11 148.21 82 141.94 78.48 141.94H47.37C43.87 141.94 23.75 148.19 22.62 155.31C21.49 162.43 8.25 268.31 5.25 296.44C2.25 324.57 1.5 375.81 1.5 380.19C1.5 383.95 7.68 389.46 12.75 391.97C13.5128 392.365 14.3052 392.699 15.12 392.97C17.8261 393.519 20.5713 393.853 23.33 393.97C36.33 394.89 59.45 395.41 62.73 395.48H63.12C66.4 395.41 89.55 394.89 102.52 393.97C105.279 393.853 108.024 393.519 110.73 392.97C111.544 392.709 112.336 392.384 113.1 392V392Z"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.85 116.44H46.67C44.58 116.44 42.83 117.02 42.83 118.27V139.69C42.83 140.77 45.25 141.86 46.5 141.86H79.35C80.6 141.86 83.02 140.77 83.02 139.69V118.27C83.02 117.02 81.27 116.44 79.19 116.44H63"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.5 15.25C118.5 14.99 118.5 14.86 118.5 14.86H7.33C7.33 14.86 7.33 14.99 7.33 15.25C7.65 20.08 10.76 68.25 11.4 74.77C12.06 81.69 15.23 84.77 17.73 87.19C20.23 89.61 47.15 116.36 47.15 116.36L62.83 116.44H63L78.69 116.36C78.69 116.36 105.6 89.61 108.1 87.19C110.6 84.77 113.77 81.69 114.44 74.77C115.07 68.21 118.18 20.08 118.5 15.25Z"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.5 15.25H120.73C120.73 15.25 120.6 12 120.6 7.87999C118.88 5.47999 75.4 1.61999 63.19 1.50999H62.67C50.46 1.61999 7 5.47999 5.25 7.87999C5.25 12 5.12 15.25 5.12 15.25H118.5Z"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.83 162.86C28.83 162.86 29.08 217.61 29.08 220.19C29.08 222.77 54.75 266.77 54.75 266.77C57.1242 267.535 59.5886 267.986 62.08 268.11C64.443 268.035 66.7905 267.7 69.08 267.11C69.08 267.11 93.83 223.69 94.92 221.61C96.01 219.53 97.33 166.61 97.41 163.04"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.42 300.65C53.58 300.65 46.04 301.52 45.62 304.13C45.2 306.74 44.92 310.57 44.92 310.57H80C80 310.57 79.92 305.67 79.37 304.74C78.82 303.81 75.54 300.65 62.42 300.65Z"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.37 290.07C95.37 291.291 95.0079 292.484 94.3295 293.499C93.6511 294.514 92.6868 295.305 91.5588 295.771C90.4308 296.238 89.1897 296.36 87.9926 296.121C86.7955 295.882 85.6961 295.293 84.8336 294.429C83.9711 293.565 83.3843 292.465 83.1473 291.268C82.9104 290.07 83.034 288.829 83.5025 287.702C83.9711 286.575 84.7634 285.612 85.7794 284.935C86.7954 284.258 87.9893 283.898 89.21 283.9C90.0198 283.9 90.8216 284.06 91.5696 284.37C92.3177 284.68 92.9972 285.135 93.5693 285.708C94.1415 286.281 94.595 286.961 94.904 287.71C95.2129 288.458 95.3713 289.26 95.37 290.07V290.07Z"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.42 162.86C97.425 162.92 97.425 162.98 97.42 163.04C96.95 166.72 81.77 169.69 63.13 169.69C44.2 169.69 28.84 166.63 28.84 162.86C28.84 159.09 44.19 156 63.12 156C82.05 156 97.42 159.08 97.42 162.86Z"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.33 364C17.33 364 33.5 371.5 61.5 371.5C89.5 371.5 107.67 364 107.67 364"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2690_1645">
        <rect width="125.85" height="400.69" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default EspressoGrinders
