import React from "react"

const FrothingPitchers = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    viewBox="0 0 353 348"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2690_2029)">
      <path
        d="M254.63 78.17C254.63 78.17 257.38 71.67 266.51 70.11C275.64 68.55 307.18 66.59 320.26 67.01C333.34 67.43 359 79.33 348 128.33C337 177.33 329.09 207.84 324.38 218.41C321 226 311.71 232.63 299.5 237.5C287.29 242.37 272.8 252.5 270.81 259.5C268.82 266.5 269.81 277.94 260.37 276.19C250.93 274.44 258.62 255.94 259.31 250.82C260 245.7 262.64 230.7 272.83 225.7C283.02 220.7 303.93 212.01 309.93 208.89C315.93 205.77 318.24 202.04 321.08 194.29C323.92 186.54 342.08 122.08 343.08 110.02C344.08 97.96 333.83 90.02 320.39 89.9C306.95 89.78 274.52 91.84 268.45 92.71C262.38 93.58 263.7 96.21 263.7 99.77C263.7 103.33 264.89 120.27 264.89 120.27C264.89 120.27 256.64 122.02 255.7 119.71C254.76 117.4 254.63 78.17 254.63 78.17Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M1.75 55.29C1.75 55.29 9.67 56 9.67 65.17C9.67 74.34 12.5 165 12.83 176.33C13.16 187.66 15.5 193.16 18.66 196.83C14.49 212.66 15.33 222 15.66 234.83C15.99 247.66 16.99 267.41 18.74 280.41C20.49 293.41 29.24 346.41 136.99 346.41C244.74 346.41 259.17 287 261.83 277.5C264.49 268 259.5 176.17 259.5 176.17C259.5 176.17 253.75 54 253.5 51.33C253.25 48.66 229.67 44.83 227.67 45C225.67 45.17 14 49.67 14 49.67L1.75 55.29Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 55.83C1.5 55.83 6 44.16 17.17 40.83C28.34 37.5 47 31.5 55.5 27.5C64 23.5 82 1.5 148.17 1.5C214.34 1.5 253.5 30.67 253.5 51.33C253.5 71.99 222.83 100.66 152.5 100.66C82.17 100.66 54.5 77.33 49 71.66C43.5 65.99 9.33 52.33 1.5 55.83Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M21.63 189.29C28.88 146.79 46.76 78.91 46.76 78.91Z"
        fill="white"
      />
      <path
        d="M21.63 189.29C28.88 146.79 46.76 78.91 46.76 78.91"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2690_2029">
        <rect width="352.17" height="347.92" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default FrothingPitchers
