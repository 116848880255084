import React from "react"

const HeatExchanger = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    width="50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="currentColor">
      <path
        d="M47.566 45.24H5.923L3.198 14.805 5.923 4.56h41.643l2.725 10.244-2.725 30.437ZM8.18 5.484V44.07"
        strokeLinejoin="round"
      />
      <path
        d="M1.887 33.424s3.07-9.208 3.642-10.724c.573-1.517 1.966-1.886 2.725-1.775.65.095 1.305.138 1.961.129.351-.018.924-.167 1.165-1.202.242-1.035.944-5.196.944-5.196h1.421s-.795 4.955-1 5.787c-.203.833-.794 2.09-2.513 1.905-1.72-.184-1.609-.147-1.997-.203-.389-.057-.944-.26-1.646 1.738s-3.44 9.99-3.44 9.99-1.09.4-1.262-.449ZM45.309 5.484V44.07M45.31 2.859H8.18v2.625h37.13V2.859ZM14.614 14.928h-2.76l-.21-.691V11.77h3.18v2.466l-.21.69Z"
        strokeLinejoin="round"
      />
      <path
        d="m.709 35.698.315-.757.863-1.517s1.11.204 1.257.444c-.055.37-.222 1.701-.222 1.701l-.24.795s-.393.752-1.313.407c-.73-.278-.66-1.073-.66-1.073Z"
        strokeLinejoin="round"
      />
      <path
        d="M33.197 18.576c-.166-.13-1.35-.5-1.35-.5s-.037-2.22-.093-3.662c-.055-1.441-.74-2.884-1.201-4.27-.462-1.387-.944-1.702-.944-1.702V6.65c0-.148-1.054-.555-1.054-.555v-.868a4.054 4.054 0 0 0-3.697 0v.868s-1.055.407-1.055.555v1.795s-.48.315-.943 1.7c-.463 1.387-1.146 2.83-1.202 4.272-.055 1.441-.093 3.662-.093 3.662s-1.183.369-1.35.5c-.166.13-.315.24-.295 1.072.02.832.148 2.367.295 2.625s1.313.315 1.313.315.407 1.017.646 1.313c.24.295 2.164 2.292 2.201 2.7.038.408-.055 4.104-.037 4.326.018.222.35.536.37.85.02.316.018 3.459.038 3.68.02.223.555.556.555.556s.129 1.497.148 1.775c.02.277.296 1.497.389 1.534.289.02.58.02.868 0 .29.02.58.02.869 0 .093-.037.37-1.257.389-1.534.019-.278.15-1.776.15-1.776s.535-.334.554-.556c.02-.222.018-3.364.038-3.68.02-.314.35-.628.369-.85.018-.222-.074-3.92-.037-4.326.036-.407 1.96-2.404 2.2-2.7.24-.298.647-1.313.647-1.313s1.165-.056 1.312-.315c.147-.26.278-1.794.296-2.626.018-.83-.129-.942-.296-1.072Z"
        strokeMiterlimit="10"
      />
      <path
        d="M26.471 26.306c-3.052 0-3.859 3.132-3.698 3.92.161.788.604.777.702.603.099-.173 1.307-1.627 2.86-1.627s2.914.999 3.095 1.294c.18.296.406.296.53.086.123-.21.308-.416.277-.8-.03-.385-.4-3.476-3.766-3.476ZM33.484 19.732h3.68l-.038 2.218-3.79-.212.148-2.006ZM16.094 39.693a3.828 3.828 0 1 0 0-7.656 3.828 3.828 0 0 0 0 7.656ZM10.156 34.368a.906.906 0 1 0 0-1.812.906.906 0 0 0 0 1.812ZM26.706 15.655a.906.906 0 1 0 0-1.813.906.906 0 0 0 0 1.813ZM9.973 39.027a.906.906 0 1 0 0-1.812.906.906 0 0 0 0 1.812ZM37.024 39.693a3.828 3.828 0 1 0 0-7.656 3.828 3.828 0 0 0 0 7.656ZM9.3 51.54v4.092c.947.117 1.9.17 2.854.16.944.014 1.889-.052 2.822-.197v-4.056H9.301ZM38.148 51.54v4.092c.95.117 1.904.17 2.86.16.945.014 1.89-.052 2.823-.197v-4.056h-5.683Z"
        strokeMiterlimit="10"
      />
      <path
        d="M10.485.208h-.962v2.454h.962V.208ZM43.919.208h-.962v2.454h.962V.208ZM9.932 6.45c.092-.853.833-1.337 1.654-1.073a6.98 6.98 0 0 0 3.016.158c.84-.176 1.526.382 1.526 1.24a6.982 6.982 0 0 0 .781 2.914c.428.746.11 1.574-.706 1.842a6.983 6.983 0 0 0-2.532 1.651c-.576.637-1.463.591-1.97-.103a6.983 6.983 0 0 0-2.346-1.905c-.784-.35-1.013-1.208-.51-1.905a6.982 6.982 0 0 0 1.087-2.818Z"
        strokeLinejoin="round"
      />
      <path
        d="M13.987 11.015A2.142 2.142 0 1 0 11.67 7.41a2.142 2.142 0 0 0 2.317 3.604Z"
        strokeLinejoin="round"
      />
      <path
        d="M38.256 29.615c.282-.21.61-.349.957-.406.601-.111.934.046.934.046s.99 2.347 1.507 3.607c.518 1.26-.573 2.07-1.33 2.265-.758.194-2.23-.315-2.173-1.739.055-1.424.105-3.773.105-3.773Z"
        strokeMiterlimit="10"
      />
      <path
        d="m37.441 23.55 1.077 5.917c.27-.005.54-.032.805-.083.178-.045.349-.114.508-.204l-1.09-5.828s-.847-.052-1.3.198ZM21.566 18.076s2.479-.204 5.067-.204c2.587 0 5.213.204 5.213.204"
        strokeMiterlimit="10"
      />
      <path
        d="M21.531 22.589s3.451.445 5.25.445c1.8 0 5.103-.45 5.103-.45M5.012 43.995h43.49l-.418 7.569H5.43l-.42-7.57Z"
        strokeLinejoin="round"
      />
      <path
        d="M26.468 26.047s-3.915.247-4.912-.087c-.998-.333-.579-1.966-.303-2.705.276-.74.598-2.262 1.338-2.151.74.11 3.6 1.707 4.117 2.262.517.555 1.072 1.645-.24 2.68Z"
        strokeMiterlimit="10"
      />
      <path
        d="M22.296 26.12a2.514 2.514 0 1 0 0-5.029 2.514 2.514 0 0 0 0 5.029Z"
        strokeMiterlimit="10"
      />
      <path
        d="M41.76 14.928H39l-.21-.691V11.77h3.18v2.466l-.209.69Z"
        strokeLinejoin="round"
      />
      <path
        d="M39.663 5.412c.542-.666 1.429-.666 1.972 0a6.98 6.98 0 0 0 2.442 1.774c.8.31 1.075 1.153.609 1.875a6.982 6.982 0 0 0-.933 2.872c-.047.858-.764 1.38-1.595 1.159a6.98 6.98 0 0 0-3.02 0c-.832.22-1.548-.301-1.596-1.159a6.982 6.982 0 0 0-.932-2.87c-.466-.722-.191-1.566.61-1.875a6.98 6.98 0 0 0 2.443-1.776Z"
        strokeLinejoin="round"
      />
      <path
        d="M40.65 11.402a2.145 2.145 0 1 0 0-4.29 2.145 2.145 0 0 0 0 4.29Z"
        strokeLinejoin="round"
      />
      <path
        d="M37.117 23.901s1.896-.018 2.302-.25c.176-.73.213-3.587.232-4.215.037-.343-.038-.508-.139-.508h-2.349l-.046 4.973Z"
        strokeMiterlimit="10"
      />
      <path
        d="M41.121 14.63s-.247 3.886-.277 4.956c-.03 1.07.694 1.59 1.577 1.936.884.345 2.38.53 3.562.924 1.183.394 1.863 1.085 2.096 1.441.233.357.777 1.388.764 3.082a281.73 281.73 0 0 1-.06 3.773l-1.468.025s.062-4.215.087-4.808c.024-.592-.371-1.804-1.369-2.095-.997-.292-4.093-.641-5.153-1.59-1.06-.95-1.504-2.552-1.454-3.65.05-1.097.26-3.907.26-3.907l1.435-.086Z"
        strokeLinejoin="round"
      />
      <path
        d="M47.174 30.645h1.762s.654.74.694 1.387c.04.648 0 2.01 0 2.269 0 .26-.149.444-.358.444h-2.408c-.197 0-.567-.139-.567-.492 0-.354.06-1.985.06-2.305 0-.32.49-1.23.817-1.303ZM25.3 36.013h2.812M23.898 8.462h5.623"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h50v56H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default HeatExchanger
