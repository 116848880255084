import React from "react"

const Juicers = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    viewBox="0 0 293 401"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2694_2314)">
      <path
        d="M1.5 171.83C1.5 171.83 32 140.66 79.5 131.16C127 121.66 147.33 118.83 183.33 118.83H234.83C234.83 118.83 283.33 131.33 284.16 133.16C284.99 134.99 280.33 205.83 280 216C279.67 226.17 276.67 253.33 276.83 279.33C276.99 305.33 277 380.33 277 380.33C277 380.33 271.33 385.16 247.83 387.5C224.33 389.84 179.66 390.83 153.33 388.33C127 385.83 120.33 382.66 120.33 382.66V171.83H1.5Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.08002 171.58L5.83002 240.08C5.83002 240.08 17 240 17 259.67C17 279.34 20.67 393.67 20.67 393.67C20.67 393.67 32.17 398.67 70 398.67C107.83 398.67 124.17 394.17 124.17 394.17V171.67L2.08002 171.58Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.83 119.83V6.67C154.83 6.67 166.33 1.5 195.83 1.5C225.33 1.5 234.5 6.33 234.5 6.33V118.83L154.83 119.83Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M213 352.75C213 355.366 212.485 357.956 211.484 360.373C210.483 362.79 209.015 364.986 207.166 366.836C205.316 368.685 203.12 370.153 200.703 371.154C198.286 372.155 195.696 372.67 193.08 372.67V372.67C187.797 372.67 182.73 370.571 178.994 366.836C175.259 363.1 173.16 358.033 173.16 352.75V253.75C173.16 248.467 175.259 243.4 178.994 239.665C182.73 235.929 187.797 233.83 193.08 233.83V233.83C195.696 233.83 198.286 234.345 200.703 235.346C203.12 236.347 205.316 237.815 207.166 239.665C209.015 241.514 210.483 243.71 211.484 246.127C212.485 248.544 213 251.134 213 253.75V352.75Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.17 171.67H282.25"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M192.67 266.5C202 266.5 205.5 259.5 205.5 253.5V157.33C205.5 150.66 208 148.33 214.67 148.33H290L290.67 132.66C290.67 132.66 193.67 129.33 181.34 130.83V254.67C181.34 254.67 182.83 266.5 192.67 266.5Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.83 119.83C154.83 119.83 117.33 128.08 97.83 171.33Z"
        fill="white"
      />
      <path
        d="M154.83 119.83C154.83 119.83 117.33 128.08 97.83 171.33"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2694_2314">
        <rect width="292.17" height="400.17" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Juicers
