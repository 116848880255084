import React from "react"

const Chemex = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    width="252"
    height="392"
    viewBox="0 0 252 392"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2690_1796)">
      <path
        d="M186.33 255.37C186.26 253.09 185.28 221.58 186.39 202.68C202.2 243.48 225.39 286.97 230.75 296C239 310 246.7 342.8 217.75 368C196.5 386.5 151.25 390.5 125 390.5C98.75 390.5 38 384.5 21 369.25C9 358.5 1.5 343.5 1.5 321C1.5 306 31.56 244.65 51.5 195.58C57.59 198.31 68.14 199.94 86.19 202C117.38 205.56 162.45 203.08 178.82 200.91C177.58 214.76 177.14 232.98 178.19 257.1C176.76 257.612 175.391 258.283 174.11 259.1L175.52 266.44C177.943 266.952 180.462 266.747 182.77 265.85C183.802 265.471 184.871 265.203 185.96 265.05C186.19 268.27 186.58 274.72 186.15 275.9L194.07 276.64C194.07 276.64 194.41 273.8 194.62 270.64C194.62 270.96 194.62 271.15 194.62 271.15C194.62 271.15 198.53 268.56 197.95 264.65C197.698 262.672 197.28 260.718 196.7 258.81C196.7 258.81 195.37 255.06 187.28 255.31L186.33 255.37Z"
        fill="white"
      />
      <path
        d="M222 1.5C232 1.5 240 7.25 240 7.25C240 7.25 214.93 42.07 195.07 78.75H48.5C48.282 78.8248 48.0684 78.9116 47.86 79.01C29.27 40.9 5 1.5 5 1.5H222Z"
        fill="white"
      />
      <path
        d="M247 259.17L249.12 266.92L241.62 269.5C241.62 269.5 240.62 263.39 239.83 259.57C240.977 260.054 242.219 260.269 243.461 260.2C244.704 260.131 245.914 259.778 247 259.17Z"
        fill="white"
      />
      <path
        d="M246.17 256.25L246.97 259.17C245.884 259.778 244.674 260.131 243.431 260.2C242.189 260.269 240.947 260.054 239.8 259.57C239.56 258.44 239.34 257.51 239.17 257C238.42 254.75 246.17 256.25 246.17 256.25Z"
        fill="white"
      />
      <path
        d="M249.84 254.75C249.769 255.668 249.469 256.553 248.968 257.325C248.466 258.097 247.78 258.732 246.97 259.17L246.17 256.25C246.17 256.25 238.42 254.75 239.17 257C239.34 257.51 239.56 258.44 239.8 259.57C239.355 259.364 238.946 259.087 238.59 258.75C237.67 260.67 233.5 261.25 233.5 261.25C232.299 259.559 231.188 257.807 230.17 256C229.93 255.33 230.8 253.5 231.28 252.57L231.63 253.25C231.63 253.25 238 254.25 238.13 251.12C237.71 250.56 237.21 249.79 236.63 248.84C239.218 248.049 241.946 247.821 244.63 248.17C246.84 248.75 247.34 249 249.84 254.75Z"
        fill="white"
      />
      <path
        d="M236.63 248.84C237.21 249.79 237.71 250.56 238.13 251.12C238 254.25 231.63 253.25 231.63 253.25L231.28 252.57C231.46 252.22 231.59 252 231.59 252C232.53 250.49 234.49 249.48 236.63 248.84Z"
        fill="white"
      />
      <path
        d="M231.59 252C231.59 252 231.46 252.22 231.28 252.57C229.04 248.19 216.5 223.57 209.13 206C201.87 188.69 201.29 167.92 201.25 163.66C204.147 163.952 207.072 163.652 209.85 162.78C209.386 166.502 209.145 170.249 209.13 174C209 182.25 214.63 199.62 219.63 213.25C224.353 225.541 230.038 237.441 236.63 248.84C234.49 249.48 232.53 250.49 231.59 252Z"
        fill="white"
      />
      <path
        d="M218.5 150.25C218.94 154.88 216.38 160.94 210.19 162.69L209.85 162.78C209.94 162.18 210 161.88 210 161.88L201.25 162.88V163.66C199.745 163.465 198.303 162.935 197.03 162.11C197.72 160.933 198.646 159.911 199.75 159.11C204.38 156.24 206.38 158.37 207 157.11C207.62 155.85 209.59 153.51 206.67 151.66C206.67 151.66 206.29 151.55 205.67 151.41C205.125 149.604 204.341 147.879 203.34 146.28C205.205 144.584 206.647 142.474 207.55 140.12C208.684 139.839 209.866 139.815 211.01 140.05C214.88 141.25 218.07 145.62 218.5 150.25Z"
        fill="white"
      />
      <path
        d="M201.25 162.88L210 161.88C210 161.88 209.94 162.18 209.85 162.78C207.072 163.652 204.147 163.952 201.25 163.66V162.88Z"
        fill="white"
      />
      <path
        d="M208.63 134.25C208.635 136.261 208.266 138.255 207.54 140.13C205.183 140.66 202.902 141.483 200.75 142.58C200.38 142.12 200 141.7 199.63 141.31C198.4 140.03 196.94 138.31 195.63 136.82C196.175 136.158 196.582 135.394 196.825 134.571C197.067 133.749 197.142 132.886 197.043 132.034C196.944 131.183 196.675 130.36 196.25 129.615C195.826 128.87 195.256 128.218 194.573 127.699C193.891 127.179 193.111 126.803 192.28 126.593C191.449 126.382 190.583 126.341 189.736 126.473C188.889 126.605 188.077 126.906 187.349 127.36C186.621 127.813 185.992 128.408 185.5 129.11C183.21 129.56 180.09 130.11 176.23 130.73C177.105 126.794 179.4 123.319 182.677 120.97C185.954 118.621 189.982 117.563 193.991 117.999C197.999 118.435 201.706 120.334 204.401 123.332C207.097 126.331 208.592 130.218 208.6 134.25H208.63Z"
        fill="white"
      />
      <path
        d="M205.88 152.69C205.852 152.26 205.778 151.834 205.66 151.42C206.29 151.56 206.66 151.67 206.66 151.67C209.58 153.52 207.66 155.88 206.99 157.12C206.32 158.36 204.37 156.25 199.74 159.12C198.636 159.921 197.71 160.943 197.02 162.12C195.982 161.422 195.084 160.537 194.37 159.51C193.503 158.291 192.9 156.905 192.6 155.44L193.16 154.68C194.182 153.262 195.634 152.21 197.3 151.68C198.13 152.92 198.62 153.68 198.62 153.68C201.064 153.563 203.494 153.232 205.88 152.69Z"
        fill="white"
      />
      <path
        d="M205.66 151.42C205.778 151.834 205.852 152.26 205.88 152.69C203.497 153.235 201.071 153.569 198.63 153.69C198.63 153.69 198.14 152.89 197.31 151.69C200.023 150.879 202.9 150.786 205.66 151.42V151.42Z"
        fill="white"
      />
      <path
        d="M207.54 140.13C206.637 142.484 205.195 144.594 203.33 146.29C202.557 144.995 201.695 143.755 200.75 142.58C202.902 141.483 205.183 140.66 207.54 140.13V140.13Z"
        fill="white"
      />
      <path
        d="M200.75 142.58C201.695 143.755 202.557 144.995 203.33 146.29C201.333 148.142 198.902 149.459 196.26 150.12C195.64 149.24 194.92 148.27 194.14 147.25C196.041 145.304 198.281 143.721 200.75 142.58V142.58Z"
        fill="white"
      />
      <path
        d="M199.63 141.31C200 141.7 200.38 142.12 200.75 142.58C198.281 143.721 196.041 145.304 194.14 147.25C192.565 145.196 190.826 143.273 188.94 141.5C188.014 140.448 187.27 139.248 186.74 137.95H187.01C188.094 138.746 189.405 139.174 190.75 139.17C191.687 139.171 192.613 138.965 193.461 138.568C194.31 138.17 195.061 137.59 195.66 136.87C196.94 138.34 198.4 140 199.63 141.31Z"
        fill="white"
      />
      <path
        d="M197.92 264.67C198.5 268.58 194.59 271.17 194.59 271.17C194.59 271.17 194.59 270.98 194.59 270.66C194.77 267.79 194.85 264.66 194.45 263.38C193.62 260.62 185.79 263.21 185.79 263.21C185.79 263.21 185.85 263.93 185.93 265.03C184.841 265.183 183.772 265.451 182.74 265.83C180.432 266.727 177.913 266.932 175.49 266.42L174.08 259.08C175.361 258.263 176.73 257.592 178.16 257.08V257.15C180.99 257.31 186.33 255.48 186.33 255.48C186.33 255.48 186.33 255.48 186.33 255.35H187.25C195.34 255.1 196.67 258.85 196.67 258.85C197.249 260.751 197.667 262.698 197.92 264.67V264.67Z"
        fill="white"
      />
      <path
        d="M196.26 150.12C198.902 149.459 201.333 148.142 203.33 146.29C204.331 147.889 205.115 149.614 205.66 151.42C202.904 150.773 200.027 150.853 197.31 151.65L196.26 150.12Z"
        fill="white"
      />
      <path
        d="M196.26 150.12L197.31 151.65C195.644 152.18 194.192 153.232 193.17 154.65L192.61 155.41C192.287 153.82 192.287 152.18 192.61 150.59C193.841 150.576 195.066 150.419 196.26 150.12Z"
        fill="white"
      />
      <path
        d="M195.34 136C194.71 131.31 190.13 128.12 190.13 128.12C190.13 128.12 188.54 128.51 185.53 129.12C186.022 128.418 186.651 127.823 187.379 127.369C188.107 126.916 188.919 126.615 189.766 126.483C190.614 126.351 191.479 126.392 192.31 126.602C193.141 126.813 193.921 127.189 194.603 127.709C195.286 128.228 195.856 128.88 196.28 129.625C196.705 130.37 196.974 131.193 197.073 132.044C197.172 132.896 197.097 133.759 196.855 134.581C196.612 135.404 196.206 136.168 195.66 136.83L195.06 136.11L195.34 136Z"
        fill="white"
      />
      <path
        d="M187 137.9C189.714 137.445 192.401 136.844 195.05 136.1L195.65 136.82C195.051 137.54 194.3 138.12 193.451 138.518C192.603 138.915 191.677 139.121 190.74 139.12C189.395 139.124 188.084 138.696 187 137.9V137.9Z"
        fill="white"
      />
      <path
        d="M194.38 159.5C195.094 160.527 195.992 161.412 197.03 162.11C194.19 166.48 191.29 174.54 189.19 183.62C187.59 180.94 185.39 178.16 183.19 174.57C186.48 162.77 190.41 158.25 192.59 155.43C192.896 156.897 193.506 158.283 194.38 159.5V159.5Z"
        fill="white"
      />
      <path
        d="M194.17 265C194.64 265.34 194.62 269.25 194.6 270.66C194.39 273.78 194.05 276.66 194.05 276.66L186.13 275.92C186.56 274.74 186.13 268.29 185.94 265.07C189.59 264.46 193.73 264.68 194.17 265Z"
        fill="white"
      />
      <path
        d="M194.14 147.25C193.738 147.701 193.402 148.206 193.14 148.75C192.907 149.353 192.729 149.975 192.61 150.61H192.26C188.846 150.61 185.518 149.543 182.74 147.558C179.963 145.574 177.875 142.77 176.77 139.54C180.63 138.99 183.98 138.45 186.77 137.94C187.3 139.238 188.044 140.438 188.97 141.49C190.846 143.267 192.575 145.193 194.14 147.25Z"
        fill="white"
      />
      <path
        d="M196.26 150.12C195.063 150.426 193.835 150.591 192.6 150.61C192.719 149.975 192.897 149.353 193.13 148.75C193.392 148.206 193.728 147.701 194.13 147.25C194.92 148.27 195.64 149.24 196.26 150.12Z"
        fill="white"
      />
      <path
        d="M194.46 263.38C194.86 264.69 194.78 267.79 194.6 270.66C194.6 269.25 194.6 265.34 194.17 265C193.74 264.66 189.59 264.46 185.94 265C185.86 263.9 185.8 263.18 185.8 263.18C185.8 263.18 193.63 260.62 194.46 263.38Z"
        fill="white"
      />
      <path
        d="M190.13 128.12C190.13 128.12 194.71 131.31 195.34 136L195.06 136.1C193.56 134.3 192.44 132.88 192.44 132.88L186.25 136.31C186.321 136.744 186.439 137.17 186.6 137.58C185.903 136.983 185.343 136.242 184.96 135.408C184.577 134.575 184.379 133.668 184.38 132.75C184.374 131.446 184.776 130.174 185.53 129.11C188.54 128.51 190.13 128.12 190.13 128.12Z"
        fill="white"
      />
      <path
        d="M190.84 187C193.15 193.4 190.16 197 186.67 198.88C186.81 197.19 186.98 195.66 187.17 194.33C187.69 190.71 188.38 187.09 189.17 183.62C189.835 184.69 190.394 185.822 190.84 187V187Z"
        fill="white"
      />
      <path
        d="M195.06 136.1C192.411 136.844 189.724 137.445 187.01 137.9C186.867 137.802 186.73 137.695 186.6 137.58C186.439 137.17 186.322 136.744 186.25 136.31L192.44 132.88C192.44 132.88 193.56 134.3 195.06 136.1Z"
        fill="white"
      />
      <path
        d="M189.19 183.62C188.38 187.09 187.69 190.71 187.19 194.33C187 195.66 186.83 197.19 186.69 198.88C186.208 199.128 185.714 199.352 185.21 199.55C183.57 195.22 182.02 190.94 180.59 186.74C181.251 182.639 182.132 178.576 183.23 174.57C185.39 178.16 187.59 180.94 189.19 183.62Z"
        fill="white"
      />
      <path
        d="M187 137.9H186.73C186.676 137.779 186.629 137.656 186.59 137.53C186.718 137.662 186.855 137.786 187 137.9V137.9Z"
        fill="white"
      />
      <path
        d="M186.6 137.58C186.64 137.706 186.686 137.829 186.74 137.95C183.97 138.46 180.62 139 176.74 139.55C175.772 136.711 175.599 133.661 176.24 130.73C180.1 130.13 183.24 129.56 185.51 129.11C184.756 130.174 184.354 131.446 184.36 132.75C184.362 133.669 184.563 134.577 184.949 135.411C185.336 136.245 185.899 136.985 186.6 137.58V137.58Z"
        fill="white"
      />
      <path
        d="M186.67 198.88C186.57 200.07 186.48 201.35 186.39 202.68L185.19 199.55C185.694 199.352 186.188 199.128 186.67 198.88V198.88Z"
        fill="white"
      />
      <path
        d="M186.33 255.37C186.33 255.46 186.33 255.5 186.33 255.5C186.33 255.5 180.99 257.33 178.16 257.17V257.1C180.783 256.125 183.536 255.542 186.33 255.37V255.37Z"
        fill="white"
      />
      <path
        d="M186.39 202.68C185.28 221.58 186.26 253.09 186.33 255.37C183.54 255.543 180.79 256.126 178.17 257.1C177.17 232.98 177.56 214.76 178.8 200.91C180.28 200.71 181.52 200.52 182.5 200.33C183.418 200.151 184.319 199.89 185.19 199.55L186.39 202.68Z"
        fill="white"
      />
      <path
        d="M185.19 199.55C184.319 199.89 183.418 200.151 182.5 200.33C181.5 200.52 180.28 200.71 178.8 200.91C179.28 195.57 179.89 190.91 180.57 186.74C182 190.94 183.55 195.22 185.19 199.55Z"
        fill="white"
      />
      <path
        d="M175 140.75C175 158.13 179.09 167.8 183.21 174.57C182.112 178.576 181.231 182.639 180.57 186.74C174.97 170.26 171.25 155.12 171.25 144C171.25 142.8 171.31 141.55 171.43 140.26L175.02 139.79C175 140.11 175 140.43 175 140.75Z"
        fill="white"
      />
      <path
        d="M180.57 186.74C179.89 190.87 179.28 195.57 178.8 200.91C162.43 203.08 117.36 205.56 86.17 202C68.17 199.94 57.57 198.31 51.48 195.58C60.98 172.21 68.17 151.58 68.71 140.11C78.71 141.7 95.04 143.5 118 143.5C135.859 143.508 153.702 142.426 171.43 140.26C171.31 141.55 171.25 142.8 171.25 144C171.25 155.12 175 170.26 180.57 186.74Z"
        fill="white"
      />
      <path
        d="M196.84 78.75C204.53 84.52 181.32 104.9 176.05 130.75C175.05 130.9 174.05 131.06 172.92 131.21C176.43 116.04 185.09 97.15 195.07 78.74L196.84 78.75Z"
        fill="white"
      />
      <path
        d="M176.05 130.76C175.436 133.722 175.101 136.735 175.05 139.76L171.46 140.23C171.744 137.199 172.242 134.191 172.95 131.23C174 131.07 175.06 130.91 176.05 130.76Z"
        fill="white"
      />
      <path
        d="M175.88 134.25C175.879 136.053 176.176 137.844 176.76 139.55L175.02 139.79C175.071 136.765 175.406 133.752 176.02 130.79H176.23C175.99 131.928 175.873 133.087 175.88 134.25V134.25Z"
        fill="white"
      />
      <path
        d="M126.13 134.5C141.784 134.485 157.417 133.389 172.92 131.22C172.212 134.181 171.714 137.189 171.43 140.22C153.703 142.399 135.86 143.495 118 143.5C95 143.5 78.67 141.7 68.71 140.11C68.71 139.64 68.71 139.19 68.71 138.75C68.6715 136.143 68.3903 133.545 67.87 130.99C87.1991 133.359 106.656 134.532 126.13 134.5Z"
        fill="white"
      />
      <path
        d="M68.7499 138.75C68.7499 139.19 68.7499 139.64 68.7499 140.11C65.4899 139.59 62.9099 139.11 61.0199 138.69C61.0199 138.22 61.0199 137.74 61.0199 137.25C61.0208 134.83 60.8705 132.412 60.5699 130.01C62.3899 130.27 64.8699 130.61 67.9299 131.01C68.4423 133.559 68.7168 136.15 68.7499 138.75V138.75Z"
        fill="white"
      />
      <path
        d="M61 138.69C62.89 139.1 65.47 139.59 68.73 140.11C68.19 151.61 61 172.21 51.5 195.58C46.12 193.18 44.24 189.92 43.77 184.75C42.77 174 60.3 162.93 61 138.69Z"
        fill="white"
      />
      <path
        d="M67.91 131C64.85 130.62 62.37 130.28 60.55 130C58 109.08 44.83 96.2 43.25 89C41.83 82.5 46.18 79.8 47.86 79C57.36 98.49 65.38 117.62 67.91 131Z"
        fill="white"
      />
      <path
        d="M61 137.25C61 137.74 61 138.25 61 138.69C58.34 138.13 57 137.75 57 137.75V129.46C57 129.46 58.24 129.67 60.55 130.01C60.8506 132.412 61.0008 134.83 61 137.25V137.25Z"
        fill="white"
      />
      <path
        d="M48.5 78.75H195.07C185.07 97.16 176.43 116.05 172.92 131.22C157.417 133.389 141.784 134.485 126.13 134.5C106.67 134.533 87.2261 133.365 67.91 131C65.38 117.63 57.36 98.5 47.91 79C48.1023 78.9066 48.2992 78.8232 48.5 78.75V78.75Z"
        fill="white"
      />
      <path
        d="M186.39 202.68C202.2 243.48 225.39 286.97 230.75 296C239 310 246.7 342.8 217.75 368C196.5 386.5 151.25 390.5 125 390.5C98.75 390.5 38 384.5 21 369.25C9 358.5 1.5 343.5 1.5 321C1.5 306 31.56 244.65 51.5 195.58"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M47.86 79C29.27 40.9 5 1.5 5 1.5H222C232 1.5 240 7.25 240 7.25C240 7.25 214.93 42.07 195.07 78.75"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M176.05 130.76C181.32 104.9 204.53 84.52 196.84 78.76H48.5001C48.2821 78.8348 48.0684 78.9216 47.8601 79.02C46.1801 79.81 41.8601 82.51 43.2501 89.02C44.8301 96.22 57.9601 109.1 60.5501 130.02"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M183.21 174.57C179.09 167.8 175 158.13 175 140.75C175 140.43 175 140.11 175 139.75"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M186.67 198.88C190.16 197.02 193.15 193.4 190.84 187C190.4 185.823 189.848 184.691 189.19 183.62"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M61 138.69C60.3 162.93 42.77 174 43.75 184.75C44.22 189.92 46.1 193.18 51.48 195.58C57.57 198.31 68.12 199.94 86.17 202C117.36 205.56 162.43 203.08 178.8 200.91"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M176.26 130.73H176.05C175.05 130.88 174.05 131.04 172.92 131.19C157.417 133.359 141.784 134.455 126.13 134.47C106.67 134.513 87.2267 133.355 67.91 131C64.85 130.62 62.37 130.28 60.55 130C58.24 129.66 57 129.45 57 129.45V137.74C57 137.74 58.32 138.12 61 138.68C62.89 139.09 65.47 139.58 68.73 140.1C78.73 141.69 95.06 143.49 118.02 143.49C135.879 143.498 153.722 142.416 171.45 140.25L175.04 139.78L176.78 139.54"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M192.6 150.61H192.25C189.013 150.608 185.849 149.646 183.158 147.846C180.467 146.046 178.371 143.489 177.133 140.498C175.896 137.506 175.573 134.215 176.206 131.04C176.839 127.865 178.398 124.95 180.688 122.661C182.978 120.373 185.895 118.815 189.07 118.184C192.245 117.553 195.536 117.878 198.527 119.117C201.518 120.356 204.074 122.455 205.872 125.146C207.67 127.838 208.63 131.003 208.63 134.24C208.635 136.251 208.266 138.245 207.54 140.12"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M186.6 137.58C185.903 136.983 185.343 136.242 184.96 135.408C184.577 134.575 184.379 133.668 184.38 132.75C184.374 131.446 184.776 130.174 185.53 129.11C186.022 128.408 186.651 127.813 187.379 127.36C188.107 126.906 188.919 126.605 189.766 126.473C190.613 126.341 191.479 126.382 192.31 126.593C193.141 126.803 193.921 127.179 194.603 127.699C195.286 128.218 195.856 128.87 196.28 129.615C196.705 130.36 196.974 131.183 197.073 132.034C197.172 132.886 197.097 133.749 196.855 134.571C196.612 135.394 196.205 136.158 195.66 136.82"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M186.33 255.37C186.26 253.09 185.28 221.58 186.39 202.68C186.48 201.35 186.57 200.07 186.67 198.88C186.81 197.19 186.98 195.66 187.17 194.33C187.69 190.71 188.38 187.09 189.17 183.62C191.27 174.54 194.17 166.48 197.01 162.11"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M192.61 155.43C190.43 158.25 186.5 162.77 183.21 174.57C182.112 178.576 181.231 182.639 180.57 186.74C179.89 190.87 179.28 195.57 178.8 200.91C177.56 214.76 177.12 232.98 178.17 257.1"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M209.85 162.78L210.19 162.69C216.38 160.94 218.94 154.88 218.5 150.25C218.06 145.62 214.88 141.25 211 140.06C209.856 139.825 208.674 139.849 207.54 140.13C205.183 140.66 202.902 141.483 200.75 142.58"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M194.14 147.25C193.738 147.701 193.402 148.206 193.14 148.75C192.907 149.353 192.729 149.975 192.61 150.61C192.287 152.2 192.287 153.84 192.61 155.43C192.91 156.895 193.513 158.282 194.38 159.5C195.163 160.643 196.18 161.605 197.365 162.323C198.55 163.04 199.875 163.496 201.25 163.66"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M186.6 137.58C186.439 137.17 186.322 136.744 186.25 136.31L192.44 132.88C192.44 132.88 193.56 134.3 195.06 136.1L195.66 136.82C196.94 138.34 198.4 140.03 199.66 141.31C200.03 141.7 200.41 142.12 200.78 142.58C201.725 143.755 202.587 144.995 203.36 146.29C204.361 147.889 205.145 149.614 205.69 151.42C205.808 151.834 205.882 152.26 205.91 152.69C203.527 153.235 201.101 153.569 198.66 153.69C198.66 153.69 198.17 152.89 197.34 151.69L196.29 150.16C195.67 149.28 194.95 148.31 194.17 147.29C192.595 145.236 190.856 143.313 188.97 141.54C188.044 140.488 187.3 139.288 186.77 137.99C186.705 137.857 186.648 137.72 186.6 137.58V137.58Z"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M236.63 248.84C230.038 237.441 224.353 225.541 219.63 213.25C214.63 199.62 209 182.25 209.13 174C209.145 170.249 209.386 166.502 209.85 162.78C209.94 162.18 210 161.88 210 161.88L201.25 162.88V163.66C201.25 167.92 201.87 188.66 209.13 206C216.5 223.6 229.04 248.19 231.28 252.57"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M185.94 265C186.17 268.22 186.56 274.67 186.13 275.85L194.05 276.59C194.05 276.59 194.39 273.75 194.6 270.59"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M178.17 257.1C176.74 257.612 175.371 258.283 174.09 259.1L175.5 266.44C177.923 266.952 180.442 266.747 182.75 265.85C183.782 265.471 184.851 265.203 185.94 265.05C189.59 264.48 193.73 264.7 194.17 265.05C194.61 265.4 194.62 269.3 194.6 270.71C194.6 271.03 194.6 271.22 194.6 271.22C194.6 271.22 198.51 268.63 197.93 264.72C197.678 262.742 197.26 260.788 196.68 258.88C196.68 258.88 195.35 255.13 187.26 255.38H186.34C183.547 255.548 180.794 256.128 178.17 257.1V257.1Z"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M247 259.17C247.809 258.732 248.496 258.097 248.998 257.325C249.499 256.553 249.799 255.668 249.87 254.75C247.37 249 246.87 248.75 244.7 248.17C242.016 247.821 239.288 248.049 236.7 248.84C234.56 249.48 232.6 250.49 231.7 252C231.7 252 231.57 252.22 231.39 252.57C230.91 253.5 230.04 255.33 230.28 256C231.298 257.807 232.409 259.559 233.61 261.25C233.61 261.25 237.78 260.67 238.7 258.75C239.056 259.087 239.465 259.364 239.91 259.57"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M239.8 259.57C239.56 258.44 239.34 257.51 239.17 257C238.42 254.75 246.17 256.25 246.17 256.25L246.97 259.17L249.09 266.92L241.59 269.5C241.59 269.5 240.59 263.39 239.8 259.57Z"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M28.5 361.67C28.5 361.67 67.84 351 117.17 351C166.5 351 205.5 365 205.5 365"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2690_1796">
        <rect width="251.34" height="392" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Chemex
