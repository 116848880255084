import React from "react"

const ManualDrippourOver = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    viewBox="0 0 288 294"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2690_1866)">
      <path
        d="M55.42 212.58C55.42 212.58 31.09 223.41 31.42 249.08C31.75 274.75 68.92 291.91 109.75 291.91C150.58 291.91 187.58 282.41 195.92 256.58C204.26 230.75 179.42 216.08 171.09 212.58C162.76 209.08 55.42 212.58 55.42 212.58Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M62.92 179.75C62.92 179.75 47.25 186.25 51.92 207.92C56.59 229.59 94.59 235.25 119.25 235.25C143.91 235.25 196.08 225.58 173.42 185.58C150.76 145.58 62.92 179.75 62.92 179.75Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M258.58 44C243.33 44.83 231.5 59.92 226.75 62.17C230.028 55.9436 231.985 49.1077 232.5 42.09L2.07996 48.75L66.08 194.75C66.08 194.75 78.91 206.42 116.25 206.42C153.59 206.42 164.25 196.09 164.25 196.09C164.25 196.09 207.42 186.26 239.92 157.26C272.42 128.26 281.59 105.43 285.59 84.26C289.59 63.09 273.83 43.17 258.58 44ZM274.37 81.71C272.29 91.96 268.66 102.84 259.5 116.17C250.34 129.5 225.58 158.67 203.42 166.59C181.26 174.51 176.42 170.67 176.42 170.67L210.21 95.21C210.21 95.21 226.5 89.21 246 63.21C249.29 58.82 257.71 54.63 265.67 58.92C273.63 63.21 276.46 71.46 274.38 81.71H274.37Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M117 85C180.789 85 232.5 66.3079 232.5 43.25C232.5 20.1921 180.789 1.5 117 1.5C53.2111 1.5 1.5 20.1921 1.5 43.25C1.5 66.3079 53.2111 85 117 85Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M74.2299 29C73.6999 24.93 70.5299 22 67.1399 22.42C63.7499 22.84 61.4299 26.52 61.9599 30.59L68.4999 81.15C72.6066 81.8167 76.8399 82.4167 81.1999 82.95L74.2299 29Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M51.43 33.66C50.62 29.66 47.26 26.91 43.91 27.58C40.56 28.25 38.5 32.06 39.3 36.09L47.4 76.57C51.6 77.72 56.02 78.76 60.64 79.7L51.43 33.66Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M29.46 42.73C28.4 38.73 25.23 36.17 22.39 36.93C19.55 37.69 18.11 41.53 19.17 45.49L25.4 68.68C29.4228 70.5524 33.5457 72.2016 37.75 73.62L29.46 42.73Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M209.55 36.92C206.71 36.16 203.55 38.76 202.48 42.72L194 74.37C198.165 73.0374 202.26 71.4954 206.27 69.75L212.78 45.49C213.84 41.52 212.4 37.68 209.55 36.92Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M123.44 84.93V24.63C123.369 22.7053 122.555 20.883 121.169 19.5463C119.782 18.2096 117.931 17.4627 116.005 17.4627C114.079 17.4627 112.228 18.2096 110.841 19.5463C109.455 20.883 108.641 22.7053 108.57 24.63V84.89C111.357 84.9633 114.17 85 117.01 85C119.163 85 121.307 84.9767 123.44 84.93Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M188 27.58C184.65 26.91 181.28 29.63 180.48 33.66L171.18 80.12C175.773 79.2333 180.177 78.2333 184.39 77.12L192.61 36.06C193.45 32.06 191.39 28.25 188 27.58Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M98 24.69C97.77 20.59 94.59 17.43 90.9 17.64C87.21 17.85 84.4 21.34 84.63 25.44L87.87 83.67C92.2633 84.0833 96.7533 84.4166 101.34 84.67L98 24.69Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M164.8 22.44C161.42 22 158.25 25 157.72 29L150.72 83.17C155.067 82.69 159.297 82.1233 163.41 81.47L170 30.61C170.51 26.54 168.19 22.88 164.8 22.44Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M141 17.63C137.31 17.42 134.13 20.63 133.9 24.68L130.56 84.68C135.133 84.4867 139.62 84.1966 144.02 83.81L147.27 25.4C147.55 21.33 144.74 17.84 141 17.63Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M68 268.36C77.3 272.75 107.71 278.95 128.83 275.2C128.762 277.458 128.504 279.705 128.06 281.92C116.63 284.76 85.19 282.18 66.46 275.2C66.746 272.875 67.2619 270.583 68 268.36V268.36Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2690_1866">
        <rect width="287.71" height="293.42" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default ManualDrippourOver
