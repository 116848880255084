import React from "react"

const DripCoffeeGrinders = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    viewBox="0 0 200 381"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2690_1636)">
      <path
        d="M198.38 362.25C198.38 362.25 178.01 382.75 102.26 377.75C26.51 372.75 1.51001 346.75 1.51001 346.75C1.51001 346.75 8.38001 126.13 8.63001 117.38C8.88001 110.55 18.46 107.55 18.71 107.55C18.96 107.55 2.79001 13.38 2.79001 13.38C2.79001 13.38 3.00001 1.5 82.63 1.5C162.26 1.5 164.26 10.75 164.26 10.75L151.64 104.25V113.25C151.64 113.25 166.02 119.25 177.64 140.25C189.26 161.25 188.27 185.38 188.27 185.38L198.38 362.25Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.21 376.29L89.21 242.12C89.21 242.12 114.54 244.29 146.63 243.62C178.72 242.95 191.13 235.7 191.13 235.7"
        fill="white"
      />
      <path
        d="M86.21 376.29L89.21 242.12C89.21 242.12 114.54 244.29 146.63 243.62C178.72 242.95 191.13 235.7 191.13 235.7"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.71 107.54V117.54C18.71 117.54 22.71 123.04 41.96 127.79C61.21 132.54 90.04 134.46 102.04 134.79C114.04 135.12 143.41 132.54 151.66 124.12V113.25"
        fill="white"
      />
      <path
        d="M18.71 107.54V117.54C18.71 117.54 22.71 123.04 41.96 127.79C61.21 132.54 90.04 134.46 102.04 134.79C114.04 135.12 143.41 132.54 151.66 124.12V113.25"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.38 118.29C9.38 118.29 92.38 178.96 104.21 186.12C116.04 193.28 132.04 198.62 153.54 197.45C175.04 196.28 185.12 182.95 187.62 179.45"
        fill="white"
      />
      <path
        d="M9.38 118.29C9.38 118.29 92.38 178.96 104.21 186.12C116.04 193.28 132.04 198.62 153.54 197.45C175.04 196.28 185.12 182.95 187.62 179.45"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163.776 182.031C165.875 175.598 158.449 167.405 147.189 163.731C135.93 160.057 125.101 162.293 123.002 168.725C120.903 175.158 128.329 183.351 139.588 187.025C150.847 190.699 161.677 188.463 163.776 182.031Z"
        fill="white"
        stroke="#858180"
        strokeWidth="2.9979"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.25 23.38H162.5"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2690_1636">
        <rect width="199.88" height="380.04" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default DripCoffeeGrinders
