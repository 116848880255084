import React from "react"

const PingleBoiler = ({ classes = "w-[2.15rem] h-[3.5rem]" }) => (
  <svg className={classes} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g stroke="currentColor" strokeLinejoin="round">
      <path d="M32.914 25.375H1.502v18.836h31.412V25.375Z" />
      <path d="M17.236.2H3.339c-.427 0-.71.133-.778.37C2.177 1.903.889 6.358.84 6.535c-.059.207 0 18.497 0 18.803 0 .306.898.326 1.144.326h30.951c.336 0 .948-.296.948-.494V6.724c0-.277-.376-1.131-.376-1.131L31.213.533s-.166-.339-.798-.339L17.235.2Z" />
      <path d="M29.248 25.258c1.94 0 3.512-1.736 3.512-3.877 0-2.142-1.572-3.878-3.511-3.878-1.94 0-3.512 1.736-3.512 3.878 0 2.141 1.572 3.877 3.512 3.877ZM1.505 44.211.479 49.868" />
      <path d="M29.384 25.257a3.373 3.373 0 1 0 0-6.747 3.373 3.373 0 0 0 0 6.747ZM2.054 6.172c-.076.257-.145.552.24.552h30.172c.303 0 .303-.223.177-.552-.127-.329-1.73-4.21-1.73-4.21H3.217S2.13 5.916 2.054 6.173ZM8.647 9.438H5.885V13h2.762V9.437ZM8.647 14.37H5.885v3.563h2.762V14.37ZM8.647 19.185H5.885v3.562h2.762v-3.562ZM17.722 9.417h-2.763v3.563h2.763V9.417ZM4.53 25.639s.407 2.447.514 2.986c.106.539 1.17 1.449 6.998 1.449 5.827 0 6.458-1.5 6.458-1.5l.532-2.927-14.502-.008Z" />
      <path d="M7.001 29.65s-.382 1.474 4.761 1.474 4.578-1.473 4.578-1.473H7Z" />
      <path d="M11.867 29.541c-.88 0-1.73.58-1.73.58s-.279 1.647-.4 1.949c-.121.302-.79 1.052-.79 2.722s-.051 5.71 2.947 5.71 2.973-4.447 2.973-5.856a4.195 4.195 0 0 0-.843-2.443c-.053-.092-.487-2.012-.565-2.117-.079-.105-.657-.544-1.592-.544ZM28.061 25.672s.02 3.194.158 3.828c.139.635.277 3.315.277 3.966v9.687h1.421v-9.707c0-.665-.078-3.275-.295-3.992-.158-.868-.079-3.789-.079-3.789l-1.482.007Z" />
      <path d="M28.396 43.147h1.625v4.157c0 .328-.632.5-.776.5-.143 0-.849-.053-.849-.5v-4.157ZM19.79 46.947c0 .799-.931 1.433-2.078 1.433H6.308c-1.149 0-2.079-.64-2.079-1.433 0-.798.931-1.433 2.079-1.433h11.405c1.148 0 2.079.642 2.079 1.433h-.002ZM4.122 54.945a.856.856 0 0 1-.856.855h-.92a.855.855 0 0 1-.855-.855.855.855 0 0 1 .855-.854h.921a.855.855 0 0 1 .856.854h-.001ZM33.245 54.945a.855.855 0 0 1-.855.855h-.92a.855.855 0 0 1-.85-.855.856.856 0 0 1 .854-.854h.92a.855.855 0 0 1 .855.854h-.004Z" />
      <path d="M34.521 49.868H.48v5.025h34.042v-5.025ZM32.916 44.211l1.605 5.657M9.608 32.373s1.263-.447 2.34-.447a6.83 6.83 0 0 1 2.158.42" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.28)" d="M0 0h34.441v56H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default PingleBoiler
