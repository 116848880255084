import React from "react"

const FrenchPress = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    viewBox="0 0 284 406"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2690_1781)">
      <path
        d="M161 296.94C161 296.94 168.19 299.57 170.44 299.88C172.69 300.19 180.44 300.07 181.57 300.13C182.7 300.19 185.26 300.44 185.13 307.19C184.997 311.576 184.39 315.935 183.32 320.19L159.57 320.69L161 296.94Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M23 357C23 357 15.37 367.69 15 370.12C14.63 372.55 15.88 378.62 20.38 378.68C24.88 378.74 33.63 375.87 35.26 375.81C36.89 375.75 38.47 366 38.47 366L24.85 357.31L23 357Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M72.78 378.15C72.78 378.15 62.07 385.52 59 389.69C55.93 393.86 50.33 404.13 45.25 403.63C40.17 403.13 40.83 394.63 42.67 391.03C44.51 387.43 54.38 375.36 54.38 375.36L72.78 378.15Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M115.09 377.38C115.09 377.38 127.03 379.13 133.34 384.38C139.65 389.63 148.84 400.94 153.59 401.75C158.34 402.56 160.47 398.56 157.84 393.56C155.21 388.56 143.9 376.37 129.65 370.31L115.09 377.38Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M94.53 380.69C70.41 380.69 21.66 375.82 21.66 352.94C21.66 330.06 22.66 135.07 22.66 130.69C22.66 126.31 23.91 121.19 15.66 120.69C7.40999 120.19 2.28999 121.07 1.53999 117.44C0.789986 113.81 13.91 100.44 20.54 96.82C27.17 93.2 85.42 95.57 96.79 95.57H164.91C164.91 95.57 164.16 342.32 164.04 350.95C163.92 359.58 158.28 380.69 94.53 380.69Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M93.53 332.56C127.495 332.56 155.03 329.202 155.03 325.06C155.03 320.918 127.495 317.56 93.53 317.56C59.5645 317.56 32.03 320.918 32.03 325.06C32.03 329.202 59.5645 332.56 93.53 332.56Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M97.28 102.06H89.78V324.56H97.28V102.06Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M33.95 91.56V128.56C33.95 128.56 67.61 123.23 93.86 123.23C120.11 123.23 151.61 129.9 151.61 129.9V92.56L33.95 91.56Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M172.78 329.44C181.787 330.216 190.855 329.88 199.78 328.44C215.53 326.07 234.66 319.44 256.16 296.82C277.66 274.2 281.54 245.45 281.66 230.45C281.78 215.45 276.29 193.7 265.54 177.7C254.79 161.7 240.79 152.08 232.54 148.45C224.29 144.82 213.79 144.45 211.29 144.45H196.41L196.54 170.33H216.54C226.17 170.33 238.04 179.58 244.04 190.21C250.04 200.84 255.42 213.09 254.42 228.84C253.42 244.59 251.55 257.97 236.55 276.97C221.55 295.97 200.18 300.35 192.05 302.1C183.92 303.85 179.68 302.6 179.68 302.6L172.78 329.44Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M16.66 84.69V95.56C16.66 95.56 41.29 103.81 96.04 103.81C150.79 103.81 173.29 96.31 173.29 96.31V85.31C173.29 83.19 167.54 81.81 167.54 81.81C167.54 81.81 163.16 65.06 152 57.19C140.84 49.32 127.63 45.69 95.63 45.69C63.63 45.69 55.41 47.81 45 51.94C34.59 56.07 26.78 66.44 22.28 82.19C20.0904 82.6477 18.0635 83.6836 16.41 85.19"
        fill="white"
      />
      <path
        d="M16.66 84.69V95.56C16.66 95.56 41.29 103.81 96.04 103.81C150.79 103.81 173.29 96.31 173.29 96.31V85.31C173.29 83.19 167.54 81.81 167.54 81.81C167.54 81.81 163.16 65.06 152 57.19C140.84 49.32 127.63 45.69 95.63 45.69C63.63 45.69 55.41 47.81 45 51.94C34.59 56.07 26.78 66.44 22.28 82.19C20.0904 82.6477 18.0635 83.6836 16.41 85.19"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M95.16 47C107.724 47 117.91 36.8145 117.91 24.25C117.91 11.6855 107.724 1.5 95.16 1.5C82.5955 1.5 72.41 11.6855 72.41 24.25C72.41 36.8145 82.5955 47 95.16 47Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M198 144.06H178.5C170.5 144.06 169.25 143.56 167.13 145.06C165.01 146.56 151.13 153.56 93.51 153.56C35.89 153.56 19.76 144.19 19.76 144.19V170.07C24.03 172.76 31.92 174.79 42.52 176.23L38.26 199.23C38.26 199.23 38.59 318.06 38.43 333.4C38.27 348.74 34.89 372.19 31.26 380.94C27.63 389.69 24.26 393.82 27.26 400.69C33.2528 402.167 39.3566 403.15 45.51 403.63C41.07 401.07 42.45 395.26 43.51 393.07C44.57 390.88 50.2 381.76 51.76 371.45C53.32 361.14 53.7 353.2 54.32 340.58C54.94 327.96 55.38 269.08 55.26 256.33C55.14 243.58 55 201.13 55 200.88C55 200.63 52.36 184.8 51.1 177.2C63.35 178.37 78.22 178.93 94.78 178.93C112.964 179.051 131.141 178.133 149.22 176.18C148.42 183.32 146.64 199.25 146.44 201.63C146.19 204.71 145.44 229.55 145.11 253.88C144.78 278.21 146.71 346.3 147.21 357.17C147.71 368.04 152.9 380.36 155.46 384.98C158.02 389.6 159.96 391.85 159.59 396.04C159.22 400.23 157.59 401.04 153.34 401.92C156.53 402.48 165.65 400.17 169.65 398.92C173.65 397.67 173.9 393.8 173.03 391.05C172.16 388.3 165.66 376.18 162.78 369.3C159.9 362.42 159.53 333.8 159.41 330.68C159.29 327.56 168.79 331.93 169.79 332.43C170.79 332.93 172.17 332.68 175.67 330.81C187.67 330.31 187.05 324.44 187.05 324.44L185.93 304.82H177.43C173.43 304.82 169.31 307.57 169.31 307.57C169.31 307.57 160.94 318.32 159.69 318.07C158.82 315.2 158.57 196.45 158.57 196.45L155.33 175.45L156.2 175.33C160.08 174.83 168.7 170.58 168.7 170.58H199.7C203.2 170.58 206.08 162.33 206.08 157.46C206.08 152.59 203 144.06 198 144.06Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2690_1781">
        <rect width="283.16" height="405.14" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default FrenchPress
