import React from "react"

const Blenders = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    width="172"
    height="401"
    viewBox="0 0 172 401"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2694_2324)">
      <path
        d="M141.52 33.16C141.52 33.16 157.11 34.62 164.52 41.51C171.93 48.4 170.3 58.19 169.76 77C169.41 89 168.61 116.41 168.25 128.93C167.89 141.45 159.39 150.78 151.39 152.56C143.39 154.34 128.79 155.81 128.79 155.81L130.46 140.09C130.46 140.09 143.74 142.36 144.01 133.09C144.28 123.82 146.18 58.49 146.36 52.43C146.54 46.37 136.46 48.87 136.46 48.87L139.94 34L141.52 33.16Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.88 394.74L30.47 399.32C30.47 399.32 16.06 397.97 12.82 395.32C9.58001 392.67 1.64002 380.32 1.50002 377.27C1.36002 374.22 4.60002 266.67 4.60002 266.67L18.07 242.82V171.24C18.07 133.24 15.78 93.64 14.7 77.34C13.62 61.04 11.2 2.71 11.2 2.71C11.2 2.71 31.41 1.5 76.14 1.5C120.87 1.5 142.29 2.85 142.29 2.85C142.29 2.85 138.65 58.08 137.44 74.39C136.23 90.7 133.44 168.15 133.44 175.83V243.19C133.44 243.19 147.05 264.19 147.59 267.3C148.13 270.41 150.77 377 150.77 377C150.77 377 143.09 391.82 140.77 393.7C138.45 395.58 120.83 398.82 120.83 398.82L113.51 394.82L37.88 394.74Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.59998 268C4.59998 268 14.3 272.85 21.6 272.85H129.22C135.609 272.75 141.868 271.03 147.41 267.85"
        fill="white"
      />
      <path
        d="M4.59998 268C4.59998 268 14.3 272.85 21.6 272.85H129.22C135.609 272.75 141.868 271.03 147.41 267.85"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.27 342.2C89.7347 342.2 100.65 331.285 100.65 317.82C100.65 304.355 89.7347 293.44 76.27 293.44C62.8053 293.44 51.89 304.355 51.89 317.82C51.89 331.285 62.8053 342.2 76.27 342.2Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.87 339C82.87 339.893 82.6941 340.777 82.3524 341.602C82.0107 342.427 81.5098 343.177 80.8783 343.808C80.2469 344.44 79.4973 344.941 78.6723 345.282C77.8473 345.624 76.963 345.8 76.07 345.8V345.8C75.177 345.8 74.2928 345.624 73.4678 345.282C72.6428 344.941 71.8931 344.44 71.2617 343.808C70.6303 343.177 70.1294 342.427 69.7876 341.602C69.4459 340.777 69.27 339.893 69.27 339V296.46C69.27 294.656 69.9864 292.927 71.2617 291.652C72.5369 290.376 74.2666 289.66 76.07 289.66C77.8735 289.66 79.6031 290.376 80.8783 291.652C82.1536 292.927 82.87 294.656 82.87 296.46V339Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 377.23C1.5 377.23 5.14 383.02 32.35 385.45C40.43 375.89 41.65 375.62 49.06 375.62H108.06C112.37 375.62 121.53 384.92 121.53 384.92C121.53 384.92 144.03 384.52 150.76 376.97"
        fill="white"
      />
      <path
        d="M1.5 377.23C1.5 377.23 5.14 383.02 32.35 385.45C40.43 375.89 41.65 375.62 49.06 375.62H108.06C112.37 375.62 121.53 384.92 121.53 384.92C121.53 384.92 144.03 384.52 150.76 376.97"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.53 28.85H11.74"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.92 246.82C19.6883 248.838 23.9058 249.87 28.18 249.82H130.29C131.318 249.756 132.319 249.47 133.226 248.981C134.132 248.493 134.922 247.813 135.54 246.99"
        fill="white"
      />
      <path
        d="M15.92 246.82C19.6883 248.838 23.9058 249.87 28.18 249.82H130.29C131.318 249.756 132.319 249.47 133.226 248.981C134.132 248.493 134.922 247.813 135.54 246.99"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 176.41H35C39.49 176.41 46.32 189.25 56.82 189.25H100.56C108.28 189.25 114.56 176.25 120.68 176.25H133.34"
        fill="white"
      />
      <path
        d="M18 176.41H35C39.49 176.41 46.32 189.25 56.82 189.25H100.56C108.28 189.25 114.56 176.25 120.68 176.25H133.34"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.17 52.73H87.1"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.17 73.0699H87.1"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.17 93.4H87.1"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.17 113.73H87.1"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.17 134.07H87.1"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.17 154.4H87.1"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2694_2324">
        <rect width="171.8" height="400.82" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Blenders
