import React from "react"

const Tampers = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    viewBox="0 0 175 292"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2694_2103)">
      <path
        d="M1.52 229.27C2.13 249.75 40.29 266.27 87.28 266.27C134.27 266.27 172.42 249.74 173.03 229.27V253C173.03 273.72 134.63 290.51 87.26 290.51C39.89 290.51 1.5 273.69 1.5 253V229.3L1.52 229.27Z"
        fill="white"
      />
      <path
        d="M45.5499 214.85C45.5499 225.65 64.23 234.4 87.28 234.4C110.33 234.4 129 225.65 129 214.85C129 210.14 125.43 205.85 119.49 202.43C119.57 199.93 119.66 197.08 119.78 194.04C151.04 199.64 173.05 213.04 173.05 228.76C173.06 228.93 173.06 229.1 173.05 229.27C172.44 249.75 134.28 266.27 87.3 266.27C40.32 266.27 2.14995 249.74 1.53995 229.27C1.52998 229.1 1.52998 228.93 1.53995 228.76C1.49995 213 23.7099 199.52 55.1899 194C55.2499 197.1 55.26 199.91 55.24 202.36C49.19 205.72 45.5499 210.09 45.5499 214.85Z"
        fill="white"
      />
      <path
        d="M87.28 191.25C76.5236 191.23 65.7861 192.15 55.19 194C54.9965 179.3 53.8374 164.628 51.72 150.08C46.91 118.66 36.23 98.96 33.37 92.08C30.51 85.2 23.45 64.41 32.47 41.86C41.49 19.31 58.78 1.5 86.3 1.5C113.82 1.5 136.07 17.74 144.64 45.71C153.21 73.68 142.53 91.41 138.64 100.71C134.75 110.01 125.84 136.82 123 151.26C121.33 159.68 120.33 179.26 119.79 194.04C109.057 192.153 98.1777 191.22 87.28 191.25V191.25Z"
        fill="white"
      />
      <path
        d="M45.55 214.85C45.55 210.09 49.1901 205.72 55.2401 202.33C55.2401 208.82 55.03 212.75 55.03 212.75C55.03 212.75 55.9301 226.88 86.9001 226.88C117.87 226.88 119.23 212.75 119.23 212.75C119.23 212.75 119.31 208.62 119.49 202.43C125.43 205.81 129 210.14 129 214.85C129 225.65 110.32 234.4 87.28 234.4C64.24 234.4 45.55 225.65 45.55 214.85Z"
        fill="white"
      />
      <path
        d="M87.28 195.3C74.4 195.3 62.89 198.03 55.28 202.3C55.28 199.85 55.28 197.04 55.23 193.94C65.8269 192.1 76.5645 191.19 87.32 191.22C98.2144 191.19 109.09 192.124 119.82 194.01C119.7 197.01 119.61 199.9 119.53 202.4C111.84 198.08 100.25 195.3 87.28 195.3Z"
        fill="white"
      />
      <path
        d="M87.28 195.3C100.28 195.3 111.84 198.08 119.49 202.43C119.31 208.62 119.23 212.75 119.23 212.75C119.23 212.75 117.87 226.88 86.9 226.88C55.93 226.88 55 212.75 55 212.75C55 212.75 55.19 208.82 55.21 202.33C62.89 198 74.4 195.3 87.28 195.3Z"
        fill="white"
      />
      <path
        d="M173.05 253.29V252.97V229.27"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M119.78 194C151.04 199.6 173.05 213 173.05 228.72C173.06 228.89 173.06 229.06 173.05 229.23C172.44 249.71 134.28 266.23 87.3 266.23C40.32 266.23 2.14995 249.7 1.53995 229.23C1.52998 229.06 1.52998 228.89 1.53995 228.72C1.49995 213 23.7099 199.52 55.1899 194"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M119.49 202.43C125.43 205.81 129 210.14 129 214.85C129 225.65 110.32 234.4 87.28 234.4C64.24 234.4 45.55 225.65 45.55 214.85C45.55 210.09 49.1901 205.72 55.2401 202.33"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M173.05 253C173.05 273.72 134.65 290.51 87.28 290.51C39.91 290.51 1.5 273.69 1.5 253"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M119.49 202.43C119.31 208.62 119.23 212.75 119.23 212.75C119.23 212.75 117.87 226.88 86.9 226.88C55.93 226.88 55 212.75 55 212.75C55 212.75 55.19 208.82 55.21 202.33C55.21 199.88 55.21 197.07 55.16 193.97C54.9665 179.27 53.8073 164.598 51.69 150.05C46.88 118.63 36.2 98.93 33.34 92.05C30.48 85.17 23.42 64.38 32.44 41.83C41.46 19.28 58.78 1.5 86.3 1.5C113.82 1.5 136.07 17.74 144.64 45.71C153.21 73.68 142.53 91.41 138.64 100.71C134.75 110.01 125.84 136.82 123 151.26C121.33 159.68 120.33 179.26 119.79 194.04C119.66 197.08 119.57 199.93 119.49 202.43Z"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M1.5 253.29V252.97V229.27"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M52.6299 107.92C52.6299 107.92 66.3399 117.76 89.7599 117.76C113.18 117.76 123.29 109.86 123.29 109.86"
        stroke="#858180"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2694_2103">
        <rect width="174.55" height="291.98" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Tampers
