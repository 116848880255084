import React from "react"

const DoubleBoiler = ({ classes = "w-[3.0625rem] h-[3.5rem]" }) => (
  <svg className={classes} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g stroke="currentColor" strokeMiterlimit="10">
      <path d="M39.214 6.362H9.644l.58-6.152h28.408l.582 6.152ZM43.992 9.11a1.164 1.164 0 0 0-1.16-1.16h-36.7a1.164 1.164 0 0 0-1.16 1.16v38.92h39.02V9.11ZM9.616 51.069v4.367s.928.365 2.474.365c1.546 0 2.443-.41 2.443-.41V51.07H9.616ZM34.62 51.069v4.367s.928.365 2.474.365c1.547 0 2.444-.41 2.444-.41V51.07H34.62Z" />
      <path d="M43.173 45.3H5.846v5.845h37.327V45.3Z" />
      <path d="m42.241 41.615.932 3.685H5.846l.796-3.685H42.24ZM40.83 8.871H7.87v32.732h32.96V8.87Z" />
      <path d="M11.669 10.244a.62.62 0 0 1 .93 0 1.177 1.177 0 0 0 1.124.302.62.62 0 0 1 .805.465 1.177 1.177 0 0 0 .819.819.62.62 0 0 1 .465.805 1.178 1.178 0 0 0 .302 1.123.621.621 0 0 1 0 .931 1.177 1.177 0 0 0-.302 1.123.62.62 0 0 1-.465.806 1.176 1.176 0 0 0-.819.818.62.62 0 0 1-.805.466 1.178 1.178 0 0 0-1.123.301.62.62 0 0 1-.931 0 1.177 1.177 0 0 0-1.123-.301.62.62 0 0 1-.806-.466 1.176 1.176 0 0 0-.819-.818.62.62 0 0 1-.465-.806 1.176 1.176 0 0 0-.302-1.123.619.619 0 0 1 0-.93 1.176 1.176 0 0 0 .302-1.124.62.62 0 0 1 .465-.805 1.177 1.177 0 0 0 .82-.819.619.619 0 0 1 .805-.465 1.176 1.176 0 0 0 1.123-.302Z" />
      <path d="M12.137 16.807a2.58 2.58 0 1 0 0-5.158 2.58 2.58 0 0 0 0 5.158ZM35.285 10.475a.617.617 0 0 1 .904-.226 1.177 1.177 0 0 0 1.163.02.62.62 0 0 1 .893.258 1.177 1.177 0 0 0 .998.599.622.622 0 0 1 .647.668 1.176 1.176 0 0 0 .564 1.017.62.62 0 0 1 .225.904 1.177 1.177 0 0 0-.02 1.162.62.62 0 0 1-.257.894 1.176 1.176 0 0 0-.6.998.618.618 0 0 1-.668.647 1.176 1.176 0 0 0-1.017.564.621.621 0 0 1-.905.226 1.177 1.177 0 0 0-1.163-.02.62.62 0 0 1-.893-.257 1.176 1.176 0 0 0-.998-.599.621.621 0 0 1-.647-.669 1.177 1.177 0 0 0-.564-1.017.621.621 0 0 1-.225-.903 1.177 1.177 0 0 0 .02-1.163.622.622 0 0 1 .257-.894 1.175 1.175 0 0 0 .6-.997.62.62 0 0 1 .668-.647 1.177 1.177 0 0 0 1.018-.565Z" />
      <path d="M37.324 16.73a2.58 2.58 0 1 0-1.248-5.005 2.58 2.58 0 0 0 1.248 5.005ZM1.541 34.274s.853.086 1.042-.137C2.48 32.158 1.475 20.9 1.423 19.28c-.052-1.62-.119-2.457.357-3.258.477-.8 1.485-1.296 2.184-1.467.699-.17 2.286-.273 2.286-.273s.393-.29.341-.716c-.052-.428-.528-.478-.528-.478s-1.774.307-2.543.495c-.82.23-1.558.684-2.133 1.312a4.681 4.681 0 0 0-1.092 2.968c.018 1.245 1.246 16.412 1.246 16.412Z" />
      <path d="M1.284 35.542s.102 1.149.125 1.273c.023.124.341.716.887.706.546-.011.74-.626.762-.83.022-.205 0-1.286 0-1.286l-.478-1.268-1.04.137-.256 1.268ZM5.692 12.658l-.127.896.273.965.57.562 1.068-.034.881-.785v-1.559l-.819-.819-1.296.047-.55.727ZM23.019 10.076h2.724M22.019 11.612s1.348.15 2.508.15a17.782 17.782 0 0 0 2.218-.15M40.548 14.356l.801.452 1.204.076.468-.767.163-1.305-.197-.614-.546-.333-1.194-.052-.724.606.025 1.937Z" />
      <path d="M47.288 26.87s1.228-7.58 1.344-8.137c.116-.557.16-1.854-.454-2.924-.614-1.07-1.376-1.637-2.331-1.944-.956-.308-3.06-.945-3.06-.945l-.284.956s3.082.955 3.65 1.183c.57.228 1.013.91 1.263 1.456.25.546.352 1.217.194 2.115-.158.899-1.342 8.087-1.342 8.087s.584.329 1.02.153Z" />
      <path d="M44.721 31.34c.267.266.605.45.973.529a2.039 2.039 0 0 0 1.52-.357c.204-.18.503-2.108.503-2.347a2.257 2.257 0 0 0-.255-.947c-.163-.29-.197-.997-.17-1.347.025-.35-1.04-.196-1.04-.196s-.188.724-.324.853c-.137.128-.674.606-.801 1.074-.127.468-.406 2.738-.406 2.738ZM40.83 6.346H7.87v2.525h32.96V6.346ZM9.326 6.335V2.72c0-.66.762-2.514.762-2.514h1.262s-.727 2.115-.772 2.388c-.045.273 0 3.742 0 3.742H9.326ZM39.511 6.335V2.72c0-.66-.761-2.514-.761-2.514H37.68s.726 2.115.771 2.388c.045.273 0 3.742 0 3.742h1.06ZM28.64 21.416h4.616v2.49H26.57v-2.49h2.07ZM16.287 38.385a3.776 3.776 0 1 0 0-7.553 3.776 3.776 0 0 0 0 7.553ZM10.007 33.153a.614.614 0 1 0 0-1.228.614.614 0 0 0 0 1.228ZM10.009 37.566a.842.842 0 1 0 0-1.685.842.842 0 0 0 0 1.685ZM32.529 38.385a3.776 3.776 0 1 0 0-7.553 3.776 3.776 0 0 0 0 7.553Z" />
      <path d="m27.813 21.29-.034-.215h.512c.091 0 .364.01.364-.399V15.57c0-1.115-1.91-3.958-1.91-3.958v-.99c-.229-.488-1.001-.545-1.001-.545v-1a3.925 3.925 0 0 0-1.365-.284c-.468.012-.93.108-1.365.284v1s-.773.057-1 .546v.99s-1.91 2.842-1.91 3.957v5.107c0 .41.272.399.364.399h.511l-.034.216s-2.377.488-2.377.818v3.082c0 .444 1.183.603 1.444.625.204.774.273 1.081.74 1.388.466.307 1.535.57 1.535.57s.034 3.548.057 3.707c.023.16.307.399.307.399l-.042 3.457s.113.33.318.42c0 .41.16.774.318.853v.591l.341.603v.683s.04.273.79.318c.751-.047.79-.318.79-.318v-.683l.342-.603v-.59c.16-.08.318-.444.318-.854.204-.091.318-.42.318-.42l-.045-3.457s.284-.239.307-.399c.023-.16.057-3.708.057-3.708s1.069-.262 1.535-.569c.467-.307.535-.614.74-1.388.262-.023 1.444-.181 1.444-.625V22.11c.007-.33-2.37-.819-2.37-.819Z" />
      <path d="M18.573 22.11s1.467.448 5.953.448c4.486 0 5.664-.449 5.664-.449M33.345 25.157h2.252v-4.128l-.33-.444H33.15v4.288l.194.284Z" />
      <path d="m34.62 28.206-.638-3.525s.171-.467.546-.478c.376-.01.649.433.649.433l.648 3.354-1.205.216Z" />
      <path d="M35.951 32.595c1.24-.147 1.525-1.467 1.444-1.934-.08-.467-1.456-2.719-1.456-2.719a3.554 3.554 0 0 0-.819-.045c-.262.046-.625.399-.625.399s-.239 2.365-.25 2.9a1.45 1.45 0 0 0 1.706 1.399ZM29.784 27.577l-2.738-.93a1.846 1.846 0 0 0-1.297 1.084c-.435.955.436 1.782.436 1.782l2.303 1.042 1.296-2.978Z" />
      <path d="M31.651 32.303s-2.234-.777-2.336-.879c-.103-.102-.802-.452-.948-1.254-.146-.803.026-1.74.769-2.278.742-.538 1.373-.512 1.595-.486.223.026 2.133.733 2.133.733" />
      <path d="M34.11 30.748c.215-1.173-.484-2.284-1.56-2.482-1.078-.198-2.126.593-2.341 1.766-.215 1.173.483 2.284 1.56 2.482 1.077.197 2.125-.593 2.34-1.766ZM35.929 1.945H12.772v2.092H35.93V1.945ZM24.382 19.335a.614.614 0 1 0 0-1.229.614.614 0 0 0 0 1.229ZM22.905 35.678c.41.182 2.49.25 2.929.08M20.018 25.818c1.774.284 7.461.5 8.735 0M22.019 11.612c1-.205 3.719-.387 4.726 0M37.919 44.038H11.374l.386-1.16h25.772l.387 1.16Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.092)" d="M0 0h48.816v56H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default DoubleBoiler
