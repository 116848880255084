import React, { FC, useContext, useEffect, useState } from "react"
import Ratings from "react-ratings-declarative"
import { GatsbyImage } from "gatsby-plugin-image"
import { GatsbyLinkProps, Link } from "gatsby"
import slugify from "@sindresorhus/slugify"
import { StoreContext } from "context/store-context"

export interface LinkStatusWrapperProps extends GatsbyLinkProps<any> {
  disabled?: boolean
}
export interface ProductProps {
  legacyResourceId: string
  title: string
  image: string
  rating?: number
  price?: number
  salePrice?: number
  reviews: number
  vim?: boolean
  images: any[]
  priceRangeV2: any
  handle: string
  onlineStoreUrl: string
  productType?: string
  tags?: string[]
  variants?: any[]
  inventoryAvailable: string
  inventoryPolicy: string
}
export interface ProductCardProps {
  product: ProductProps
}
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  currencyDisplay: "narrowSymbol",
  minimumFractionDigits: 2,
})
const ProductCardBw: FC<ProductCardProps> = ({ product }) => {
  const {
    images: [productImage],
    priceRangeV2,
    handle,
    productType,
    variants,
    legacyResourceId,
    inventoryAvailable,
    inventoryPolicy,
  } = product
  const [available, setAvailable] = useState(true)
  const [availableForSale] = useState(true)
  const [totalReviews, setTotalReviews] = useState(0)
  const [averageReviewScore, setAverageReviewScore] = useState(0)

  // This code is causing a new graphql query anytime a product card is visible.

  // useEffect(() => {
  //   let isSubscribed = true
  //   client.product.fetchByHandle(handle).then((product) => {
  //     if (isSubscribed && product) {
  //       setAvailableForSale(product.availableForSale)
  //     }
  //   })

  //   return () => {
  //     isSubscribed = false
  //   }
  // }, [])
  const { client } = React.useContext(StoreContext)

  useEffect(() => {
    let isSubscribed = true

    if (legacyResourceId) {
      fetch(
        `https://api.yotpo.com/products/${process.env.GATSBY_YOTPO_APP_KEY}/${legacyResourceId}/bottomline`
      )
        .then((res) => res.json())
        .then((data) => {
          if (isSubscribed && data?.response?.bottomline) {
            setAverageReviewScore(data.response.bottomline.average_score)
            setTotalReviews(data.response.bottomline.total_reviews)
          }
        })
        .catch((err) => {
          if (isSubscribed) {
            // console.warn(err)
          }
        })
      // Get product by handle
      client.product.fetchByHandle(handle).then((fetchedProduct) => {
        setAvailable(fetchedProduct.availableForSale)
      })

      return () => {
        isSubscribed = false
      }
    }
  }, [])

  let vim = product?.vim
  if (!vim && product?.tags) {
    vim = product.tags.includes("VIM")
  }

  let salePrice = product.salePrice
  let price = product.price
  if (!salePrice) {
    variants?.forEach((variant) => {
      if (variant.compareAtPrice) {
        price = variant.compareAtPrice
        salePrice = variant.price
      }
    })
  }

  const LinkStatusWrapper = (props: LinkStatusWrapperProps) => {
    if (!props.disabled) {
      return (
        <Link className={props.className} to={props.to}>
          {props.children}
        </Link>
      )
    } else if (props.disabled) {
      return (
        <div
          className={
            `${props.className}` + ` pointer-events-none cursor-not-allowed`
          }
        >
          {props.children}
        </div>
      )
    } else {
      return <></>
    }
  }

  return (
    <LinkStatusWrapper
      className="md:pl-2 md:pb-2 block w-full h-full text-left relative group md:hover:z-10 xs:max-w-[280px]"
      to={`/products/${handle}`}
      disabled={!inventoryAvailable && inventoryPolicy == "deny"}
    >
      <div className="relative z-10 duration-300 group-hover:duration-75 transition transform md:group-hover:scale-[1.025]">
        <div className="flex flex-row flex-shrink-0 gap-x-4 items-center w-full xs:flex-col">
          <div className="mb-4 shrink-0 relative xs:max-h-[280px]">
            {productImage ? (
              <GatsbyImage
                alt={productImage?.altText ?? product.title}
                image={productImage?.gatsbyImageData}
                className={`asbolute aspect-square inset-0 w-[128px] xs:w-full h-full duration-150 ${
                  !available ? "opacity-60 blur-sm group-hover:opacity-100" : ""
                }`}
                objectFit="contain"
                loading={"eager"}
              />
            ) : null}

            {!productImage && product.image ? (
              <>
                <img
                  className={`w-[128px] xs:w-[228px] inset-0 h-[128px] xs:h-[228px] min-h-full aspect-square object-contain ${
                    !inventoryAvailable && inventoryPolicy == "deny"
                      ? "group-hover:opacity-100 blur-sm"
                      : ""
                  }`}
                  src={`${product.image}&width=420&height=420`}
                  alt={product.title}
                />

                {!inventoryAvailable && inventoryPolicy == "deny" ? (
                  <div className="flex absolute inset-0 justify-center items-center">
                    <div className="px-2 py-2 text-sm font-bold text-white uppercase rounded border-2 -rotate-12 bg-idc-red-500 bg-idc-border-orange border-idc-white-transparent-2">
                      Sold
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}

            {vim ? (
              <div className="absolute top-0 right-0 rounded backdrop-blur-sm">
                <div className="flex items-center px-2 py-1 rounded bg-idc-vim-light">
                  <svg
                    id="Layer_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 22.24 20.18"
                    xmlSpace="preserve"
                    width={22.24}
                    height={20.18}
                  >
                    <defs id="defs21" />

                    <style type="text/css" id="style2">
                      {".st0{fill:#FFFFFF;} .st1{fill:#F15B28;}"}
                    </style>
                    <g
                      id="g16"
                      transform="matrix(0.12912216,0,0,0.12912216,-28.341889,-41.11018)"
                    >
                      <g id="g8">
                        <rect
                          x={253.50999}
                          y={441.01999}
                          className="st0"
                          width={104.98}
                          height={29.549999}
                          id="rect4"
                        />
                        <path
                          className="st1"
                          d="m 354.49,445.02 v 21.55 h -96.98 v -21.55 h 96.98 m 8,-8 h -8 -96.98 -8 v 8 21.55 8 h 8 96.98 8 v -8 -21.55 z"
                          id="path6"
                        />
                      </g>
                      <g id="g14">
                        <path
                          className="st0"
                          d="m 253.09,441.58 -17,-54.01 c -6.86,-0.77 -12.21,-6.6 -12.21,-13.65 0,-7.57 6.17,-13.73 13.76,-13.74 7.59,0 13.77,6.16 13.77,13.74 0,2.03 -0.46,4.02 -1.32,5.83 10.46,8.68 14.9,9.4 16.07,9.4 0.15,0 0.27,-0.01 0.38,-0.03 7.3,-1.34 18.88,-17.56 30.51,-42.63 -3.01,-2.57 -4.82,-6.36 -4.82,-10.44 0,-7.58 6.17,-13.74 13.77,-13.74 7.6,0 13.77,6.16 13.77,13.74 0,4.1 -1.82,7.89 -4.84,10.46 11.65,25.21 23.24,41.53 30.53,42.86 0.1,0.02 0.23,0.03 0.37,0.03 1.17,0 5.6,-0.73 16.12,-9.57 -0.88,-1.83 -1.35,-3.84 -1.35,-5.9 0,-7.39 5.88,-13.44 13.22,-13.73 l 0.04,-0.01 h 0.51 c 7.59,0 13.76,6.17 13.76,13.74 0,7.06 -5.36,12.89 -12.23,13.65 l -16.99,54.01 H 253.09 Z"
                          id="path10"
                        />
                        <path
                          className="st1"
                          d="m 306,326.3 c 5.39,0 9.77,4.36 9.77,9.74 0,4.01 -2.44,7.46 -5.92,8.95 6.97,15.51 22.15,45.97 34.89,48.3 0.35,0.06 0.71,0.09 1.09,0.09 5.64,0 14.58,-6.81 21.41,-12.86 -1.63,-1.74 -2.64,-4.05 -2.64,-6.62 0,-5.37 4.37,-9.74 9.77,-9.74 v 0 c 5.39,0 9.77,4.36 9.77,9.74 0,5.37 -4.37,9.74 -9.77,9.74 -0.47,0 -0.92,-0.07 -1.38,-0.14 l -17,54.06 H 256.04 L 239.03,383.5 c -0.45,0.06 -0.9,0.13 -1.37,0.13 -5.39,0 -9.77,-4.36 -9.77,-9.74 0,-5.38 4.37,-9.74 9.77,-9.74 5.39,0 9.77,4.36 9.77,9.74 0,2.55 -1,4.84 -2.61,6.58 6.82,5.96 15.73,12.65 21.36,12.65 0.38,0 0.75,-0.03 1.1,-0.1 12.73,-2.33 27.91,-32.61 34.88,-48.06 -3.47,-1.5 -5.91,-4.93 -5.91,-8.94 -0.02,-5.36 4.35,-9.72 9.75,-9.72 m 0,-8 c -9.8,0 -17.77,7.96 -17.77,17.74 0,4.19 1.47,8.14 4.03,11.25 -13.2,27.83 -22.68,36.88 -26.21,37.83 -0.62,-0.11 -3.88,-0.93 -11.23,-6.7 0.39,-1.46 0.59,-2.97 0.59,-4.51 0,-9.78 -7.97,-17.74 -17.77,-17.74 -9.8,0.01 -17.77,7.97 -17.77,17.74 0,8.17 5.56,15.07 13.11,17.12 l 15.4,48.94 1.76,5.6 h 5.87 99.95 5.87 l 1.76,-5.6 15.39,-48.94 c 7.55,-2.04 13.13,-8.95 13.13,-17.12 0,-9.78 -7.97,-17.74 -17.77,-17.74 h -1.62 l -0.23,0.1 c -8.93,0.93 -15.92,8.48 -15.92,17.64 0,1.57 0.21,3.12 0.61,4.61 -7.29,5.81 -10.59,6.71 -11.27,6.84 -3.54,-0.98 -13.01,-10.1 -26.21,-38.05 2.56,-3.11 4.04,-7.07 4.04,-11.27 0.02,-9.78 -7.95,-17.74 -17.74,-17.74 z"
                          id="path12"
                        />
                      </g>
                    </g>
                  </svg>
                  <p className="ml-1 text-sm font-bold text-idc-vim">VIM</p>
                </div>
              </div>
            ) : null}
          </div>
          <div>
            {!available && inventoryPolicy == "deny" ? (
              <p className="mb-1 text-sm font-medium text-center uppercase duration-150 text-idc-subtle group-hover:text-idc-orange">
                Out Of Stock
              </p>
            ) : (
              <p className="mb-1 text-sm font-bold text-center uppercase duration-150 text-idc-orange group-hover:text-idc-orange">
                1 Available
              </p>
            )}
            <h3 className="font-bold text-sm md:text-lg md:leading-[1.375rem] text-idc-title group-hover:text-idc-orange duration-150 mb-2 m-0 text-left text-clip">
              {product.title}
            </h3>
            {totalReviews > 0 ? (
              <div className="flex flex-row items-end mb-3">
                <div className="flex flex-col justify-center">
                  <Ratings
                    rating={averageReviewScore}
                    widgetDimensions="10px"
                    widgetSpacings="0.5px"
                    widgetRatedColors="#F7C069"
                    widgetEmptyColors="#968E8C"
                  >
                    <Ratings.Widget />
                    <Ratings.Widget />
                    <Ratings.Widget />
                    <Ratings.Widget />
                    <Ratings.Widget />
                  </Ratings>
                </div>
                <div className="flex flex-col justify-center">
                  <p className="m-0 mr-3 ml-1 text-xs font-medium text-idc-subtle">
                    {averageReviewScore}
                  </p>
                </div>
                <div className="flex flex-col justify-center">
                  <p className="hidden m-0 text-xs font-medium sm:block text-idc-subtle">
                    {totalReviews} Review{`${totalReviews !== 1 ? "s" : ""}`}
                  </p>
                  <p className="m-0 text-xs font-medium sm:hidden text-idc-subtle">
                    ({totalReviews})
                  </p>
                </div>
              </div>
            ) : null}
            {price > salePrice ? (
              <div>
                <div className="flex flex-row justify-start">
                  <div className="inline-block px-2 py-1 my-1 mt-2 rounded-sm bg-idc-orange">
                    <p className="m-0 text-xs font-bold text-white">
                      {formatter.format(Math.round(price - salePrice))} Off
                    </p>
                  </div>
                </div>

                <div className="flex flex-row">
                  <p className="m-0 text-2xl font-medium text-idc-orange">
                    {formatter.format(salePrice)}
                  </p>
                  <div className="flex invisible flex-row flex-col justify-end pb-1 ml-2 md:visible">
                    <p className="m-0 text-xs line-through text-idc-subtle">
                      {formatter.format(price)}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <p className="m-0 text-2xl font-normal">
                  {formatter.format(priceRangeV2.minVariantPrice.amount)}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="absolute inset-0 block -m-5 rounded-lg bg-white shadow-xl opacity-0 duration-300 group-hover:duration-75 transition transform scale-95 group-hover:scale-[1.025] group-hover:opacity-100"></div>
    </LinkStatusWrapper>
  )
}

export default ProductCardBw
