import React from "react"

const WaterFilters = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    viewBox="0 0 134 387"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2694_2185)">
      <path
        d="M88.89 366.8L87.63 379.09C87.63 379.09 85.55 385.25 65.79 385.25C46.03 385.25 43.43 379 43.43 379L42.33 366.36C43.33 368.01 48.12 373.83 66.53 373.83C83.77 373.84 87.93 368.8 88.89 366.8Z"
        fill="white"
      />
      <path
        d="M88.89 366.8C87.89 368.8 83.77 373.8 66.56 373.8C48.15 373.8 43.35 367.98 42.36 366.33C42.2704 366.194 42.1934 366.05 42.13 365.9L40.32 340.3C46.21 341.41 56.74 343.12 65.91 343.12H66.36C74.3163 342.966 82.2566 342.345 90.14 341.26L91.44 341.38L89.18 366C89.1195 366.278 89.022 366.548 88.89 366.8V366.8Z"
        fill="white"
      />
      <path
        d="M15.36 39.67C15.36 42.67 38.46 46.03 66.95 46.03C95.09 46.03 117.95 42.76 118.52 39.78C122.78 46.59 126.62 63.65 126.62 63.65H126.88C130.36 64.65 132.28 65.76 132.28 66.93C132.285 66.9799 132.285 67.0302 132.28 67.08C131.64 71.56 102.64 75.16 66.96 75.16C32.06 75.16 3.55 71.72 1.72 67.37C1.66565 67.2534 1.63182 67.1282 1.62 67C1.62 65.81 3.62 64.69 7.2 63.67C7.37 62.9 11.18 46.26 15.36 39.67Z"
        fill="white"
      />
      <path
        d="M126.76 85.0601L126.84 85.3401C126.84 85.3401 119.06 341.05 118.28 365C111.8 373.68 97.67 373.43 97.67 373.43L96.12 340.4C96.12 340.4 93.79 340.78 90.12 341.26C82.2366 342.345 74.2962 342.966 66.34 343.12V90.0001H67C93.67 90.0001 116.64 88.0001 126.76 85.0601Z"
        fill="white"
      />
      <path
        d="M65.92 343.12C56.75 343.12 46.22 341.41 40.33 340.3C37.48 339.76 35.71 339.36 35.71 339.36C35.71 339.36 34.03 354.27 32.71 373.2C18.32 373.2 12.71 364.9 12.71 364.9L5.89999 84.91L6.07999 84.39C15.41 87.48 38.28 89.94 66.37 89.97V343.12H65.92Z"
        fill="white"
      />
      <path
        d="M118.54 39.67C118.549 39.7061 118.549 39.7439 118.54 39.78C118 42.76 95.09 46 67 46C38.51 46 15.41 42.65 15.41 39.64C15.41 38.05 21.94 36.64 32.33 35.64V35.71C32.7 35.82 40.33 39.08 67.44 39.08C94.55 39.08 101.16 35.71 101.16 35.71V35.6C111.8 36.59 118.54 38.05 118.54 39.67Z"
        fill="white"
      />
      <path
        d="M132.32 67.12C132.32 67.12 132.58 79.21 132.38 81.54L132.28 81.74C132.28 82.92 130.28 84.04 126.76 85.06C116.64 88 93.67 90 67 90H66.42C38.33 90 15.42 87.51 6.13 84.42C3.51 83.55 2 82.65 1.62 81.74C1.24 80.83 1.67 69 1.71 67.4C3.54 71.75 32.05 75.19 67 75.19C102.68 75.19 131.68 71.59 132.32 67.11V67.12Z"
        fill="white"
      />
      <path
        d="M90.28 31.19C90.17 29.82 89.28 18.8 88.9 17.34L90.28 31.19ZM78 32.89C78 32.89 77.84 20 77.41 18L78 32.89ZM43.28 31.19L44.65 17.34C44.26 18.8 43.4 29.82 43.29 31.19H43.28ZM55.15 32.89L55.76 18C55.33 20 55.16 32.89 55.16 32.89H55.15ZM32.28 35.65C31.64 21.35 35.91 6.94 35.91 6.94C35.91 9.94 49.81 12.39 66.91 12.39C84.01 12.39 98 10 98 6.94C98.0144 6.83382 98.0144 6.72618 98 6.62V6.62C101 13.62 101.12 33.85 101.13 35.62C92 34.75 80.05 34.23 67 34.23C53.6 34.23 41.44 34.77 32.28 35.65Z"
        fill="white"
      />
      <path
        d="M98 6.62C69.17 6.62 36 6.94 36 6.94C36 3.94 49.9 1.5 67 1.5C83.49 1.5 97 3.77 98 6.62Z"
        fill="white"
      />
      <path
        d="M98 6.94C98 9.94 84.1 12.39 67 12.39C49.9 12.39 36 9.95 36 6.94C36 6.94 69.21 6.62 98 6.62C98.0144 6.72618 98.0144 6.83382 98 6.94V6.94Z"
        fill="white"
      />
      <path
        d="M90.28 31.19L88.9 17.34C89.3 18.8 90.17 29.82 90.28 31.19Z"
        fill="white"
      />
      <path d="M78 32.89L77.41 18C77.84 20 78 32.89 78 32.89Z" fill="white" />
      <path
        d="M67.39 39.09C40.29 39.09 32.65 35.83 32.28 35.72V35.65C41.44 34.77 53.6 34.23 66.95 34.23C80.05 34.23 92.01 34.75 101.11 35.6V35.72C101.11 35.72 94.48 39.09 67.39 39.09Z"
        fill="white"
      />
      <path
        d="M55.76 18L55.16 32.86C55.16 32.86 55.33 20 55.76 18Z"
        fill="white"
      />
      <path
        d="M44.66 17.34L43.29 31.19C43.4 29.82 44.26 18.8 44.66 17.34Z"
        fill="white"
      />
      <path
        d="M101.11 35.6C111.8 36.6 118.54 38.05 118.54 39.67C118.549 39.7061 118.549 39.7439 118.54 39.78C118 42.76 95.09 46 67 46C38.51 46 15.41 42.65 15.41 39.64C15.41 38.05 21.94 36.64 32.33 35.64"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M126.88 63.68C130.36 64.68 132.28 65.79 132.28 66.96C132.285 67.0098 132.285 67.0601 132.28 67.11C131.64 71.59 102.64 75.19 66.96 75.19C32.06 75.19 3.55 71.75 1.72 67.4C1.6622 67.274 1.62829 67.1383 1.62 67C1.62 65.81 3.62 64.69 7.2 63.67L7.48 63.59"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.20001 63.65C7.37001 62.92 11.2 46.28 15.36 39.65"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M126.62 63.65C126.62 63.65 122.78 46.59 118.52 39.78L118.46 39.67"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.89999 84.91L12.77 364.91C12.77 364.91 18.34 373.21 32.77 373.21C34.07 354.28 35.77 339.37 35.77 339.37C35.77 339.37 37.54 339.77 40.39 340.31C46.28 341.42 56.81 343.13 65.98 343.13H66.43C74.3862 342.976 82.3266 342.355 90.21 341.27C93.85 340.79 96.21 340.41 96.21 340.41L97.76 373.47C97.76 373.47 111.89 373.72 118.37 365.04C119.15 341.04 126.93 85.35 126.93 85.35"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.72001 67.22C1.72001 67.22 1.72001 67.28 1.72001 67.4C1.67001 69 1.32001 81 1.62001 81.74C1.92001 82.48 3.51001 83.55 6.08001 84.39C15.41 87.48 38.28 89.94 66.37 89.97H67C93.72 89.97 116.69 87.97 126.81 85.06C130.36 84.06 132.33 82.92 132.33 81.74"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.32 67.12C132.32 67.12 132.58 79.21 132.38 81.54"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.32 340.34L42.13 365.94C42.1934 366.09 42.2704 366.234 42.36 366.37C43.36 368.02 48.15 373.84 66.56 373.84C83.77 373.84 87.93 368.84 88.89 366.84C89.022 366.587 89.1195 366.318 89.18 366.04L91.45 341.42"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.33 366.39L43.43 379C43.43 379 46.02 385.22 65.79 385.22C85.56 385.22 87.63 379.06 87.63 379.06L88.89 366.77V366.49"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.91 6.93998C35.91 6.93998 31.64 21.35 32.28 35.65V35.72C32.65 35.83 40.28 39.09 67.39 39.09C94.5 39.09 101.11 35.72 101.11 35.72V35.6C101.11 33.85 100.95 13.6 97.98 6.59998"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98 6.62C98.0144 6.72618 98.0144 6.83382 98 6.94C98 9.94 84.1 12.39 67 12.39C49.9 12.39 36 9.95 36 6.94C36 3.93 49.81 1.5 67 1.5C83.49 1.5 97 3.77 98 6.62Z"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.31 18.29V33.45"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.41 18C77.84 20 78.01 32.86 78.01 32.86"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.76 18C55.33 20 55.16 32.86 55.16 32.86"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.9 17.34C89.3 18.8 90.17 29.82 90.28 31.19"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.29 31.3299L90.28 31.1899"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.66 17.34C44.26 18.8 43.4 29.82 43.29 31.19"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.28 31.3299L43.29 31.1899"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.37 90.62V342.86"
        stroke="#858180"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2694_2185">
        <rect width="133.96" height="386.75" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default WaterFilters
