import React, { FC, Key } from "react"
import { GatsbyLinkProps, graphql } from "gatsby"
import { Seo } from "components/seo"
import { getSrc } from "gatsby-plugin-image"
import slugify from "@sindresorhus/slugify"
import qs from "qs"
import useDebounce from "utils/useDebounce"
import StickyBox from "react-sticky-box"
import { Disclosure } from "@headlessui/react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  RefinementList,
  SortBy,
  Pagination as PaginationComponent,
  ClearRefinements,
  Configure,
  Hits,
  HitsPerPage,
  Panel,
  NumericMenu,
  ToggleRefinement,
  Stats,
  connectStateResults,
  connectRefinementList,
} from "react-instantsearch-dom"

import { Layout } from "components/layouts/Layout/layout"
import ProductCard from "components/elements/ProductCard"
import ProductCardBw from "components/elements/ProductCardBw"
import SingleBoiler from "assets/icons/singleBoiler"
import DoubleBoiler from "assets/icons/doubleBoiler"
import HeatExchanger from "assets/icons/heatExchanger"
import SuperAutomatic from "assets/icons/superAutomatic"
import Lever from "assets/icons/lever"

import AeropressCoffeeMaker from "assets/icons/types/aeropress-coffee-maker"
import AutomaticDrip from "assets/icons/types/automatic-drip"
import BeanStorage from "assets/icons/types/bean-storage"
import Blenders from "assets/icons/types/blenders"
import Chemex from "assets/icons/types/chemex"
import CleaningDescaling from "assets/icons/types/cleaning-and-descaling"
import CoffeeRoasters from "assets/icons/types/coffee-roasters"
import ColdBrew from "assets/icons/types/cold-brew"
import DripCoffeeGrinders from "assets/icons/types/drip-coffee-grinders"
import DualPurposeGrinders from "assets/icons/types/dual-purpose-grinders"
import ElectricMilkFrothers from "assets/icons/types/electric-milk-frothers"
import EspressoGrinders from "assets/icons/types/espresso-grinders"
import FrenchPress from "assets/icons/types/french-press"
import FrothingPitchers from "assets/icons/types/frothing-pitchers"
import Glassware from "assets/icons/types/glassware"
import HandGrinders from "assets/icons/types/hand-grinders"
import Juicers from "assets/icons/types/juicers"
import KnockBoxes from "assets/icons/types/knock-boxes"
import ManualDripPourOver from "assets/icons/types/manual-drip-pour-over"
import Tampers from "assets/icons/types/tampers"
import TampingStands from "assets/icons/types/tamping-stands"
import TeaKettles from "assets/icons/types/tea-kettles"
import TeaPots from "assets/icons/types/tea-pots"
import TravelMugs from "assets/icons/types/travel-mugs"
import Vacuum from "assets/icons/types/vacuum"
import WaterFilters from "assets/icons/types/water-filters"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const createUrlParams = () => {
  const params = {}
  return `${qs.stringify(params)}`
}

const searchStateToUrl = ({ location }, searchState) =>
  searchState ? `${location.pathname}${createUrlParams(searchState)}` : ""

const urlToSearchState = ({ search }) => qs.parse(search.slice(1))

const DEBOUNCE_TIME = 250

const Results = connectStateResults(({ searchState }) =>
  searchState.page || searchState.refinementList ? (
    <Hits hitComponent={Hit} />
  ) : (
    <div>
      <p>No results found</p>
    </div>
  )
)

function Hit({ hit }) {
  const priceRangeV2 = {
    minVariantPrice: {
      amount: hit.price,
    },
  }

  const images = []

  const product = {
    title: hit.title,
    image: hit.image,
    vim: hit.tags.includes("VIM"),
    price: hit.compare_at_price,
    // salePrice: hit.salePrice,
    reviews: 0,
    images: images,
    priceRangeV2: priceRangeV2,
    salePrice: hit.price,
    handle: hit.handle,
    productType: hit.product_type,
    meta: hit.meta,
    inventoryPolicy: hit.inventory_policy,
    legacyResourceId: hit.id,
    inventoryManagement: hit.inventory_management,
    inventoryAvailable: hit.inventory_available,
    inventoryQuantity: hit.inventory_quantity,
    variantTitle: hit.variant_title,
    id: hit.id,
    sku: hit.sku,
    reviewsAverage: hit?.meta?.yotpo?.reviews_average,
    reviewsCount: hit?.meta?.yotpo?.reviews_count,
  }

  // for boxing week special listing page
  const isBoxingWeek = location.pathname.includes("boxing-week")

  if (isBoxingWeek) {
    return <ProductCardBw product={product} />
  } else {
    return <ProductCard product={product} />
  }
}

const FilterTypes = [
  {
    tag: "Super-Automatic",
    label: "Super Automatic",
    icon: <SuperAutomatic classes={`w-[2.9375rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "icon-Single-Boiler",
    label: "Single Boiler",
    icon: <SingleBoiler classes={`w-[2.15rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "NC_DualBoiler",
    label: "Dual Boiler",
    icon: <DoubleBoiler classes={`w-[3.0625rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "lever",
    label: "Lever",
    icon: <Lever classes={`w-8 h-[3.5rem] mb-2`} />,
  },
  {
    tag: "heat-exchanger",
    label: "Heat Exchanger",
    icon: <HeatExchanger classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "automatic-drip",
    label: "Automatic Drip",
    icon: <AutomaticDrip classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "manual-drip",
    label: "Manual Drip",
    icon: <ManualDripPourOver classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "pour-over",
    label: "Pourover",
    icon: <ManualDripPourOver classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "vacuum",
    label: "Vacuum",
    icon: <Vacuum classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "french-press",
    label: "French Press",
    icon: <FrenchPress classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "aeropress",
    label: "Aeropress",
    icon: <AeropressCoffeeMaker classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "cold-brew",
    label: "Cold Brew",
    icon: <ColdBrew classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "chemex",
    label: "Chemex",
    icon: <Chemex classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "drip-grinder",
    label: "Drip Coffee Grinder",
    icon: <DripCoffeeGrinders classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "espresso-grinder",
    label: "Espresso Grinder",
    icon: <EspressoGrinders classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "dual-purpose-grinder",
    label: "Dual Purpose Grinders",
    icon: <DualPurposeGrinders classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "hand-grinder",
    label: "Hand Grinders",
    icon: <HandGrinders classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "bean-storage",
    label: "Bean Storage",
    icon: <BeanStorage classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "cleaning-descaling",
    label: "Cleaning and Descaling",
    icon: <CleaningDescaling classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "coffee-roaster",
    label: "Coffee Roasters",
    icon: <CoffeeRoasters classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "electric-milk",
    label: "Electric Milk Frothers",
    icon: <ElectricMilkFrothers classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "frothing-pitcher",
    label: "Frothing Pitchers",
    icon: <FrothingPitchers classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "glassware",
    label: "Glassware",
    icon: <Glassware classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "knock-box",
    label: "Knock Boxes",
    icon: <KnockBoxes classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "tamper",
    label: "Tampers",
    icon: <Tampers classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "tamper-stand",
    label: "Tamping Stands",
    icon: <TampingStands classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "travel-mugs",
    label: "Travel Mugs",
    icon: <TravelMugs classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "water-filter",
    label: "Water Filters",
    icon: <WaterFilters classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "blender",
    label: "Blenders",
    icon: <Blenders classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "juicer",
    label: "Juicers",
    icon: <Juicers classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "tea-kettle",
    label: "Tea Kettles",
    icon: <TeaKettles classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "tea-pot",
    label: "Tea Pots",
    icon: <TeaPots classes={`w-[3.125rem] h-[3.5rem] mb-2`} />,
  },
  {
    tag: "origin-brazil",
    label: "Brazil 🇧🇷",
  },
  {
    tag: "origin-colombia",
    label: "Colombia 🇨🇴",
  },
  {
    tag: "origin-burundi",
    label: "Burundi 🇧🇮",
  },
  {
    tag: "origin-costa-rica",
    label: "Costa Rica 🇨🇷",
  },
  {
    tag: "origin-ecuador",
    label: "Ecuador 🇪🇨",
  },
  {
    tag: "origin-guatemala",
    label: "Guatemala 🇬🇹",
  },
  {
    tag: "origin-haiti",
    label: "Haiti 🇭🇹",
  },
  {
    tag: "origin-hawaii",
    label: "Hawaii 🇺🇸",
  },
  {
    tag: "origin-india",
    label: "India 🇮🇳",
  },
  {
    tag: "origin-jamaica",
    label: "Jamaica 🇯🇲",
  },
  {
    tag: "origin-kenya",
    label: "Kenya 🇰🇪",
  },
  {
    tag: "origin-papua-new-guinea",
    label: "Papua New Guinea 🇵🇬",
  },
  {
    tag: "origin-indonesia",
    label: "indonesia 🇮🇩",
  },
  {
    tag: "origin-mexico",
    label: "Mexico 🇲🇽",
  },
  {
    tag: "origin-tanzania",
    label: "Tanzania 🇹🇿",
  },
  {
    tag: "origin-rwanda",
    label: "Rwanda 🇷🇼",
  },
  {
    tag: "origin-congo",
    label: "Democratic Republic of the Congo 🇨🇩",
  },
  {
    tag: "origin-peru",
    label: "Peru 🇵🇪",
  },
  {
    tag: "origin-nicaragua",
    label: "Nicaragua 🇳🇮",
  },
  {
    tag: "origin-uganda",
    label: "Uganda 🇺🇬",
  },
  {
    tag: "black",
    label: "Black",
  },
  {
    tag: "Green",
    label: "Green",
  },
  {
    tag: "White",
    label: "White",
  },
  {
    tag: "Oolong",
    label: "Oolong",
  },
  {
    tag: "pu-ehr",
    label: "Pu Ehr",
  },
  {
    tag: "Rooibos",
    label: "Rooibos",
  },
  {
    tag: "decaffeinated",
    label: "Decaffeinated",
  },
  {
    tag: "flavoured",
    label: "Flavoured",
  },
  {
    tag: "Fruit",
    label: "Fruit",
  },
  {
    tag: "herbal-tisanes",
    label: "Herbal",
  },
  {
    tag: "flowering",
    label: "Flowering",
  },
]

const MachineTypeRefinementList = ({ items, refine }) => {
  return (
    <ul className="grid grid-cols-2 gap-2">
      {items.map(
        (item: { id: Key | null | undefined; label: string; icon: string }) => {
          let label = ""
          let icon = ""

          FilterTypes.forEach((type) => {
            if (type.tag === item.label) {
              label = type.label
              icon = type.icon
            }
          })

          if (label) {
            return (
              <li className="filter-list" key={item.label}>
                <button
                  className={`cursor-pointer text-xs leading-5 flex flex-col justify-center items-center rounded w-full h-full p-2 text-center border hover:border-idc-blue ${
                    item.isRefined
                      ? "border-idc-blue bg-idc-light-blue text-idc-blue"
                      : "bg-white"
                  }`}
                  onClick={(event) => {
                    event.preventDefault()
                    refine(item.value)
                  }}
                >
                  <span
                    className={`${
                      item.isRefined ? "text-idc-blue" : "text-idc-icon"
                    }`}
                  >
                    {icon}
                  </span>
                  <span>{label}</span>
                </button>
              </li>
            )
          }
        }
      )}
    </ul>
  )
}

const MachineTypeRefinementListComponent = connectRefinementList(
  MachineTypeRefinementList
)

const Filters = () => {
  return (
    <div className="p-6 pt-8 rounded-md lg:p-8 bg-idc-light">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-bold leading-7 text-idc-title">Filters</h2>
        <ClearRefinements
          clearsQuery
          className="text-sm font-medium leading-tight uppercase text-idc-orange"
        />
      </div>
      <div className="space-y-4">
        <Panel>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full">
                  <h3 className="flex items-center justify-between w-full ais-Panel-header group">
                    <span>Type</span>
                    <svg
                      className={`text-idc-icon group-hover:text-idc-orange fill-current ${
                        open ? "" : "transform rotate-180"
                      }`}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.628357 7.2053L1.37165 7.87427L7 1.62054L12.6284 7.87427L13.3717 7.2053L7 0.125696L0.628357 7.2053Z"
                      />
                    </svg>
                  </h3>
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel
                    unmount={false}
                    className={`${open ? "mb-6" : ""}`}
                  >
                    <MachineTypeRefinementListComponent
                      attribute="tags"
                      limit={5000}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </Panel>
        <Panel>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full">
                  <h3 className="flex items-center justify-between w-full ais-Panel-header group">
                    <span>Brands</span>
                    <svg
                      className={`text-idc-icon group-hover:text-idc-orange fill-current ${
                        open ? "" : "transform rotate-180"
                      }`}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.628357 7.2053L1.37165 7.87427L7 1.62054L12.6284 7.87427L13.3717 7.2053L7 0.125696L0.628357 7.2053Z"
                      />
                    </svg>
                  </h3>
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel
                    unmount={false}
                    className={`${open ? "mb-6" : ""}`}
                  >
                    <RefinementList
                      attribute="vendor"
                      operator="and"
                      showMore
                      limit={12}
                      showMoreLimit={300}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </Panel>
        <Panel>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full">
                  <h3 className="flex items-center justify-between w-full ais-Panel-header group">
                    <span>Price</span>
                    <svg
                      className={`text-idc-icon group-hover:text-idc-orange fill-current ${
                        open ? "" : "transform rotate-180"
                      }`}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.628357 7.2053L1.37165 7.87427L7 1.62054L12.6284 7.87427L13.3717 7.2053L7 0.125696L0.628357 7.2053Z"
                      />
                    </svg>
                  </h3>
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel
                    unmount={false}
                    className={`${open ? "mb-6" : ""}`}
                  >
                    <NumericMenu
                      attribute="price"
                      items={[
                        { label: "All" },
                        { label: "Under $100", end: 100 },
                        { label: "$100 - $500", start: 100, end: 500 },
                        { label: "$500 - $1,000", start: 500, end: 1000 },
                        { label: "$1,000 - $2,500", start: 1000, end: 2500 },
                        { label: "$2,500 & Above", start: 2500 },
                      ]}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </Panel>
        <Panel>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full">
                  <h3 className="flex items-center justify-between w-full ais-Panel-header group">
                    <span>Colour</span>
                    <svg
                      className={`text-idc-icon group-hover:text-idc-orange fill-current ${
                        open ? "" : "transform rotate-180"
                      }`}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.628357 7.2053L1.37165 7.87427L7 1.62054L12.6284 7.87427L13.3717 7.2053L7 0.125696L0.628357 7.2053Z"
                      />
                    </svg>
                  </h3>
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel
                    unmount={false}
                    className={`${open ? "mb-6" : ""}`}
                  >
                    <RefinementList
                      attribute="options.colour"
                      operator="and"
                      showMore
                      limit={10}
                      showMoreLimit={50}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </Panel>
        <Panel>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full">
                  <h3 className="flex items-center justify-between w-full ais-Panel-header group">
                    <span>Size</span>
                    <svg
                      className={`text-idc-icon group-hover:text-idc-orange fill-current ${
                        open ? "" : "transform rotate-180"
                      }`}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.628357 7.2053L1.37165 7.87427L7 1.62054L12.6284 7.87427L13.3717 7.2053L7 0.125696L0.628357 7.2053Z"
                      />
                    </svg>
                  </h3>
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel
                    unmount={false}
                    className={`${open ? "mb-6" : ""}`}
                  >
                    <RefinementList
                      attribute="options.size"
                      operator="and"
                      showMore
                      limit={10}
                      showMoreLimit={50}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </Panel>
        <Panel>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full">
                  <h3 className="flex items-center justify-between w-full ais-Panel-header group">
                    <span>Portafilter</span>
                    <svg
                      className={`text-idc-icon group-hover:text-idc-orange fill-current ${
                        open ? "" : "transform rotate-180"
                      }`}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.628357 7.2053L1.37165 7.87427L7 1.62054L12.6284 7.87427L13.3717 7.2053L7 0.125696L0.628357 7.2053Z"
                      />
                    </svg>
                  </h3>
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel
                    unmount={false}
                    className={`${open ? "mb-6" : ""}`}
                  >
                    <RefinementList
                      attribute="options.portafilter"
                      operator="and"
                      showMore
                      limit={10}
                      showMoreLimit={50}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </Panel>
        <Panel>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full">
                  <h3 className="flex items-center justify-between w-full ais-Panel-header group">
                    <span>Base Size</span>
                    <svg
                      className={`text-idc-icon group-hover:text-idc-orange fill-current ${
                        open ? "" : "transform rotate-180"
                      }`}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.628357 7.2053L1.37165 7.87427L7 1.62054L12.6284 7.87427L13.3717 7.2053L7 0.125696L0.628357 7.2053Z"
                      />
                    </svg>
                  </h3>
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel
                    unmount={false}
                    className={`${open ? "mb-6" : ""}`}
                  >
                    <RefinementList
                      attribute="options.base_size"
                      operator="and"
                      showMore
                      limit={10}
                      showMoreLimit={50}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </Panel>
        <Panel>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full">
                  <h3 className="flex items-center justify-between w-full ais-Panel-header group">
                    <span>Customization</span>
                    <svg
                      className={`text-idc-icon group-hover:text-idc-orange fill-current ${
                        open ? "" : "transform rotate-180"
                      }`}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.628357 7.2053L1.37165 7.87427L7 1.62054L12.6284 7.87427L13.3717 7.2053L7 0.125696L0.628357 7.2053Z"
                      />
                    </svg>
                  </h3>
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel
                    unmount={false}
                    className={`${open ? "mb-6" : ""}`}
                  >
                    <RefinementList
                      attribute="options.customization"
                      operator="and"
                      showMore
                      limit={10}
                      showMoreLimit={50}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </Panel>
        <Panel>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full">
                  <h3 className="flex items-center justify-between w-full ais-Panel-header group">
                    <span>Availability</span>
                    <svg
                      className={`text-idc-icon group-hover:text-idc-orange fill-current ${
                        open ? "" : "transform rotate-180"
                      }`}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.628357 7.2053L1.37165 7.87427L7 1.62054L12.6284 7.87427L13.3717 7.2053L7 0.125696L0.628357 7.2053Z"
                      />
                    </svg>
                  </h3>
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel
                    unmount={false}
                    className={`${open ? "mb-6" : ""}`}
                  >
                    <ToggleRefinement
                      attribute="inventory_available"
                      label="Show only availabile"
                      value={true}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </Panel>
      </div>
    </div>
  )
}

const CollectionListing: FC<ICollectionListing> = ({
  location,
  data: {
    collection: { id, title, handle, descriptionHtml, image },
    collectionMeta,
    staticHits,
    staticHitsNull,
  },
}) => {
  if (staticHitsNull) {
    const staticHitsCombined = staticHits?.edges?.concat(staticHitsNull?.edges)
    const breakpoints = useBreakpoint()
    const [searchState, setSearchState] = React.useState(
      urlToSearchState(location)
    )
    const debouncedSearchState = useDebounce(searchState, DEBOUNCE_TIME)
    const onSearchStateChange = (updatedSearchState) => {
      setSearchState(updatedSearchState)
    }

    const [pageTitle, setPageTitle] = React.useState(title)
    const [pageDescription, setPageDescription] =
      React.useState(descriptionHtml)
    const [pageImage, setPageImage] = React.useState(
      getSrc(image?.gatsbyImageData)
    )

    function updateMeta(searchState: {
      refinementList: { vendor: (string | null)[]; tags: (string | null)[] }
    }) {
      let filterVendor: string | null = null
      let filterType: string | null = null
      let additionalMeta: any[] = []

      if (searchState.refinementList?.vendor) {
        filterVendor = searchState.refinementList?.vendor[0]
      } else {
        filterVendor = null
      }

      if (searchState.refinementList?.tags) {
        filterType = searchState.refinementList?.tags[0]
      } else {
        filterType = null
      }

      if (filterVendor || filterType) {
        additionalMeta = collectionMeta.nodes.filter(
          (node: {
            filterVendor: string | null
            filterType: string | null
          }) => {
            return (
              (node.filterVendor == filterVendor &&
                node.filterType == filterType) ||
              (node.filterVendor == filterVendor && node.filterType == "*") ||
              (node.filterVendor == "*" && node.filterType == filterType)
            )
          }
        )
      }

      if (additionalMeta.length) {
        setPageTitle(additionalMeta[0].Title)
        setPageDescription(additionalMeta[0].description)
        if (additionalMeta[0].image) {
          setPageImage(additionalMeta[0].image.url)
        }
      } else {
        setPageTitle(title)
        setPageDescription(descriptionHtml)
        setPageImage(getSrc(image?.gatsbyImageData))
      }
    }

    React.useEffect(() => {
      if (debouncedSearchState) {
        // I tried using setState here, but I found that that broke the "Back"
        // button. Ultimately this means that the search state overwrites
        // itself as the user refines their search. There is no "undo" to the
        // refinements.
        window.history.replaceState(
          debouncedSearchState,
          "",
          searchStateToUrl({ location }, debouncedSearchState)
        )
        updateMeta(debouncedSearchState)
      }
    }, [debouncedSearchState, location])

    const StaticHitResults = () => {
      return (
        <div className="ais-Hits">
          <div className="ais-Hits-list">
            {staticHitsCombined.slice(0, 32).map(({ node }) => {
              const priceRangeV2 = {
                minVariantPrice: {
                  amount: node.price,
                },
              }

              const images: [] = []
              const staticProduct = {
                title: node.title,
                image: node.image,
                vim: node.tags.includes("VIM"),
                salePrice: node.price,
                reviews: 0,
                images: images,
                priceRangeV2: priceRangeV2,
                price: node.compare_at_price,
                handle: node.handle,
                productType: node.product_type,
                meta: node.meta,
                key: node.jsonId,
                onlineStoreUrl: node.online_store_url,
                inventoryPolicy: node.inventory_policy,
                inventoryManagement: node.inventory_management,
                inventoryAvailable: node.inventory_available,
                inventoryQuantity: node.inventory_quantity,
                variantTitle: node.variant_title,
                legacyResourceId: node.jsonId,
                id: node.id,
                sku: node.sku,
                reviewsAverage: node?.meta?.yotpo?.reviews_average,
                reviewsCount: node?.meta?.yotpo?.reviews_count,
              }

              return <ProductCard product={staticProduct} key={node.id} />
            })}
          </div>
        </div>
      )
    }
    //print total number of statichitresults

    // if location.pathname is /collections/open-box-returns, then ob=true
    const isOpenBoxReturns = location.pathname.includes("open-box-returns")
    // for boxing week special listing page
    const isBoxingWeek = location.pathname.includes("boxing-week")

    // The following lines presumably set the state of the search component to
    // immediately fetch dynamically and ignore the static initial state
    // if ob is true, make searchState.page = 1 but only if searchState.page is not already set
    if (isOpenBoxReturns && !searchState.page) {
      searchState.page = 1
    }

    // if boxing week is true, make searchState.page = 1 but only if searchState.page is not already set
    if (isBoxingWeek && !searchState.page) {
      searchState.page = 1
    }

    React.useEffect(() => {
      if (
        searchState.page ||
        searchState.refinementList ||
        searchState.configure
      ) {
        setTimeout(() => {
          scrollTo(document.querySelector(".ais-Hits-list").offsetTop, 400)
        }, 400)
      }
    }, [searchState.page, searchState.refinementList, searchState.configure])

    React.useEffect(() => {
      if (typeof window === "undefined" || !window?.klaviyo) return null
      const klaviyo = window.klaviyo
      const item = {
        CategoryName: title,
        CategoryID: id,
        URL: "https://www.idrinkcoffee.com/collections/" + handle,
      }
      klaviyo.push(["track", "Viewed Category", item])
    }, [])

    return (
      <Layout>
        {pageImage ? (
          <Seo
            title={pageTitle}
            description={pageDescription}
            image={pageImage}
          />
        ) : (
          <Seo title={pageTitle} description={pageDescription} />
        )}
        <hr></hr>

        <div className="max-w-[1700px] px-1 mx-auto">
          <div className="flex flex-col-reverse items-center max-w-xl mx-auto my-10 md:flex-row gap-y-4 md:gap-x-20 md:max-w-4xl">
            <div className="md:flex-grow">
              <h1
                className={`text-center ${
                  image ? "md:text-left" : ""
                } font-bold text-3xl`}
              >
                {pageTitle}
              </h1>
              <p
                className="prose-sm text-center prose-p:prose-xs dark:prose-invert li-p:prose-sm dark:li-invert md:text-left li-m:0 prose-a:underline prose-a:font-semibold hover:prose-a:no-underline"
                //remove any data-mce-* attributes
                dangerouslySetInnerHTML={{
                  __html: pageDescription
                    .replace(/<img[^>]*>/g, "")
                    .replace(/data-mce-[^>]*/g, ""),
                }}
              />
            </div>
            {pageImage && (
              <div className="relative self-center w-40 h-40 md:shrink-0 md:w-56 md:h-56 lg:h-72 lg:w-72">
                <div className="absolute top-0 left-0 w-full h-full rounded-full"></div>
                <img
                  className="relative z-10 object-contain w-full h-full"
                  loading="eager"
                  alt={pageImage?.altText ? image.altText : title}
                  src={pageImage}
                />
              </div>
            )}
          </div>

          <InstantSearch
            searchClient={searchClient}
            indexName="idc_products_recently_ordered_count_desc"
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
            createUrl={createUrlParams}
          >
            {isOpenBoxReturns ? (
              <Configure
                distinct
                facetingAfterDistinct="true"
                filters={`(collections:${slugify(handle)})`}
                enablePersonalization={false}
              />
            ) : (
              <Configure
                filters={`(collections:${slugify(handle)})`}
                enablePersonalization={false}
              />
            )}

            {isBoxingWeek ? (
              <Configure
                distinct
                facetingAfterDistinct="true"
                filters={`(collections:${slugify(handle)})`}
                enablePersonalization={false}
              />
            ) : (
              <Configure
                filters={`(collections:${slugify(handle)})`}
                enablePersonalization={false}
              />
            )}

            <div className="relative items-start justify-between w-full md:flex md:flex-row">
              {breakpoints.md ? null : (
                <StickyBox offsetTop={16} offsetBottom={16}>
                  <div className="w-full md:w-64 lg:w-80 2xl:w-96 min-w-[280px]">
                    <Filters />
                  </div>
                </StickyBox>
              )}

              <div className="relative z-10 bg-white grow md:pl-8 xl:pl-12 2xl:pl-16">
                <div className="flex flex-col items-center justify-start w-full pb-2 md:flex-row md:justify-end lg:justify-between md:py-4">
                  <div className="hidden lg:block">
                    <Stats
                      translations={{
                        stats(nbHits, nbSortedHits, areHitsSorted) {
                          return areHitsSorted && nbHits !== nbSortedHits
                            ? `${nbSortedHits!.toLocaleString()} relevant results sorted out of ${nbHits.toLocaleString()} found`
                            : `${nbHits.toLocaleString()} results found`
                        },
                      }}
                    />
                  </div>
                  <div className="flex justify-between w-full md:w-auto md:space-x-8 overscroll-contain">
                    {breakpoints.md ? (
                      <Disclosure>
                        {() => (
                          <>
                            <Disclosure.Button
                              className={
                                "inline-block justify-self-start cursor-pointer py-2.5 px-5 outline-none bg-transparent bg-white border hover:border-idc-border rounded hover:shadow-lg"
                              }
                            >
                              Filter
                            </Disclosure.Button>
                            <Disclosure.Panel unmount={false}>
                              <div className="bg-idc-light fixed inset-0 max-w-[300px] z-30 overflow-auto overscroll-contain">
                                <Filters />
                                <div className="p-6 pt-0 -mt-12">
                                  <Disclosure.Button
                                    as="div"
                                    className="inline-block w-full px-8 py-3 mt-8 text-center transition duration-300 ease-in-out rounded-full bg-idc-orange hover:bg-idc-blue hover:bg-opacity-75 active:bg-idc-blue"
                                  >
                                    <span className="m-0 text-base font-bold text-white">
                                      View Results
                                    </span>
                                  </Disclosure.Button>
                                </div>
                              </div>
                              <Disclosure.Button
                                as="div"
                                className="fixed inset-0 z-20 bg-idc-title opacity-30"
                              ></Disclosure.Button>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ) : null}
                    <div className="flex md:space-x-8">
                      <div className="flex items-center space-x-2">
                        <span className="hidden w-16 text-sm md:inline-block md:w-auto text-idc-body">
                          Sort by
                        </span>
                        <SortBy
                          className="hidden pr-3 bg-white border rounded hover:border-idc-border hover:shadow-lg md:block"
                          defaultRefinement="idc_products_recently_ordered_count_desc"
                          items={[
                            {
                              label: "Featured",
                              value: "idc_products_recently_ordered_count_desc",
                            },
                            {
                              label: "Price Ascending",
                              value: "idc_products_price_asc",
                            },
                            {
                              label: "Price Descending",
                              value: "idc_products_price_dec",
                            },
                          ]}
                        />
                      </div>
                      <div className="flex items-center space-x-2">
                        <span className="hidden w-16 text-sm md:inline-block md:w-auto text-idc-body">
                          Per page
                        </span>
                        <HitsPerPage
                          className="invisible pr-3 bg-white border rounded hover:border-idc-border hover:shadow-lg overscroll-contain md:visible"
                          items={[
                            {
                              label: "32 Products",
                              value: 32,
                            },
                            {
                              label: "64 Products",
                              value: 64,
                            },
                            {
                              label: "128 Products",
                              value: 128,
                            },
                          ]}
                          defaultRefinement={32}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-8 listing-list">
                  {!(searchState.page || searchState.refinementList) ? (
                    <>
                      {" "}
                      <StaticHitResults />
                    </>
                  ) : (
                    <Results />
                  )}
                </div>
                <div className="mt-12">
                  <PaginationComponent
                    padding={5}
                    totalPages={8}
                    showFirst={false}
                    showLast={false}
                  />
                </div>
              </div>
            </div>
          </InstantSearch>
        </div>
      </Layout>
    )
  }
}

export const collectionQuery = graphql`
  query CollectionQuery($handle: String) {
    collection: shopifyCollection(handle: { eq: $handle }) {
      id
      handle
      title
      descriptionHtml
      image {
        id
        gatsbyImageData
      }
    }
    collectionMeta: allStrapiCollectionPages(
      filter: { parentCollection: { shopifyData: { handle: { eq: $handle } } } }
    ) {
      nodes {
        id
        Title
        description
        image {
          url
        }
        filterVendor
        filterType
      }
    }
    staticHits: allIdcProductsJson(
      filter: {
        collections: { eq: $handle }
        recently_ordered_count: { ne: null }
      }
    ) {
      edges {
        node {
          title
          collections
          meta {
            yotpo {
              reviews_average
            }
          }
          option1
          _tags
          position
          compare_at_price
          price
          price_range
          product_image
          product_type
          recently_ordered_count
          sku
          tags
          jsonId
          variant_title
          variants_inventory_count
          variants_max_price
          variants_min_price
          vendor
          handle
          id
          image
          inventory_available
          inventory_management
          inventory_management_shopify
          inventory_policy
          inventory_quantity
        }
      }
      totalCount
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        totalCount
        perPage
      }
    }
    staticHitsNull: allIdcProductsJson(
      filter: {
        collections: { eq: $handle }
        recently_ordered_count: { eq: null }
      }
    ) {
      edges {
        node {
          title
          collections
          meta {
            yotpo {
              reviews_average
            }
          }
          option1
          _tags
          position
          compare_at_price
          price
          price_range
          product_image
          product_type
          recently_ordered_count
          sku
          tags
          jsonId
          variant_title
          variants_inventory_count
          variants_max_price
          variants_min_price
          vendor
          handle
          id
          image
          inventory_available
          inventory_management
          inventory_management_shopify
          inventory_policy
          inventory_quantity
        }
      }
      totalCount
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        totalCount
        perPage
      }
    }
  }
`

const CollectionListingTemplate = (props: any) => {
  return <CollectionListing {...props} />
}

export default CollectionListingTemplate
