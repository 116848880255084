import React from "react"

const AutomaticDrip = ({ classes = "w-[3.125rem] h-[3.5rem]" }) => (
  <svg
    className={classes}
    width="343"
    height="405"
    viewBox="0 0 343 405"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2690_1756)">
      <path
        d="M142.58 13.58H256.33L262.87 28.67H138.54L142.58 13.58Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M17.33 391.83L19.08 402.83H39.21L41.46 391.58L17.33 391.83Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M295.71 27.83L163.21 29L162.59 61.75L182.21 111.88L187.96 137.38L180.46 141.63H129V187.26H139.5C139.5 187.26 159.96 150.93 166.58 150.8C173.2 150.67 260.87 150.88 260.87 150.88L282.62 141.88L276 136.46L275.5 107.84C275.5 107.84 297.71 54.55 298.58 52.42C299.87 44.42 295.71 27.83 295.71 27.83Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M179.67 157.24L286.92 157.99C286.92 157.99 282.8 162.64 281.17 166.37C279.54 170.1 277.92 176.75 276.05 181.75C274.18 186.75 279.92 228.25 281.92 237.38C283.92 246.51 302.54 356.01 302.92 357.88C303.3 359.75 305.46 395.1 305.46 395.1C305.46 395.1 277.13 399.27 232.88 399.27C188.63 399.27 159.09 395.1 159.09 395.1L161.42 347.84L189.92 198.76C189.92 198.76 192.09 185.26 191.67 182.26C191.25 179.26 186.55 168 185.17 165.51C183.79 163.02 179.67 157.24 179.67 157.24Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M180.46 141.58C180.46 141.58 223.46 142.46 234.71 142.46C245.96 142.46 282.58 141.84 282.58 141.84"
        fill="white"
      />
      <path
        d="M180.46 141.58C180.46 141.58 223.46 142.46 234.71 142.46C245.96 142.46 282.58 141.84 282.58 141.84"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M299.08 42.21L162.46 42.83"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M188.58 177.74L275.5 178.41H327.87C327.87 178.41 348.66 297.31 338.1 297.31C327.54 297.31 321.7 199.84 298.7 199.84C285.2 199.84 287.16 233.16 286.91 237.49C286.849 238.722 286.44 239.911 285.733 240.921C285.025 241.932 284.047 242.721 282.91 243.2C282.91 243.2 280.33 230.2 279.24 221.58C278.15 212.96 275 201.33 274.79 200C274.58 198.67 192.92 200.15 188.92 198.09C190.23 189 189.87 179.61 188.58 177.74Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M302.92 357.86C302.92 357.86 268.54 366.35 201.54 361.44Z"
        fill="white"
      />
      <path
        d="M302.92 357.86C302.92 357.86 268.54 366.35 201.54 361.44"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M222.16 392.24H16.87L1.5 387.92V353.57H222.16V392.24Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M163 337.57H17.21L1.5 353.57H222.17C222.17 353.57 159 348.33 163 337.57Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M42.33 343.08C42.33 343.08 43.46 346.58 48.33 346.58H123.33C127.83 346.58 131.08 343.96 131.08 342.83V131.08H43.21L42.33 343.08Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M82.04 366.25H50.54V380.92H82.04V366.25Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M117.87 366.25H110.37V378.25H117.87V366.25Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M181 110.08C181 110.08 206.33 86.91 231.33 86.91C256.33 86.91 275.41 107.83 275.41 107.83"
        fill="white"
      />
      <path
        d="M181 110.08C181 110.08 206.33 86.91 231.33 86.91C256.33 86.91 275.41 107.83 275.41 107.83"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M32.54 2.5C32.54 2.5 52.12 1.5 86.04 1.5C119.96 1.5 140.37 3.17 140.37 3.17L143 23.25L30.25 23.42L32.54 2.5Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M21.37 23.08C21.37 23.08 41.87 21.42 86.37 21.42C130.87 21.42 152.7 22.75 152.7 22.75L140.7 131.08H33L21.37 23.08Z"
        fill="white"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M76.87 108.42H91.71"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M76.87 90.88H91.71"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M76.87 73.33H91.71"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M76.87 55.79H91.71"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M76.87 38.25H91.71"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M60.21 131.58V344.58"
        stroke="#858180"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2690_1756">
        <rect width="342.6" height="404.33" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default AutomaticDrip
